import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    Typography,
    DialogActions,
} from '@mui/material';
import { Divider } from '@mui/material';
import { Order } from '../networking.types';

interface OrderViewProps {
    open: boolean;
    order: Order | null;
    onClose: () => void;
}

const OrderView: React.FC<OrderViewProps> = ({ open, order, onClose }) => {
    const formatCurrency = (amount: number): string => {
        amount = amount / 100;
        return `$${amount.toFixed(2)}`;
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <div style={{ position: 'absolute', top: '0', right: '0', margin: '8px' }}>
                    <Typography variant="body2" style={{ fontSize: '0.8rem', textAlign: 'right' }}>
                        Created <strong>{order?.created_at.toLocaleString()}</strong>
                    </Typography>
                </div>
                Order Details
                {order && (
                    <Grid
                        container
                        direction="row"
                        alignItems="left"
                        spacing={1}
                        style={{ marginLeft: '4px' }}
                    >
                        <Grid item>
                            <Typography
                                variant="body2"
                                style={{ fontSize: '0.8rem', marginRight: '10px' }}
                            >
                                Items <strong>{formatCurrency(order.commissionable_total)}</strong>
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography variant="body2" style={{ fontSize: '0.8rem' }}>
                                    <>
                                        <span>
                                            Buyer{' '}
                                            <strong>
                                                {order?.buyer_org_name
                                                    ? order.buyer_org_name
                                                    : `${order.buyer_athlete?.first_name} ${order.buyer_athlete?.last_name}`}
                                            </strong>
                                        </span>
                                        <span style={{ fontSize: '0.7rem' }}>
                                            &nbsp;
                                            {order.buyer_athlete?.email}{' '}
                                            {order.buyer_athlete?.phone}
                                        </span>
                                    </>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </DialogTitle>
            <Divider />
            <DialogContent>
                {order && (
                    <>
                        <h4>Line Items</h4>
                        <Divider style={{ marginTop: '20px', backgroundColor: 'lightgray' }} />
                        {order.line_items.map((item, index) => (
                            <React.Fragment key={item.uuid}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{ marginTop: '10px', marginLeft: '20px' }}
                                >
                                    <Grid item xs={12} style={{ position: 'relative' }}>
                                        <Typography
                                            variant="body2"
                                            style={{
                                                fontSize: '0.8rem',
                                                color: '#111111',
                                                position: 'absolute',
                                                left: '-20px',
                                                top: '0',
                                            }}
                                        >
                                            {index + 1}.
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: '1.5rem', color: '#111111' }}
                                        >
                                            <strong>
                                                {item.stripe_price_id.includes('coupon_')
                                                    ? 'Coupon'
                                                    : 'Product'}
                                                :
                                            </strong>{' '}
                                            {item.description}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: '0.8rem', color: '#111111' }}
                                        >
                                            <strong>Unit Amount:</strong>{' '}
                                            {item.stripe_price_id.includes('coupon_')
                                                ? `(${formatCurrency(item.unit_amount)})`
                                                : formatCurrency(item.unit_amount)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            style={{ fontSize: '1.4rem', color: '#111111' }}
                                        >
                                            <strong>Quantity:</strong> {item.quantity}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: '0.8rem', color: '#111111' }}
                                        >
                                            <strong>Subtotal:</strong>{' '}
                                            {item.stripe_price_id.includes('coupon_')
                                                ? `(${formatCurrency(item.amount_subtotal)})`
                                                : formatCurrency(item.amount_subtotal)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider
                                    style={{
                                        marginTop: '20px',
                                        backgroundColor: 'lightgray',
                                        marginBottom: '24px',
                                    }}
                                />
                            </React.Fragment>
                        ))}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrderView;
