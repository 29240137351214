import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    MenuItem,
    Select,
    Snackbar,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import goldMedal from '../../../images/gold-medal (1).png';
import goldMedal1Tul from '../../../images/gold-medal.png';
import silverMedal from '../../../images/silver-medal.png';
import bronzeMedal from '../../../images/bronze-medal.png';
import Pod from '../../../images/pod.png';
import Vest from '../../../images/vestTransparent.png';
import Charger from '../../../images/charger1.png';
import TripleCharger from '../../../images/charger3x.png';
import Fiyrlink from '../../../images/fiyrlink.png';
import { ShoppingCart } from '../../../utils';
import DialogContent from '@mui/material/DialogContent';
import { UseShoppingCart } from '../../../contexts/ShoppingCartContext';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';
import { Organization } from 'utils';
import {
    checkoutAthlete,
    checkoutOrganization,
    deleteShoppingCarts,
    initializeShoppingCart,
    InitializeShoppingCartParams,
} from '../shop.api';
import { getOrgSubscriptions } from '../../networking/networking.api';
import { ShopAsOrganization } from '../components/ShopAsOrganization';
import { ShopAsAthlete } from '../components/ShopAsAthlete';
import getCurrentUser from '../../../api/Auth/getCurrentUser';
import getOrganizationsForCoach from '../../../api/Organization/getOrganizationsForCoach';
import getOrganizationBySlug from '../../../api/Public/getOrganizationBySlug';
import { getSellerOrganization } from '../shop.api';

const Shop = () => {
    const [userType, setUserType] = useState<'user' | 'organization' | null>(null);
    const [buyerOrganization, setBuyerOrganization] = useState<Organization | null>(null);
    const [sellerOrganization, setSellerOrganization] = useState<Organization | null>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [clearingCart, setClearingCart] = useState(false);
    const { cart, refetchCart, isInitialLoading } = UseShoppingCart();
    const { organizations } = React.useContext(OrganizationsContext);
    const [buyerOrganizations, setBuyerOrganizations] = useState<Organization[]>([]);
    const [initializing, setInitializing] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [hasActiveOrgSubscription, setHasActiveOrgSubscription] = useState<boolean>(false);
    const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
    const [podCount, setPodCount] = useState(0);
    const [vestCount, setVestCount] = useState(0);
    const [showVestAlert, setShowVestAlert] = useState(false);
    const [showFiyrlinkAlert, setShowFiyrlinkAlert] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [shippingCost, setShippingCost] = useState(0);
    const [isCheckingOut, setIsCheckingOut] = useState(false);
    const defaultShippingCost = 15;

    useEffect(() => {
        const initializeFromSlug = async (slug: string) => {
            if (isInitialLoading) {
                return;
            }

            const slugResponse = await getOrganizationBySlug(slug);
            const orgResponse = await getSellerOrganization();
            setUserType(orgResponse.data.userType);
            setSellerOrganization(slugResponse.data);

            if (cart && cart.seller_uuid !== slugResponse.data.uuid) {
                await deleteShoppingCarts();
                await refetchCart();
            }

            setLoaded(true);
        };

        const initialize = async () => {
            if (isInitialLoading) {
                return;
            }

            if (cart?.uuid) {
                setLoaded(true);
                return;
            }

            try {
                const orgResponse = await getSellerOrganization();
                setUserType(orgResponse.data.userType);
                setSellerOrganization(orgResponse.data.seller);
            } catch (error) {
                setSnackbarMessage('Error fetching seller organization');
                console.error('Error fetching seller organization:', error);
            } finally {
                setLoaded(true);
            }
        };

        const slug = window.location.pathname.split('/')[2];

        if (slug) {
            initializeFromSlug(slug).then(() => {});
        } else {
            initialize().then(() => {});
        }
    }, [isInitialLoading, refetchCart, cart]);

    useEffect(() => {
        if (cart?.items) {
            // Calculate total pods and vests
            const pods = cart.items.reduce(
                (total, item) => (item.item_category === 'fiyrpod' ? total + item.quantity : total),
                0,
            );

            const vests = cart.items.reduce(
                (total, item) => (item.item_category === 'vest' ? total + item.quantity : total),
                0,
            );

            // Check for Fiyrlink
            const hasLink = cart.items.some((item) => item.item_category === 'fiyrlink');

            setPodCount(pods);
            setVestCount(vests);

            // Set alert states
            setShowVestAlert(cart.buyer_type === 'organization' && pods > vests);
            setShowFiyrlinkAlert(cart.buyer_type === 'organization' && pods >= 5 && !hasLink);
        }
    }, [cart]);

    useEffect(() => {
        if (!cart?.items) {
            return;
        }

        setShippingCost(0);

        cart.items.map((item) => {
            if (
                item.item_category !== 'subscription' &&
                item.item_category !== 'marketplace_item'
            ) {
                setShippingCost(defaultShippingCost);
            }
        });
    }, [cart]);

    // Check for active subscriptions when buyer organization changes
    useEffect(() => {
        if (buyerOrganization?.uuid) {
            checkActiveOrgSubscriptions(buyerOrganization.uuid);
        }
    }, [buyerOrganization]);

    useEffect(() => {
        const getBuyerOrgsForCoach = async () => {
            try {
                // Then fetch from API
                const data = await getOrganizationsForCoach();
                const orgsFromAPI = data.data;

                setBuyerOrganizations(orgsFromAPI);

                if (orgsFromAPI.length === 1) {
                    setBuyerOrganization(orgsFromAPI[0]);
                }
            } catch (error) {
                console.error('Error fetching buyer organizations:', error);
            }
        };

        getBuyerOrgsForCoach();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Keep existing effect for setting default buyer organization
    useEffect(() => {
        if (organizations.length === 1 && userType === 'organization') {
            setBuyerOrganization(organizations[0]);
        }
    }, [organizations, userType]);

    const checkActiveOrgSubscriptions = async (organizationId: string) => {
        setLoadingSubscriptions(true);
        try {
            const response = await getOrgSubscriptions(organizationId, {
                stripe_status: 'active',
                per_page: 100,
                page: 1,
                level: '0',
            });
            // Check if there's an active organization or organization_combo subscription
            const hasActive = response.data.data.some(
                (subscription) =>
                    subscription.stripe_status === 'active' &&
                    (subscription.name === 'organization' ||
                        subscription.name === 'organization_combo'),
            );

            setHasActiveOrgSubscription(hasActive);
        } catch (error) {
            console.error('Failed to fetch subscriptions:', error);
            setErrorMessage('Failed to check organization subscriptions');
        } finally {
            setLoadingSubscriptions(false);
        }
    };

    useEffect(() => {
        if (cart) {
            setUserType(cart.buyer_type);
            setBuyerOrganization(organizations.find((org) => org.uuid === cart.buyer_uuid) || null);
            setSellerOrganization(
                organizations.find((org) => org.uuid === cart.seller_uuid) || null,
            );
        }
    }, [cart, organizations]);

    const handleInitializeCart = async (type: 'user' | 'organization') => {
        try {
            setInitializing(true);

            if (!sellerOrganization) {
                setErrorMessage('Please select a seller organization');
                return;
            }

            // Only check buyerOrganization for organization type
            if (type === 'organization' && !buyerOrganization) {
                setErrorMessage('Please select a buyer organization');
                return;
            }

            let buyerUuid;

            if (type === 'user') {
                const user = await getCurrentUser();
                if (!user) {
                    throw new Error('User not found');
                }

                buyerUuid = user.data.uuid;
            } else {
                buyerUuid = buyerOrganization!.uuid;
            }

            const params: InitializeShoppingCartParams = {
                buyerType: type,
                buyerUuid: buyerUuid,
                sellerUuid: sellerOrganization.uuid,
            };

            await initializeShoppingCart(params);
            await refetchCart();
        } catch (error: any) {
            setErrorMessage('Error initializing shopping cart');
            console.error('Error initializing shopping cart:', error?.message);
        } finally {
            setInitializing(false);
        }
    };

    const handleClearCart = async () => {
        setClearingCart(true);
        try {
            await deleteShoppingCarts();
        } catch (error) {
            console.error('Error clearing cart:', error);
        } finally {
            await refetchCart();
            setUserType(null);
            setBuyerOrganization(null);
            setHasActiveOrgSubscription(false);
            setClearingCart(false);
        }
    };

    const calculateCartTotal = (cart: ShoppingCart) => {
        const subtotal =
            cart.items?.reduce((total, item) => total + item.price * item.quantity, 0) || 0;
        const discount =
            cart.discounts?.reduce((total, discount) => total + discount.discount_total, 0) || 0;
        return { subtotal: subtotal, total: subtotal - discount };
    };

    const checkout = async () => {
        if (!cart || isCheckingOut) {
            return;
        }
        setIsCheckingOut(true);

        if (cart.buyer_type === 'organization') {
            checkoutOrganization(cart.uuid, buyerOrganization!.uuid)
                .then(async (res) => {
                    window.location.href = res.data.url;
                })
                .catch((err) => {
                    setSnackbarMessage(`Error checking out: ${err.message}`);
                    console.error(err);
                    setIsCheckingOut(false);
                });
        } else {
            checkoutAthlete(cart.uuid)
                .then(async (res) => {
                    window.location.href = res.data.url;
                })
                .catch((err) => {
                    setSnackbarMessage(`Error checking out: ${err.message}`);
                    console.error(err);
                    setIsCheckingOut(false);
                });
        }
    };

    return isInitialLoading || !loaded ? (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <CircularProgress />
        </Box>
    ) : (
        <>
            <Snackbar
                open={!!snackbarMessage}
                message={snackbarMessage}
                autoHideDuration={6000}
                onClose={() => setSnackbarMessage('')}
            />

            <Container maxWidth="xl">
                {!cart && userType === null ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 4,
                            mt: 4,
                            marginTop: '20px',
                        }}
                    >
                        {sellerOrganization && (
                            <Typography variant="h5" component="h1">
                                {sellerOrganization.name} Marketplace
                            </Typography>
                        )}
                    </Box>
                ) : (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2,
                                mb: 4,
                                marginTop: '20px',
                            }}
                        >
                            {sellerOrganization && (
                                <Typography variant="h5" component="h1">
                                    {sellerOrganization.logo_url && (
                                        <img
                                            src={sellerOrganization.logo_url}
                                            alt=""
                                            style={{ height: '32px', width: '32px' }}
                                        ></img>
                                    )}{' '}
                                    {sellerOrganization.name}{' '}
                                    {userType === 'user' ? 'Athlete' : 'Coach'} Marketplace
                                </Typography>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    gap: 2,
                                    width: '100%',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                }}
                            >
                                <Dialog open={!!errorMessage} onClose={() => setErrorMessage(null)}>
                                    <DialogTitle>Error</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>{errorMessage}</DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={() => setErrorMessage(null)}
                                            color="primary"
                                        >
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                {userType === 'organization' && sellerOrganization && !cart ? (
                                    <>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                            <Typography variant="body1">Shop as</Typography>
                                            {buyerOrganizations.length === 1 ? (
                                                <span style={{ fontWeight: '600' }}>
                                                    {buyerOrganizations[0].name}
                                                </span>
                                            ) : (
                                                <FormControl size="small">
                                                    <Select
                                                        value={buyerOrganization?.uuid || ''}
                                                        onChange={(e) => {
                                                            const selectedOrg =
                                                                buyerOrganizations.find(
                                                                    (org) =>
                                                                        org.uuid === e.target.value,
                                                                ) || null;
                                                            setBuyerOrganization(selectedOrg);
                                                        }}
                                                        displayEmpty
                                                        size="small"
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select Buyer
                                                        </MenuItem>
                                                        {buyerOrganizations.map((org) => (
                                                            <MenuItem
                                                                key={org.uuid}
                                                                value={org.uuid}
                                                                disabled={
                                                                    org.uuid ===
                                                                    sellerOrganization.uuid
                                                                }
                                                            >
                                                                {org.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}
                                            <Typography variant="body1">
                                                from seller{' '}
                                                <span style={{ fontWeight: '600' }}>
                                                    {sellerOrganization.name}
                                                </span>
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                gap: 2,
                                                mt: 3,
                                                marginTop: '48px',
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={
                                                    !buyerOrganization ||
                                                    !sellerOrganization ||
                                                    initializing ||
                                                    loadingSubscriptions
                                                }
                                                onClick={() => handleInitializeCart('organization')}
                                            >
                                                {initializing ? (
                                                    <>
                                                        <CircularProgress
                                                            size={20}
                                                            sx={{ mr: 1 }}
                                                        />
                                                        Initializing Cart...
                                                    </>
                                                ) : (
                                                    `Shop ${sellerOrganization.name}`
                                                )}
                                            </Button>
                                        </Box>
                                    </>
                                ) : userType === 'user' && sellerOrganization && !cart ? (
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                gap: 2,
                                                mt: 3,
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={initializing || loadingSubscriptions}
                                                onClick={() => handleInitializeCart('user')}
                                            >
                                                {initializing ? (
                                                    <>
                                                        <CircularProgress
                                                            size={20}
                                                            sx={{ mr: 1 }}
                                                        />
                                                        Initializing Cart...
                                                    </>
                                                ) : (
                                                    `Shop ${sellerOrganization.name}`
                                                )}
                                            </Button>
                                        </Box>
                                    </>
                                ) : null}
                                {!sellerOrganization && loaded && (
                                    <Typography
                                        variant="body1"
                                        sx={{ textAlign: 'center', my: 4, fontWeight: '600' }}
                                    >
                                        Please check with your coach to join an organization so you
                                        can make purchases.
                                    </Typography>
                                )}
                                {cart && (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 3,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '100%',
                                                maxWidth: '512px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                p: 2,
                                            }}
                                        >
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={handleClearCart}
                                                disabled={clearingCart}
                                                startIcon={
                                                    clearingCart ? (
                                                        <CircularProgress size={20} />
                                                    ) : null
                                                }
                                            >
                                                Clear Cart & Start Over
                                            </Button>
                                        </Box>

                                        {cart.items.length > 0 && (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: '512px',
                                                    border: '1px solid lightgray',
                                                    borderRadius: '8px',
                                                    padding: '16px',
                                                }}
                                            >
                                                <Typography variant="h6" sx={{ mb: 2 }}>
                                                    My Cart
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    component="div"
                                                    gutterBottom
                                                    style={{
                                                        borderTop: '1px solid #e0e0e0',
                                                        borderBottom: '1px solid #e0e0e0',
                                                    }}
                                                >
                                                    {buyerOrganization?.name && (
                                                        <p>
                                                            <strong>Buyer:</strong>{' '}
                                                            {buyerOrganization?.name}
                                                        </p>
                                                    )}
                                                    <p>
                                                        <strong>Seller:</strong>{' '}
                                                        {sellerOrganization?.name}
                                                    </p>
                                                </Typography>
                                                {cart.items.map((item) => (
                                                    <Box
                                                        key={item.uuid}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            mb: 2,
                                                            p: 2,
                                                        }}
                                                    >
                                                        <Box sx={{ width: 80, mr: 2 }}>
                                                            {item.item_category === 'fiyrpod' && (
                                                                <img
                                                                    src={Pod}
                                                                    alt="Fiyrpod"
                                                                    style={{ width: '45%' }}
                                                                />
                                                            )}
                                                            {item.item_category ===
                                                                'athlete_combo' && (
                                                                <img
                                                                    src={Pod}
                                                                    alt="Fiyrpod"
                                                                    style={{ width: '45%' }}
                                                                />
                                                            )}
                                                            {item.item_category === 'vest' && (
                                                                <img
                                                                    src={Vest}
                                                                    alt="Vest"
                                                                    style={{ width: '45%' }}
                                                                />
                                                            )}
                                                            {item.item_category ===
                                                                'single_charger' && (
                                                                <img
                                                                    src={Charger}
                                                                    alt="Charger"
                                                                    style={{ width: '45%' }}
                                                                />
                                                            )}
                                                            {item.item_category ===
                                                                'triple_charger' && (
                                                                <img
                                                                    src={TripleCharger}
                                                                    alt="Triple Charger"
                                                                    style={{ width: '45%' }}
                                                                />
                                                            )}
                                                            {item.item_category === 'fiyrlink' && (
                                                                <img
                                                                    src={Fiyrlink}
                                                                    alt="Fiyrlink Gateway"
                                                                    style={{ width: '45%' }}
                                                                />
                                                            )}
                                                            {item.item_category ===
                                                                'marketplace_item' && (
                                                                <img
                                                                    src={item.image_url!}
                                                                    alt="Marketplace Item"
                                                                    style={{
                                                                        width: '45%',
                                                                        fontSize: '0.5rem',
                                                                        border: '1px solid #e0e0e0',
                                                                    }}
                                                                />
                                                            )}
                                                            {item.item_category ===
                                                                'subscription' &&
                                                                item.stripe_price_id ===
                                                                    process.env
                                                                        .REACT_APP_STRIPE_ATHLETE_PRICE! && (
                                                                    <img
                                                                        src={goldMedal1Tul}
                                                                        alt="1Tul Subscription"
                                                                        style={{ width: '50%' }}
                                                                    />
                                                                )}
                                                            {item.item_category ===
                                                                'subscription' &&
                                                                item.stripe_price_id ===
                                                                    process.env
                                                                        .REACT_APP_STRIPE_ORGANIZATION_LEVEL_1_PRICE! && (
                                                                    <img
                                                                        src={bronzeMedal}
                                                                        alt="Bronze Medal"
                                                                        style={{ width: '50%' }}
                                                                    />
                                                                )}
                                                            {item.item_category ===
                                                                'subscription' &&
                                                                item.stripe_price_id ===
                                                                    process.env
                                                                        .REACT_APP_STRIPE_ORGANIZATION_LEVEL_2_PRICE! && (
                                                                    <img
                                                                        src={silverMedal}
                                                                        alt="Silver Medal"
                                                                        style={{ width: '50%' }}
                                                                    />
                                                                )}
                                                            {item.item_category ===
                                                                'subscription' &&
                                                                item.stripe_price_id ===
                                                                    process.env
                                                                        .REACT_APP_STRIPE_ORGANIZATION_LEVEL_3_PRICE! && (
                                                                    <img
                                                                        src={goldMedal}
                                                                        alt="Gold Medal"
                                                                        style={{ width: '50%' }}
                                                                    />
                                                                )}
                                                        </Box>
                                                        <Box sx={{ flex: 1 }}>
                                                            <Typography
                                                                variant="body1"
                                                                style={{ fontSize: '0.85rem' }}
                                                            >
                                                                {item.item_category ===
                                                                'athlete_combo'
                                                                    ? `1Tul Combo ${item.name}`
                                                                    : item.name}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center',
                                                                    mt: 1,
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.secondary"
                                                                    style={{ fontSize: '0.75rem' }}
                                                                >
                                                                    ${(item.price / 100).toFixed(2)}{' '}
                                                                    × {item.quantity}
                                                                </Typography>
                                                                <Typography variant="body2">
                                                                    $
                                                                    {(
                                                                        (item.price *
                                                                            item.quantity) /
                                                                        100
                                                                    ).toFixed(2)}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                ))}

                                                <Box
                                                    sx={{
                                                        mt: 3,
                                                        pt: 2,
                                                        borderTop: '1px solid #e0e0e0',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            mb: 1,
                                                        }}
                                                    >
                                                        <Typography variant="body2">
                                                            Subtotal
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            $
                                                            {(
                                                                calculateCartTotal(cart).subtotal /
                                                                100
                                                            ).toFixed(2)}
                                                        </Typography>
                                                    </Box>
                                                    {cart.discounts.map((discount) => (
                                                        <Box
                                                            key={discount.uuid}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                sx={{ fontSize: '0.8rem' }}
                                                                color="text.secondary"
                                                            >
                                                                {discount.discount_description} ($
                                                                {(
                                                                    discount.discount_per_unit! /
                                                                    100
                                                                ).toFixed(2)}{' '}
                                                                x {discount.unit_quantity})
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="text.secondary"
                                                                sx={{ fontSize: '0.8rem' }}
                                                            >
                                                                -$
                                                                {(
                                                                    discount.discount_total / 100
                                                                ).toFixed(2)}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                    {shippingCost ? (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                color="text.secondary"
                                                                sx={{ fontSize: '0.8rem' }}
                                                            >
                                                                Shipping
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="text.secondary"
                                                                sx={{ fontSize: '0.8rem' }}
                                                            >
                                                                {shippingCost ? '$15.00' : 'Free'}
                                                            </Typography>
                                                        </Box>
                                                    ) : null}
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            mb: 2,
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            sx={{ fontSize: '0.8rem' }}
                                                        >
                                                            Tax
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            style={{
                                                                fontStyle: 'italic',
                                                                fontSize: '0.75rem',
                                                            }}
                                                        >
                                                            (will be calculated after checkout)
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            mb: 2,
                                                        }}
                                                    >
                                                        <Typography variant="h6">Total</Typography>
                                                        <Typography variant="h6">
                                                            $
                                                            {(
                                                                calculateCartTotal(cart).total /
                                                                    100 +
                                                                shippingCost
                                                            ).toFixed(2)}
                                                        </Typography>
                                                    </Box>
                                                    {cart.buyer_type === 'organization' && (
                                                        <>
                                                            {showVestAlert && (
                                                                <Alert
                                                                    variant="standard"
                                                                    style={{
                                                                        border: '2px solid green',
                                                                        borderRadius: '6px',
                                                                        margin: '8px',
                                                                    }}
                                                                    icon={
                                                                        <img
                                                                            src={Vest}
                                                                            alt="Vest"
                                                                            style={{
                                                                                width: '40px',
                                                                                height: '40px',
                                                                                marginTop: 'auto',
                                                                                marginBottom:
                                                                                    'auto',
                                                                            }}
                                                                        />
                                                                    }
                                                                >
                                                                    Please add at least{' '}
                                                                    <strong>
                                                                        {podCount - vestCount}
                                                                    </strong>{' '}
                                                                    more vest(s) to your cart. Each
                                                                    Fiyrpod you purchase entitles
                                                                    you to a free vest.
                                                                </Alert>
                                                            )}

                                                            {showFiyrlinkAlert && (
                                                                <Alert
                                                                    variant="standard"
                                                                    style={{
                                                                        border: '2px solid green',
                                                                        borderRadius: '6px',
                                                                        margin: '8px',
                                                                    }}
                                                                    icon={
                                                                        <img
                                                                            src={Fiyrlink}
                                                                            alt="Fiyrlink"
                                                                            style={{
                                                                                width: '40px',
                                                                                height: '40px',
                                                                                marginTop: 'auto',
                                                                                marginBottom:
                                                                                    'auto',
                                                                            }}
                                                                        />
                                                                    }
                                                                >
                                                                    Since you added{' '}
                                                                    <strong>5</strong> or more
                                                                    Fiyrpods, you are eligible for a
                                                                    free Fiyrlink! Add one to your
                                                                    cart to claim this offer.
                                                                </Alert>
                                                            )}
                                                        </>
                                                    )}
                                                    <Button
                                                        onClick={checkout}
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        size="large"
                                                        disabled={showVestAlert || isCheckingOut}
                                                        startIcon={
                                                            isCheckingOut ? (
                                                                <CircularProgress size={20} />
                                                            ) : null
                                                        }
                                                    >
                                                        {isCheckingOut
                                                            ? 'Processing...'
                                                            : 'Checkout'}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {userType === 'organization' ? (
                            <ShopAsOrganization
                                buyerOrganization={buyerOrganization}
                                sellerOrganization={sellerOrganization!}
                                hasActiveCart={!!cart}
                                hasActiveOrgSubscription={hasActiveOrgSubscription}
                            />
                        ) : userType === 'user' ? (
                            <ShopAsAthlete
                                sellerOrganization={sellerOrganization!}
                                hasActiveCart={!!cart}
                            />
                        ) : null}
                    </>
                )}
            </Container>
        </>
    );
};

export default Shop;
