import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, CircularProgress, TextField, useTheme } from '@mui/material';
import { DataGridPremium, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { AdvancedOrganization } from '../networking.types';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useNetworkingOrganization } from '../state/NetworkingContext';
import {
    GetDashboardOrganizationParams,
    getOrgDashboardMetrics,
    getTierOneOrgs,
} from '../networking.api';
import { Organization } from '../../../utils';
import debounce from 'lodash/debounce';

const DashboardMetricsScene = () => {
    const theme = useTheme();
    const [organizations, setOrganizations] = useState<AdvancedOrganization[]>([]);
    const [searchParams, setSearchParams] = useState<GetDashboardOrganizationParams>({
        sort: '-uploads_this_month',
        page: 1,
        per_page: 10,
    });
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'uploads_this_month',
            sort: 'desc',
        },
    ]);
    const organization = useNetworkingOrganization();

    const [totalOrganizations, setTotalOrganizations] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [orgOptions, setOrgOptions] = useState<Organization[]>([]);
    const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
        setSearchParams({
            ...searchParams,
            page: newModel.page + 1,
            per_page: newModel.pageSize,
        });
    };

    useEffect(() => {
        setIsLoading(true);
        getOrgDashboardMetrics(organization?.data?.uuid as string, {
            ...searchParams,
        })
            .then((response) => {
                setOrganizations(response.data.data);
                setTotalOrganizations(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [organization, searchParams]);

    const formatCentsToDollars = (cents: number): string => {
        const dollars = cents / 100;
        return `$${dollars.toFixed(2)}`;
    };

    const columns: GridColDef<AdvancedOrganization, any, any>[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            sortable: true,
            type: 'string',
        },
        {
            field: 'sponsor_name',
            headerName: 'Sponsor',
            width: 150,
            sortable: false,
            type: 'string',
            renderCell: (params) => {
                return params.row.sponsor_name;
            },
        },
        {
            field: 'subscription_level',
            headerName: 'Subscription Level',
            width: 120,
            sortable: true,
            type: 'string',
            renderCell: (params) => {
                return params.row.subscription_tier;
            },
        },
        {
            field: 'uploads_this_month',
            headerName: 'Uploads this Month',
            width: 120,
            sortable: true,
            type: 'number',
        },
        {
            field: 'amount_past_due',
            headerName: 'Amount due',
            width: 120,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.amount_past_due || 0),
        },
        {
            field: 'total_commissions',
            headerName: 'Total Commissions',
            width: 150,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.total_commissions || 0),
        },
        {
            field: 'marketplace_commissions',
            headerName: 'Marketplace Commissions',
            width: 140,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.marketplace_commissions || 0),
        },
        {
            field: 'pib_bonus_commissions',
            headerName: 'PIB Bonus Commissions',
            width: 140,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.pib_bonus_commissions || 0),
        },
        {
            field: 'residual_commissions',
            headerName: 'Residual Commissions',
            width: 140,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.residual_commissions || 0),
        },
        {
            field: 'check_match_commissions',
            headerName: 'Check Match Commissions',
            width: 160,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.check_match_commissions || 0),
        },
        {
            field: 'org_pod_subscriptions',
            headerName: 'Org Pod Subscriptions',
            width: 140,
            sortable: true,
            type: 'number',
        },
        {
            field: 'athlete_pod_subscriptions',
            headerName: 'Athlete Pod Subscriptions',
            width: 140,
            sortable: true,
            type: 'number',
        },
        {
            field: 'athlete_count',
            headerName: 'Athlete Count',
            width: 120,
            sortable: true,
            type: 'number',
        },
        {
            field: 'created_at',
            headerName: 'Joined',
            width: 100,
            sortable: true,
            type: 'string',
            renderCell: (params) => {
                const date = new Date(params.value);
                return date.toISOString().split('T')[0];
            },
        },
    ];

    const handleSearchClick = () => {
        // Update search params with organization_uuid if org is selected, or remove it if not
        setSearchParams((prevParams) => {
            const newParams = { ...prevParams };

            if (selectedOrg) {
                newParams.organization_uuid = selectedOrg.uuid;
            } else {
                delete newParams.organization_uuid;
            }

            return newParams;
        });
    };

    const fetchOrganizations = async (inputValue: string) => {
        try {
            const response = await getTierOneOrgs(organization?.data?.uuid as string, {
                name: inputValue,
            });
            setOrgOptions(response.data.data);
        } catch (error) {
            console.error('Failed to fetch organizations', error);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchOrganizations = useCallback(
        debounce((inputValue: string) => {
            fetchOrganizations(inputValue).then(() => {});
        }, 300),
        [organization?.data?.uuid],
    );

    const handleOrgSort = async (model: GridSortModel) => {
        setIsLoading(true);
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        if (sortField === 'subscription_level') {
            sortField = 'subscription_tier_sort';
        }

        /**
         * Sort will be undefined, or it will be ascending, ie "createdAt", or in this case
         * it will be descending, ie "-createdAt". If descending, we prepend a hyphen.
         */
        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        // Update your API request with the new sort model and fetch the data again
        const params = {
            ...searchParams,
            sort: sortField as GetDashboardOrganizationParams['sort'],
        };

        try {
            const response = await getOrgDashboardMetrics(
                organization?.data?.uuid as string,
                params,
            );
            setOrganizations(response.data.data);
            setTotalOrganizations(response.data.meta.total);
        } catch (error) {
            console.error('Failed to fetch sorted organizations', error);
        } finally {
            setIsLoading(false);
        }
    };

    // @ts-ignore
    return (
        <div className="relative">
            {isLoading && (
                <div
                    style={{
                        position: 'fixed',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9999,
                    }}
                >
                    <div className="bg-white rounded-lg p-4 shadow-lg flex flex-col items-center gap-2">
                        <CircularProgress size={80} />
                    </div>
                </div>
            )}
            <Box sx={{ margin: theme.spacing(0, 2) }}>
                <h2>Organization Metrics</h2>
                <h4>Your organization and all level 1 tier partners</h4>
                <Box display="flex" alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                    <Autocomplete
                        size="small"
                        options={orgOptions}
                        getOptionLabel={(option) => option.name}
                        onInputChange={(event, value) => debouncedFetchOrganizations(value)}
                        onChange={(event, value) => setSelectedOrg(value)}
                        value={selectedOrg}
                        clearOnBlur={false}
                        renderInput={(params) => (
                            <TextField {...params} label="Filter by Organization" />
                        )}
                        sx={{ width: 250, marginRight: theme.spacing(2) }}
                    />
                    <Button variant="contained" onClick={handleSearchClick}>
                        Search
                    </Button>
                </Box>
                <Box width="98%" maxHeight="600px" overflow="auto">
                    <DataGridPremium
                        rows={organizations}
                        columns={columns}
                        checkboxSelection={false}
                        sortingMode="server"
                        onSortModelChange={(model: GridSortModel) => {
                            setSortModel(model);
                            handleOrgSort(model);
                        }}
                        sortModel={sortModel}
                        getRowId={(row) => row.id}
                        style={{ height: '624px', marginBottom: '50px' }}
                        pagination
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        rowCount={totalOrganizations}
                        paginationMode="server"
                        disableColumnMenu={true}
                        showCellVerticalBorder={true}
                        sx={{
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell': {
                                fontSize: '0.6rem',
                            },
                            '& .MuiDataGrid-columnHeader': {
                                fontSize: '0.6rem',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontSize: '0.6rem',
                            },
                            '& .MuiDataGrid-footerContainer': {
                                fontSize: '0.6rem',
                            },
                        }}
                    />
                </Box>
            </Box>
        </div>
    );
};

export default DashboardMetricsScene;
