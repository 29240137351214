import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { LicenseInfo } from '@mui/x-license';
import { AthleteSubscriptionsProvider } from 'contexts/AthleteContext';
import { OrganizationsProvider } from 'contexts/OrganizationsContext';
import { useNavigationContext } from 'Layouts/Navigation/NavigationContext';
import LeavePagePrompt from 'Layouts/Partials/LeavePagePrompt';
import SetNavigation from 'Layouts/Partials/SetNavigation';
import routeconfig, { RouteConfigProps } from 'Layouts/routeconfig';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, useLocation, Redirect, Route, Switch } from 'react-router-dom';
import { authSelector } from 'redux/reducers/auth';
import { DefaultTheme } from 'themes';
import { QueryParamProvider } from 'use-query-params';
import { ChatProvider } from './contexts/ChatContext';
import { PusherProvider } from './contexts/PusherContext';
import { UserProvider } from './contexts/UserContext';
import NoOrganizationsWarning from './pages/Organization/NoOrganizationsWarning';
import { MessageProvider, useMessage } from './contexts/MessageContext';
import Lottie from 'lottie-react';
import animationData from 'lottie-animations/checkbox.json';
import { AdminProvider } from './contexts/AdminContext';
import { ShoppingCartProvider } from './contexts/ShoppingCartContext';

LicenseInfo.setLicenseKey(
    'b89bf036b45c170df49776851d9a5a77Tz05Nzg4NyxFPTE3NTc2MTQ0MzgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=',
);

// Component to handle the display of success messages from Stripe after a purchase.
const OverlayWithMessage: React.FC = () => {
    const location = useLocation();
    const { message, setMessage } = useMessage();
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        if (!searchParams.get('stripeSuccess')) {
            return;
        }

        setMessage('Purchase was successful!');

        // Set a timeout to clear the message and query string param after 3 seconds
        const timer = setTimeout(() => {
            setMessage('');
            const currentUrl = window.location.href;
            const regex = /([?&])stripeSuccess=true(&|$)/;
            window.location.href = currentUrl
                .replace(regex, '&')
                .replace(/&$/, '')
                .replace(/\?$/, '');
        }, 3000);

        return () => clearTimeout(timer);
    }, [location, setMessage]);

    if (!message) return null;

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1000,
            }}
        >
            <Lottie animationData={animationData} style={{ width: '300px', height: '300px' }} />
            <p
                style={{
                    color: 'white',
                    marginTop: '20px',
                    fontSize: '24px',
                    textShadow: '2px 2px 4px #000000',
                }}
            >
                {message}
            </p>
        </div>
    );
};

const App = () => {
    const { isAuth } = useSelector(authSelector);
    const { isRedirectionBlocked, blockRedirection } = useNavigationContext();

    // AbilityContext provider is used to manage permissions in the context
    // The default value for the permissions is null when provider is initialized
    // We will be pulling the permissions for every refresh to make sure user does not have broken access
    // The refresh in case of react is the react router dom location change
    // For that, we need to get access to location and detect the changes in location to know that user have navigated to a new page and we need to reload permissions
    // However, location object is accessible inside Router/BrowserRouter Context
    // So we have that logic under `Routes` component which is de<ciding component in this context hierarchy
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={DefaultTheme}>
                <AdminProvider>
                    <ShoppingCartProvider>
                        <LocalizationProvider
                            dateAdapter={AdapterLuxon}
                            localeText={{ start: 'After', end: 'Before' }}
                        >
                            <PusherProvider>
                                <CssBaseline />

                                <CookiesProvider>
                                    <Router>
                                        <QueryParamProvider ReactRouterRoute={Route}>
                                            <MessageProvider>
                                                <OverlayWithMessage />
                                            </MessageProvider>
                                            <OrganizationsProvider>
                                                <UserProvider>
                                                    <AthleteSubscriptionsProvider>
                                                        <ChatProvider>
                                                            <Switch>
                                                                <Route
                                                                    exact={true}
                                                                    path={'/no-organizations'}
                                                                >
                                                                    <NoOrganizationsWarning />
                                                                </Route>
                                                                {routeconfig.map(
                                                                    (
                                                                        config: RouteConfigProps,
                                                                        index,
                                                                    ) => {
                                                                        return (
                                                                            <Route
                                                                                key={`route-${index}`}
                                                                                exact={config.exact}
                                                                                path={config.path}
                                                                                render={(
                                                                                    history,
                                                                                ) => {
                                                                                    // Unblock the router prompt when redirection happens
                                                                                    history.history.listen(
                                                                                        () =>
                                                                                            blockRedirection(
                                                                                                false,
                                                                                            ),
                                                                                    );

                                                                                    if (
                                                                                        config.private &&
                                                                                        !isAuth
                                                                                    ) {
                                                                                        return (
                                                                                            <Redirect
                                                                                                to={{
                                                                                                    pathname:
                                                                                                        '/login',
                                                                                                    state: {
                                                                                                        referrer:
                                                                                                            history
                                                                                                                .location
                                                                                                                .pathname +
                                                                                                            history
                                                                                                                .location
                                                                                                                .search,
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        );
                                                                                    }

                                                                                    if (
                                                                                        config.is_auth_route &&
                                                                                        isAuth
                                                                                    ) {
                                                                                        return (
                                                                                            <Redirect to="/home" />
                                                                                        );
                                                                                    }
                                                                                    const element =
                                                                                        React.createElement(
                                                                                            config.layout,
                                                                                            {},
                                                                                            React.createElement(
                                                                                                config.component,
                                                                                                {},
                                                                                            ),
                                                                                        );

                                                                                    const page =
                                                                                        element;

                                                                                    return (
                                                                                        <>
                                                                                            <SetNavigation
                                                                                                config={
                                                                                                    config
                                                                                                }
                                                                                            />
                                                                                            <LeavePagePrompt
                                                                                                when={
                                                                                                    isRedirectionBlocked
                                                                                                }
                                                                                            />
                                                                                            <>
                                                                                                {
                                                                                                    page
                                                                                                }
                                                                                            </>
                                                                                        </>
                                                                                    );
                                                                                }}
                                                                            ></Route>
                                                                        );
                                                                    },
                                                                )}
                                                                <Route path="*">
                                                                    <Redirect to={'/home'} />
                                                                </Route>
                                                            </Switch>
                                                        </ChatProvider>
                                                    </AthleteSubscriptionsProvider>
                                                </UserProvider>
                                            </OrganizationsProvider>
                                        </QueryParamProvider>
                                    </Router>
                                </CookiesProvider>
                            </PusherProvider>
                        </LocalizationProvider>
                    </ShoppingCartProvider>
                </AdminProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
