import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Snackbar,
    Stack,
    Typography,
} from '@mui/material';
import MuxPlayer from '@mux/mux-player-react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { viewOrganization } from '../../../api';
import {
    createCheckoutMarketplaceItem,
    createCheckoutMarketplaceSubscriptionItem,
} from '../../../api/Auth/createCheckoutMarketplaceItem';
import createCheckoutPodOrgSubscriptionWithVests from '../../../api/Auth/createCheckoutPodOrgSubscriptionWithVests';
import { getPhysicalProducts } from '../../../api/Auth/getPhysicalProducts';
import viewOrganizationBySlug from '../../../api/Organization/viewOrganizationBySlug';
import PhysicalItemPurchaseDialog from '../../../components/Dialogs/PhysicalItemPurchaseDialog';
import { AbilityContext } from '../../../components/Functional/Can';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';
import Charger1x from '../../../images/charger1.png';
import Charger3x from '../../../images/charger3x.png';
import Fiyrlink from '../../../images/fiyrlink.png';
import Pod from '../../../images/pod.png';
import Vest from '../../../images/vestTransparent.png';
import { Organization, PhysicalProductForPurchase } from '../../../utils';
import QuantityControl from '../../website/scenes/QuantityControl';
import GlobalMarketplaceCard from '../components/GlobalMarketplaceCard';
import MarketplaceCard from '../components/MarketplaceCard';
import { createMarketplaceCheckoutSession, getLocalMarketplaceItems } from '../marketplace.api';
import { MarketplaceItem } from '../marketplace.types';

const MarketplaceHome = () => {
    const [localMarketplaceItems, setLocalMarketplaceItems] = useState<MarketplaceItem[]>([]);
    const [creatingCheckout, setCreatingCheckout] = useState(false);
    const [snackMessage, setSnackMessage] = useState<string>('');
    const [itemsLoaded, setItemsLoaded] = useState(false);
    const [organization, setOrganization] = useState<Organization>();
    const [filters, setFilters] = React.useState<{
        'filter[title]': string;
        'filter[category]': 'all' | 'form' | 'session';
        'filter[sales_type]': 'business_to_business';
    }>({
        'filter[category]': 'all',
        'filter[title]': '',
        'filter[sales_type]': 'business_to_business',
    });
    const [selectedOrganizationForFiyrPodSub, setSelectedOrganizationForFiyrPodSub] =
        useState<string>('');

    // Vest purchase states
    const [selectedOrgForVestPurchase, setSelectedOrgForVestPurchase] = useState<string>('');
    const [vestsForPurchase, setVestsForPurchase] = useState<PhysicalProductForPurchase[]>([]);
    const [isPurchasingVests, setIsPurchasingVests] = useState<boolean>(false);

    // Charger purchase states
    const [selectedOrgForSingleChargerPurchase, setSelectedOrgForSingleChargerPurchase] =
        useState<string>('');
    const [singleChargersForPurchase, setSingleChargersForPurchase] = useState<
        PhysicalProductForPurchase[]
    >([]);
    const [isPurchasingSingleChargers, setIsPurchasingSingleChargers] = useState<boolean>(false);

    const [selectedOrgForTripleChargerPurchase, setSelectedOrgForTripleChargerPurchase] =
        useState<string>('');
    const [tripleChargersForPurchase, setTripleChargersForPurchase] = useState<
        PhysicalProductForPurchase[]
    >([]);
    const [isPurchasingTripleChargers, setIsPurchasingTripleChargers] = useState<boolean>(false);
    const [selectedOrgForFiyrlinkPurchase, setSelectedOrgForFiyrlinkPurchase] =
        useState<string>('');
    const [fiyrlinksForPurchase, setFiyrlinksForPurchase] = useState<PhysicalProductForPurchase[]>(
        [],
    );
    const [isPurchasingFiyrlinks, setIsPurchasingFiyrlinks] = useState<boolean>(false);

    // Pod with free vest purchase states
    const [selectedVestCountsForPods, setSelectedVestCountsForPods] = useState<
        Record<string, number>
    >({});
    const [isPurchasingPods, setIsPurchasingPods] = useState<boolean>(false);
    const [podsForPurchase, setPodsForPurchase] = useState<PhysicalProductForPurchase[]>([]);
    const [selectedPodCounts, setSelectedPodCounts] = useState<Record<string, number>>({});

    // get the seller organization ID or the organization slug for the Marketplace from url params
    const { orgUuidOrSlug } = useParams<{ orgUuidOrSlug: string }>();
    const { organizations } = useContext(OrganizationsContext);
    const ability = useContext(AbilityContext);
    const filteredOrganizations = organizations.filter((o) =>
        ability.can('organization:update', o),
    );

    /**
     * @todo - Upgrade/refactor this when necessary. These are for display only.
     */
    const SHIPPING_FEE = 15; // Flat rate shipping fee $15

    const handleVestForPodCountChange = (priceId: string, count: number) => {
        setSelectedVestCountsForPods((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[priceId];
            } else {
                newCounts[priceId] = count;
            }
            return newCounts;
        });
    };

    const handlePodCountChange = (uuid: string, count: number) => {
        setSelectedPodCounts((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[uuid];
            } else {
                newCounts[uuid] = count;
            }
            return newCounts;
        });
    };

    const isUuid = (value: string): boolean => {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidRegex.test(value);
    };

    useEffect(() => {
        const fetchOrganization = async () => {
            try {
                if (isUuid(orgUuidOrSlug)) {
                    const response = await viewOrganization(orgUuidOrSlug);
                    setOrganization(response.data);
                } else {
                    const response = await viewOrganizationBySlug(orgUuidOrSlug);
                    setOrganization(response.data);
                }
            } catch (e) {
                setSnackMessage('Error fetching organization');
            } finally {
                setItemsLoaded(true);
            }
        };

        const fetchLocalMarketplaceItems = async (orgUuid: string) => {
            try {
                const response = await getLocalMarketplaceItems(orgUuid, filters);
                setLocalMarketplaceItems(response.data);
            } catch (e) {
                setSnackMessage('Error fetching items');
            } finally {
                setItemsLoaded(true);
            }
        };

        fetchOrganization().then(() => {
            // Not necessary in prod but avoids annoying double ajax request race condition in local dev.
            if (typeof organization?.uuid === 'string') {
                fetchLocalMarketplaceItems(organization.uuid).then(() => {});
            }
        });
    }, [organization?.uuid, orgUuidOrSlug, filters]);

    const handlePodPurchase = async () => {
        setCreatingCheckout(true);
        createCheckoutPodOrgSubscriptionWithVests(
            window.location.href,
            selectedOrganizationForFiyrPodSub,
            organization?.uuid as string,
            selectedVestCountsForPods,
            selectedPodCounts,
        )
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                setSnackMessage(`Error creating checkout session. ${e.response?.data?.message}`);
                setCreatingCheckout(false);
            });
    };

    const handleVestPurchase = async (selectedVestCounts: Record<string, number>) => {
        createCheckoutMarketplaceItem(
            window.location.href,
            selectedOrgForVestPurchase,
            organization?.uuid as string,
            selectedVestCounts,
        )
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                setSnackMessage(`Error creating checkout session. ${e.response?.data?.message}`);
            });
    };

    const handleSingleChargerPurchase = async (
        selectedSingleChargerCounts: Record<string, number>,
    ) => {
        createCheckoutMarketplaceItem(
            window.location.href,
            selectedOrgForSingleChargerPurchase,
            organization?.uuid as string,
            selectedSingleChargerCounts,
        )
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                setSnackMessage(`Error creating checkout session. ${e.response?.data?.message}`);
            });
    };

    const handleTripleChargerPurchase = async (
        selectedTripleChargerCounts: Record<string, number>,
    ) => {
        createCheckoutMarketplaceItem(
            window.location.href,
            selectedOrgForTripleChargerPurchase,
            organization?.uuid as string,
            selectedTripleChargerCounts,
        )
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                setSnackMessage(`Error creating checkout session. ${e.response?.data?.message}`);
            });
    };

    const handleFiyrlinkPurchase = async (selectedFiyrlinkCounts: Record<string, number>) => {
        createCheckoutMarketplaceSubscriptionItem(
            window.location.href,
            selectedOrgForFiyrlinkPurchase,
            organization?.uuid as string,
            selectedFiyrlinkCounts,
        )
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                setSnackMessage(`Error creating checkout session. ${e.response?.data?.message}`);
            });
    };

    const isPurchasePodButtonDisabled = () => {
        return (
            Object.values(selectedPodCounts).reduce((sum, count) => sum + count, 0) == 0 ||
            Object.values(selectedPodCounts).reduce((sum, count) => sum + count, 0) !==
                Object.values(selectedVestCountsForPods).reduce((sum, count) => sum + count, 0)
        );
    };

    const calculateTotalPodCost = () => {
        let totalPods = Object.values(selectedPodCounts).reduce((sum, count) => sum + count, 0);
        let totalCost = totalPods * 60;
        return totalCost.toFixed(2); // Returns the total cost as a string with 2 decimal places
    };

    const handleBuyPodClick = () => {
        getPhysicalProducts('FiyrPod Vest')
            .then((response) => {
                setVestsForPurchase(response.data.data);
                setIsPurchasingPods(true);
            })
            .catch((e: any) => {
                setSnackMessage(`Error retrieving FiyrPod vests. ${e.response?.data?.message}`);
            });

        getPhysicalProducts('Fiyrpod')
            .then((response) => {
                setPodsForPurchase(response.data.data);
                setIsPurchasingPods(true);
            })
            .catch((e: any) => {
                setSnackMessage(`Error retrieving FiyrPods. ${e.response?.data?.message}`);
            });
    };

    return (
        <main>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!snackMessage}
                autoHideDuration={6000}
                message={snackMessage}
                onClose={() => setSnackMessage('')}
            />
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                    backgroundImage: `url(${organization?.banner_url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent gray overlay
                    },
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        align="center"
                        color="white"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '2.5rem',
                            position: 'relative',
                            textShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        {organization?.name} Coach Marketplace
                    </Typography>
                    <Typography
                        variant="h5"
                        align="center"
                        color="white"
                        paragraph
                        sx={{
                            fontWeight: 'bold',
                            position: 'relative',
                            fontSize: '1.5rem',
                            textShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        {organization?.about_us
                            ? organization.about_us
                            : 'Buy premium products, subscriptions, training templates and assessments to use for your organization.'}
                    </Typography>
                </Container>
            </Box>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                }}
                maxWidth={'xl'}
            >
                <Stack direction="row" sx={{ my: 8 }} spacing={4}>
                    {organization?.mux_playback_id && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '60%',
                                margin: 'auto',
                                marginBottom: '20px',
                            }}
                        >
                            <MuxPlayer playbackId={organization.mux_playback_id} />
                        </div>
                    )}
                </Stack>

                {organization?.uuid && (
                    <>
                        <Typography variant="h5" component="h1" gutterBottom>
                            Global Coach Marketplace
                        </Typography>

                        <Grid
                            container
                            spacing={1}
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                marginTop: '32px',
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '32px',
                                }}
                            >
                                <GlobalMarketplaceCard
                                    title="FiyrPod"
                                    body="Purchase 🔥 FiyrPod GPS units and subscriptions here. To keep costs low, units do not come with a ⚡️charger unless you lease 3 or more and are a 🏅 Gold Org Member"
                                    image={Pod}
                                    buttonText="BUY PODS"
                                    organizations={filteredOrganizations}
                                    selectedOrganization={selectedOrganizationForFiyrPodSub}
                                    onOrganizationChange={(event) =>
                                        setSelectedOrganizationForFiyrPodSub(event.target.value)
                                    }
                                    onButtonClick={handleBuyPodClick}
                                    disabled={!selectedOrganizationForFiyrPodSub}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '32px',
                                }}
                            >
                                <GlobalMarketplaceCard
                                    title="FiyrPod Vest"
                                    body="Purchase your FiyrPod vests here. You can purchase multiple vest sizes and quantities."
                                    image={Vest}
                                    buttonText="Buy Vests"
                                    organizations={filteredOrganizations}
                                    selectedOrganization={selectedOrgForVestPurchase}
                                    onOrganizationChange={(event) =>
                                        setSelectedOrgForVestPurchase(event.target.value)
                                    }
                                    onButtonClick={() => {
                                        getPhysicalProducts('FiyrPod Vest')
                                            .then((response) => {
                                                setVestsForPurchase(response.data.data);
                                                setIsPurchasingVests(true);
                                            })
                                            .catch((e: any) => {
                                                setSnackMessage(
                                                    `Error retrieving FiyrPod vests. ${e.response?.data?.message}`,
                                                );
                                            });
                                    }}
                                    disabled={!selectedOrgForVestPurchase}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '32px',
                                }}
                            >
                                <GlobalMarketplaceCard
                                    title="FiyrPod Single Charger"
                                    body="⚡ Quick Charger and 📱Phone Cradle for 📶 Maximum Reception and 🛡️ Protection"
                                    image={Charger1x}
                                    buttonText="Buy Single Chargers"
                                    organizations={filteredOrganizations}
                                    selectedOrganization={selectedOrgForSingleChargerPurchase}
                                    onOrganizationChange={(event) =>
                                        setSelectedOrgForSingleChargerPurchase(event.target.value)
                                    }
                                    onButtonClick={() => {
                                        getPhysicalProducts('FiyrPod Single Charger')
                                            .then((response) => {
                                                setSingleChargersForPurchase(response.data.data);
                                                setIsPurchasingSingleChargers(true);
                                            })
                                            .catch((e: any) => {
                                                setSnackMessage(
                                                    `Error retrieving FiyrPod single chargers. ${e.response?.data?.message}`,
                                                );
                                            });
                                    }}
                                    disabled={!selectedOrgForSingleChargerPurchase}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '32px',
                                }}
                            >
                                <GlobalMarketplaceCard
                                    title="FiyrPod Triple Charger"
                                    body="Triple ⚡ Quick Charger and Case for 🛡️ Protection"
                                    image={Charger3x}
                                    buttonText="Buy Triple Chargers"
                                    organizations={filteredOrganizations}
                                    selectedOrganization={selectedOrgForTripleChargerPurchase}
                                    onOrganizationChange={(event) =>
                                        setSelectedOrgForTripleChargerPurchase(event.target.value)
                                    }
                                    onButtonClick={() => {
                                        getPhysicalProducts('FiyrPod Triple Charger')
                                            .then((response) => {
                                                setTripleChargersForPurchase(response.data.data);
                                                setIsPurchasingTripleChargers(true);
                                            })
                                            .catch((e: any) => {
                                                setSnackMessage(
                                                    `Error retrieving FiyrPod triple chargers. ${e.response?.data?.message}`,
                                                );
                                            });
                                    }}
                                    disabled={!selectedOrgForTripleChargerPurchase}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '32px',
                                }}
                            >
                                <GlobalMarketplaceCard
                                    title="FiyrLink Laptop Gateway"
                                    body="Purchase your FiyrLink gateways here."
                                    image={Fiyrlink}
                                    buttonText="Buy Fiyrlink Gateways"
                                    organizations={filteredOrganizations}
                                    selectedOrganization={selectedOrgForFiyrlinkPurchase}
                                    onOrganizationChange={(event) =>
                                        setSelectedOrgForFiyrlinkPurchase(event.target.value)
                                    }
                                    onButtonClick={() => {
                                        getPhysicalProducts('Fiyrlink')
                                            .then((response) => {
                                                setFiyrlinksForPurchase(response.data.data);
                                                setIsPurchasingFiyrlinks(true);
                                            })
                                            .catch((e: any) => {
                                                setSnackMessage(
                                                    `Error retrieving Fiyrlink gateways. ${e.response?.data?.message}`,
                                                );
                                            });
                                    }}
                                    disabled={!selectedOrgForFiyrlinkPurchase}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}

                <Stack
                    direction="row"
                    sx={{ my: 4, justifyContent: 'space-between', marginTop: '40px' }}
                    spacing={4}
                >
                    <h2>Local Coach Marketplace</h2>
                    <FormControl>
                        <Select
                            variant={'outlined'}
                            size="small"
                            labelId="category"
                            id="category"
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    'filter[category]': e.target.value as any,
                                })
                            }
                            value={filters['filter[category]']}
                        >
                            <MenuItem value={'all'}>All Local Items</MenuItem>
                            <MenuItem value={'form'}>Assessments</MenuItem>
                            <MenuItem value={'session'}>Training Templates</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>

                {/* End hero unit */}
                <Grid
                    container
                    spacing={1}
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        marginTop: '20px',
                    }}
                >
                    {localMarketplaceItems.map((i) => (
                        <Grid
                            key={i.uuid}
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '4rem',
                            }}
                        >
                            <MarketplaceCard
                                id={i.uuid}
                                sellerUuid={organization?.uuid || ''}
                                title={i.title}
                                description={i.description}
                                image={i.imageUrl}
                                price={i.price}
                                avatarUrl={i.organizationImageUrl}
                                headerTitle={i.organizationName}
                                loading={creatingCheckout}
                                organizations={filteredOrganizations}
                                onPurchase={async (buyerOrganization) => {
                                    // create a stripe checkout session
                                    // redirect to the checkout page
                                    setCreatingCheckout(true);
                                    try {
                                        const response = await createMarketplaceCheckoutSession(
                                            i.uuid,
                                            buyerOrganization.uuid,
                                            organization?.uuid || '',
                                        );
                                        // redirect to the checkout page
                                        window.location.href = response.data.session.url;
                                    } catch (e: any) {
                                        setSnackMessage(
                                            `Error creating checkout session. ${e.response?.data?.message}`,
                                        );
                                    } finally {
                                        setCreatingCheckout(false);
                                    }
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>

                {localMarketplaceItems.length === 0 && itemsLoaded && (
                    <Box sx={{ my: 20 }}>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            No items found
                        </Typography>
                    </Box>
                )}
                <Dialog open={isPurchasingPods} maxWidth="sm" fullWidth>
                    <DialogTitle>Purchase Fiyrpods</DialogTitle>

                    <span style={{ fontSize: 'small', marginLeft: '10px' }}>
                        Pods and Vests (select a free vest for every pod)
                    </span>

                    <DialogContent dividers sx={{ overflowY: 'scroll', paddingLeft: '0' }}>
                        {podsForPurchase.map((pod) => (
                            <FormControl
                                key={pod.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 24,
                                            width: 24,
                                            marginRight: 2,
                                        }}
                                        alt="Pod"
                                        src={Pod}
                                    />
                                    <Typography
                                        sx={{ width: 'calc(80% - 24px)', fontSize: '0.8rem' }}
                                    >
                                        {pod.name}
                                        <span style={{ fontWeight: '500' }}> $60/month </span>
                                        <span
                                            style={{
                                                color: pod.inventory_count > 0 ? 'green' : 'red',
                                                fontStyle: 'italic',
                                                fontSize: '0.65rem',
                                            }}
                                        >
                                            {pod.inventory_count > 0
                                                ? ` ${pod.inventory_count} available`
                                                : ' available soon'}
                                        </span>
                                    </Typography>
                                    {pod.inventory_count > 0 && (
                                        <QuantityControl
                                            value={selectedPodCounts[pod.uuid] || 0}
                                            onDecrement={() =>
                                                handlePodCountChange(
                                                    pod.uuid,
                                                    Math.max(
                                                        (selectedPodCounts[pod.uuid] || 0) - 1,
                                                        0,
                                                    ),
                                                )
                                            }
                                            onIncrement={() =>
                                                handlePodCountChange(
                                                    pod.uuid,
                                                    Math.min(
                                                        (selectedPodCounts[pod.uuid] || 0) + 1,
                                                        Math.min(pod.inventory_count),
                                                    ),
                                                )
                                            }
                                            decrementDisabled={
                                                (selectedPodCounts[pod.uuid] || 0) === 0
                                            }
                                            incrementDisabled={
                                                (selectedPodCounts[pod.uuid] || 0) >=
                                                Math.min(pod.inventory_count)
                                            }
                                        />
                                    )}
                                </Stack>
                            </FormControl>
                        ))}

                        {vestsForPurchase.map((vest) => (
                            <FormControl
                                key={vest.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 24,
                                            width: 24,
                                            marginRight: 2,
                                        }}
                                        alt="Vest"
                                        src={Vest}
                                    />
                                    <Typography
                                        sx={{ width: 'calc(80% - 24px)', fontSize: '0.8rem' }}
                                    >
                                        {vest.name}
                                        <span style={{ fontWeight: '500' }}> $0 </span>
                                        <span
                                            style={{
                                                color: vest.inventory_count > 0 ? 'green' : 'red',
                                                fontStyle: 'italic',
                                                fontSize: '0.65rem',
                                            }}
                                        >
                                            {vest.inventory_count > 0
                                                ? ` ${vest.inventory_count} available`
                                                : ' available soon'}
                                        </span>
                                    </Typography>
                                    {vest.inventory_count > 0 && (
                                        <QuantityControl
                                            value={
                                                selectedVestCountsForPods[vest.stripe_price_id] || 0
                                            }
                                            onDecrement={() =>
                                                handleVestForPodCountChange(
                                                    vest.stripe_price_id,
                                                    Math.max(
                                                        (selectedVestCountsForPods[
                                                            vest.stripe_price_id
                                                        ] || 0) - 1,
                                                        0,
                                                    ),
                                                )
                                            }
                                            onIncrement={() =>
                                                handleVestForPodCountChange(
                                                    vest.stripe_price_id,
                                                    Math.min(
                                                        (selectedVestCountsForPods[
                                                            vest.stripe_price_id
                                                        ] || 0) + 1,
                                                        Math.min(vest.inventory_count),
                                                    ),
                                                )
                                            }
                                            decrementDisabled={
                                                (selectedVestCountsForPods[vest.stripe_price_id] ||
                                                    0) === 0
                                            }
                                            incrementDisabled={
                                                (selectedVestCountsForPods[vest.stripe_price_id] ||
                                                    0) >= Math.min(vest.inventory_count)
                                            }
                                        />
                                    )}
                                </Stack>
                            </FormControl>
                        ))}
                    </DialogContent>
                    {isPurchasePodButtonDisabled() ? (
                        <Box
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <span style={{ fontSize: '0.65rem', color: 'red' }}>
                                Select at least one pod subscription and a matching number of vests
                            </span>
                        </Box>
                    ) : (
                        <div style={{ padding: '10px 16px 4px 0', textAlign: 'right' }}>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    fontSize: '0.65rem',
                                    color: '#424242',
                                    fontWeight: '450',
                                }}
                            >
                                Subtotal: ${calculateTotalPodCost()}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    fontSize: '0.6rem',
                                    color: '#424242',
                                }}
                            >
                                Tax: (will be calculated)
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    fontSize: '0.6rem',
                                    color: '#424242',
                                }}
                            >
                                Shipping: ${SHIPPING_FEE.toFixed(2)}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    fontSize: '0.65rem',
                                    color: '#424242',
                                    fontWeight: '600',
                                }}
                            >
                                Total: $
                                {(parseFloat(calculateTotalPodCost()) + SHIPPING_FEE).toFixed(2)}
                            </Typography>
                        </div>
                    )}
                    <DialogActions>
                        <Button onClick={() => setIsPurchasingPods(false)}>Cancel</Button>
                        <Button
                            onClick={handlePodPurchase}
                            variant="contained"
                            disabled={isPurchasePodButtonDisabled()}
                        >
                            Purchase
                        </Button>
                    </DialogActions>
                </Dialog>
                <PhysicalItemPurchaseDialog
                    open={isPurchasingVests}
                    onClose={() => setIsPurchasingVests(false)}
                    items={vestsForPurchase}
                    onPurchase={handleVestPurchase}
                    title="Purchase Vests"
                    imageSource={Vest}
                    shippingFee={SHIPPING_FEE}
                />
                <PhysicalItemPurchaseDialog
                    open={isPurchasingSingleChargers}
                    onClose={() => setIsPurchasingSingleChargers(false)}
                    items={singleChargersForPurchase}
                    onPurchase={handleSingleChargerPurchase}
                    title="Purchase Single Chargers"
                    imageSource={Charger1x}
                    shippingFee={SHIPPING_FEE}
                />
                <PhysicalItemPurchaseDialog
                    open={isPurchasingTripleChargers}
                    onClose={() => setIsPurchasingTripleChargers(false)}
                    items={tripleChargersForPurchase}
                    onPurchase={handleTripleChargerPurchase}
                    title="Purchase Triple Chargers"
                    imageSource={Charger3x}
                    shippingFee={SHIPPING_FEE}
                />
                <PhysicalItemPurchaseDialog
                    open={isPurchasingFiyrlinks}
                    onClose={() => setIsPurchasingFiyrlinks(false)}
                    items={fiyrlinksForPurchase}
                    onPurchase={handleFiyrlinkPurchase}
                    title="Purchase Fiyrlink Gateway"
                    imageSource={Fiyrlink}
                    shippingFee={SHIPPING_FEE}
                    subscription
                />
            </Container>
        </main>
    );
};

export default MarketplaceHome;
