import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Stack,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PhysicalProductForPurchase, Organization } from '../../../utils';
import { getPhysicalProducts } from '../../../api/Auth/getPhysicalProducts';
import { ErrorIcon } from 'stream-chat-react';
import { UseShoppingCart } from '../../../contexts/ShoppingCartContext';
import HoverImage from '../../../components/Images/HoverImage';
import HoverImageWithText from '../../../components/Images/HoverImageWithText';
import { MarketplaceItem } from '../../marketplace/marketplace.types';
import { getLocalMarketplaceItems } from '../../marketplace/marketplace.api';
import { CartInput } from './CartInput';
import goldMedal from '../../../images/gold-medal (1).png';
import silverMedal from '../../../images/silver-medal.png';
import bronzeMedal from '../../../images/bronze-medal.png';
import Pod from '../../../images/pod.png';
import Vest from '../../../images/vestTransparent.png';
import Charger from '../../../images/charger1.png';
import TripleCharger from '../../../images/charger3x.png';
import Fiyrlink from '../../../images/fiyrlink.png';

interface ShopAsOrganizationProps {
    buyerOrganization: Organization | null;
    sellerOrganization: Organization;
    hasActiveCart: boolean;
    hasActiveOrgSubscription: boolean;
}

export const ShopAsOrganization: React.FC<ShopAsOrganizationProps> = ({
    sellerOrganization,
    buyerOrganization,
    hasActiveCart,
    hasActiveOrgSubscription,
}) => {
    const [vestsForPurchase, setVestsForPurchase] = useState<PhysicalProductForPurchase[]>([]);
    const [podsForPurchase, setPodsForPurchase] = useState<PhysicalProductForPurchase[]>([]);
    const [selectedPodCounts, setSelectedPodCounts] = useState<Record<string, number>>({});
    const [singleChargersForPurchase, setSingleChargersForPurchase] = useState<
        PhysicalProductForPurchase[]
    >([]);
    const [tripleChargersForPurchase, setTripleChargersForPurchase] = useState<
        PhysicalProductForPurchase[]
    >([]);
    const [fiyrlinkGatewaysForPurchase, setFiyrlinkGatewaysForPurchase] = useState<
        PhysicalProductForPurchase[]
    >([]);
    const [selectedFiyrlinkGatewayCountsForPods, setSelectedFiyrlinkGatewayCountsForPods] =
        useState<Record<string, number>>({});
    const [hasSelectedOrgSubscription, setHasSelectedOrgSubscription] = useState<boolean>(false);
    const [localMarketplaceItems, setLocalMarketplaceItems] = useState<MarketplaceItem[]>([]);
    const [error, setError] = useState('');
    const { cart } = UseShoppingCart();

    useEffect(() => {
        console.debug('ShopAsOrganization - cart', cart);
    }, [cart]);

    useEffect(() => {
        if (!sellerOrganization) {
            return;
        }

        /**
         * Get pods, vests, chargers, and Fiyrlinks for purchase.
         */
        getPhysicalProducts('FiyrPod Vest')
            .then((response) => {
                setVestsForPurchase(response.data.data);
            })
            .catch((e: any) => {
                setError(`Error retrieving FiyrPod vests. ${e.response?.data?.message}`);
            });

        getPhysicalProducts('Fiyrpod')
            .then((response) => {
                setPodsForPurchase(response.data.data);
                const defaultPodCounts = response.data.data.reduce(
                    (
                        acc: Record<string, number>,
                        pod: PhysicalProductForPurchase,
                        index: number,
                    ) => {
                        acc[pod.uuid] = index === 0 && pod.inventory_count >= 5 ? 5 : 0;
                        return acc;
                    },
                    {},
                );
                setSelectedPodCounts(defaultPodCounts);
            })
            .catch((e: any) => {
                setError(`Error retrieving FiyrPods. ${e.response?.data?.message}`);
            });

        getPhysicalProducts('Fiyrpod Single Charger')
            .then((response) => {
                setSingleChargersForPurchase(response.data.data);
            })
            .catch((e: any) => {
                setError(`Error retrieving FiyrPod single chargers. ${e.response?.data?.message}`);
            });

        getPhysicalProducts('Fiyrpod Triple Charger')
            .then((response) => {
                setTripleChargersForPurchase(response.data.data);
            })
            .catch((e: any) => {
                setError(`Error retrieving FiyrPod triple chargers. ${e.response?.data?.message}`);
            });

        getPhysicalProducts('Fiyrlink')
            .then((response) => {
                setFiyrlinkGatewaysForPurchase(response.data.data);
                const defaultFiyrlinkCounts = response.data.data.reduce(
                    (
                        acc: Record<string, number>,
                        fiyrlink: PhysicalProductForPurchase,
                        index: number,
                    ) => {
                        acc[fiyrlink.stripe_price_id] = index === 0 ? 1 : 0;
                        return acc;
                    },
                    {},
                );
                setSelectedFiyrlinkGatewayCountsForPods(defaultFiyrlinkCounts);
            })
            .catch((e: any) => {
                setError(`Error retrieving Fiyrlink Laptop Gateways. ${e.response?.data?.message}`);
            });

        const localMarketplaceFilters = {
            'filter[category]': 'all',
            'filter[title]': '',
            'filter[sales_type]': 'business_to_business',
        };

        getLocalMarketplaceItems(sellerOrganization.uuid, localMarketplaceFilters).then(
            (response) => {
                setLocalMarketplaceItems(response.data);
            },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sellerOrganization]);

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: '24px',
                marginBottom: '24px',
            }}
        >
            <Dialog
                open={!!error}
                onClose={(event, reason) => {
                    console.log('on close event', event);
                    if (reason === 'backdropClick') {
                        return;
                    }
                    setError('');
                }}
                disableEscapeKeyDown={true}
                PaperProps={{
                    sx: {
                        minWidth: '320px',
                        maxWidth: '90vw',
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: '#dc2626',
                    }}
                >
                    <ErrorIcon />
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{error}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setError('');
                        }}
                        variant="contained"
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '24px',
                }}
            >
                {hasActiveCart ? (
                    <Box
                        sx={{
                            maxWidth: 512,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            border: '1px solid lightgray',
                            borderRadius: '8px',
                            padding: '12px',
                        }}
                    >
                        <FormControl
                            fullWidth
                            margin="normal"
                            style={{ marginTop: 0, marginBottom: '8px' }}
                        >
                            {hasActiveOrgSubscription ? (
                                <Typography
                                    sx={{
                                        fontSize: '0.8rem',
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        marginTop: 2,
                                        marginBottom: 4,
                                    }}
                                >
                                    You have an active{' '}
                                    <a
                                        href={`networking/${buyerOrganization?.uuid}/subscriptions`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        organization subscription
                                    </a>
                                </Typography>
                            ) : (
                                <>
                                    <Typography
                                        sx={{
                                            fontSize: '0.8rem',
                                            fontWeight: '600',
                                            textAlign: 'center',
                                            marginTop: 2,
                                            marginBottom: 4,
                                        }}
                                    >
                                        Organization Subscriptions
                                    </Typography>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 24,
                                                width: 24,
                                                marginRight: 2,
                                            }}
                                            alt="goldMedal"
                                            src={goldMedal}
                                        />
                                        <Typography
                                            sx={{
                                                width: 'calc(80% - 24px)',
                                                fontSize: '0.8rem',
                                            }}
                                        >
                                            Organization Gold Package
                                            <span style={{ fontWeight: '500' }}> $100/month</span>
                                        </Typography>
                                        <CartInput
                                            initialValue={0}
                                            name="Organization - Gold Package"
                                            maxValue={null}
                                            cartUuid={cart?.uuid}
                                            stripePriceId={
                                                process.env
                                                    .REACT_APP_STRIPE_ORGANIZATION_LEVEL_3_PRICE!
                                            }
                                            type="subscription"
                                            category="subscription"
                                            sellableId={null}
                                            disabled={!cart?.uuid || hasSelectedOrgSubscription}
                                            onAdded={() => setHasSelectedOrgSubscription(true)}
                                            onRemoved={() => setHasSelectedOrgSubscription(false)}
                                        />
                                    </Stack>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 24,
                                                width: 24,
                                                marginRight: 2,
                                            }}
                                            alt="silverMedal"
                                            src={silverMedal}
                                        />
                                        <Typography
                                            sx={{
                                                width: 'calc(80% - 24px)',
                                                fontSize: '0.8rem',
                                            }}
                                        >
                                            Organization Silver Package
                                            <span style={{ fontWeight: '500' }}> $50/month</span>
                                        </Typography>
                                        <CartInput
                                            initialValue={0}
                                            name="Organization - Silver Package"
                                            maxValue={null}
                                            cartUuid={cart?.uuid}
                                            stripePriceId={
                                                process.env
                                                    .REACT_APP_STRIPE_ORGANIZATION_LEVEL_2_PRICE!
                                            }
                                            type="subscription"
                                            category="subscription"
                                            sellableId={null}
                                            disabled={!cart?.uuid || hasSelectedOrgSubscription}
                                            onAdded={() => setHasSelectedOrgSubscription(true)}
                                            onRemoved={() => setHasSelectedOrgSubscription(false)}
                                        />
                                    </Stack>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 24,
                                                width: 24,
                                                marginRight: 2,
                                            }}
                                            alt="bronzeMedal"
                                            src={bronzeMedal}
                                        />
                                        <Typography
                                            sx={{
                                                width: 'calc(80% - 24px)',
                                                fontSize: '0.8rem',
                                            }}
                                        >
                                            Organization Bronze Package
                                            <span style={{ fontWeight: '500' }}> $25/month</span>
                                        </Typography>
                                        <CartInput
                                            initialValue={0}
                                            name="Organization - Bronze Package"
                                            maxValue={null}
                                            cartUuid={cart?.uuid}
                                            stripePriceId={
                                                process.env
                                                    .REACT_APP_STRIPE_ORGANIZATION_LEVEL_1_PRICE!
                                            }
                                            type="subscription"
                                            category="subscription"
                                            sellableId={null}
                                            disabled={!cart?.uuid || hasSelectedOrgSubscription}
                                            onAdded={() => setHasSelectedOrgSubscription(true)}
                                            onRemoved={() => setHasSelectedOrgSubscription(false)}
                                        />
                                    </Stack>
                                </>
                            )}
                        </FormControl>
                        <hr
                            style={{
                                width: '100%',
                                margin: '16px 0',
                                border: 'none',
                                borderTop: '1px solid #e0e0e0',
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: '600',
                                textAlign: 'center',
                                marginTop: 2,
                            }}
                        >
                            Fiyrpods
                        </Typography>
                        {podsForPurchase.map((pod) => (
                            <FormControl
                                key={pod.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <HoverImage src={Pod} alt="Pod" />
                                    <Typography
                                        sx={{
                                            width: 'calc(80% - 24px)',
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {pod.name}
                                        <span style={{ fontWeight: '500' }}>
                                            {' $'}
                                            {(pod.price / 100).toFixed(2)}
                                            /month
                                        </span>
                                        <span
                                            style={{
                                                color: pod.inventory_count > 0 ? 'green' : 'red',
                                                fontStyle: 'italic',
                                                fontSize: '0.65rem',
                                            }}
                                        >
                                            {pod.inventory_count > 0
                                                ? ` ${pod.inventory_count} available`
                                                : ' available soon'}
                                        </span>
                                    </Typography>
                                    {pod.inventory_count > 0 && (
                                        <CartInput
                                            initialValue={selectedPodCounts[pod.uuid] || 0}
                                            name={pod.name}
                                            maxValue={pod.inventory_count}
                                            cartUuid={cart?.uuid}
                                            stripePriceId={pod.stripe_price_id}
                                            type="physical_product"
                                            category="fiyrpod"
                                            sellableId={pod.uuid}
                                            disabled={!cart?.uuid}
                                        />
                                    )}
                                </Stack>
                            </FormControl>
                        ))}
                        <hr
                            style={{
                                width: '100%',
                                margin: '16px 0',
                                border: 'none',
                                borderTop: '1px solid #e0e0e0',
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: '600',
                                textAlign: 'center',
                                marginTop: 2,
                            }}
                        >
                            Vests
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '0.65rem',
                                fontWeight: '400',
                                textAlign: 'center',
                            }}
                        >
                            Get a free vest with every pod subscription
                        </Typography>
                        {vestsForPurchase.map((vest) => (
                            <FormControl
                                key={vest.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <HoverImage src={Vest} alt="Vest" />
                                    <Typography
                                        sx={{
                                            width: 'calc(80% - 24px)',
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {vest.name}
                                        <span style={{ fontWeight: '500' }}>
                                            {' $'}
                                            {(vest.price / 100).toFixed(2)}
                                        </span>
                                        <span
                                            style={{
                                                color: vest.inventory_count > 0 ? 'green' : 'red',
                                                fontStyle: 'italic',
                                                fontSize: '0.65rem',
                                            }}
                                        >
                                            {vest.inventory_count > 0
                                                ? ` ${vest.inventory_count} available`
                                                : ' available soon'}
                                        </span>
                                    </Typography>
                                    {vest.inventory_count > 0 && (
                                        <CartInput
                                            initialValue={0}
                                            name={vest.name}
                                            maxValue={vest.inventory_count}
                                            cartUuid={cart?.uuid}
                                            stripePriceId={vest.stripe_price_id}
                                            type="physical_product"
                                            category="vest"
                                            sellableId={vest.uuid}
                                            disabled={!cart?.uuid}
                                        />
                                    )}
                                </Stack>
                            </FormControl>
                        ))}
                        <hr
                            style={{
                                width: '100%',
                                margin: '16px 0',
                                border: 'none',
                                borderTop: '1px solid #e0e0e0',
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: '600',
                                textAlign: 'center',
                                marginTop: 2,
                            }}
                        >
                            Single Chargers
                        </Typography>
                        {singleChargersForPurchase.length === 0 && (
                            <Typography
                                sx={{
                                    fontSize: '0.65rem',
                                    textAlign: 'center',
                                    marginTop: 2,
                                    color: 'red',
                                }}
                            >
                                No single chargers available
                            </Typography>
                        )}
                        {singleChargersForPurchase.map((charger) => (
                            <FormControl
                                key={charger.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <HoverImage src={Charger} alt="Charger" />
                                    <Typography
                                        sx={{
                                            width: 'calc(80% - 24px)',
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {charger.name}
                                        <span style={{ fontWeight: '500' }}>
                                            {' $'}
                                            {(charger.price / 100).toFixed(2)}{' '}
                                        </span>
                                        <span
                                            style={{
                                                color:
                                                    charger.inventory_count > 0 ? 'green' : 'red',
                                                fontStyle: 'italic',
                                                fontSize: '0.65rem',
                                            }}
                                        >
                                            {charger.inventory_count > 0
                                                ? ` ${charger.inventory_count} available`
                                                : ' available soon'}
                                        </span>
                                    </Typography>
                                    {charger.inventory_count > 0 && (
                                        <CartInput
                                            initialValue={0}
                                            name={charger.name}
                                            maxValue={charger.inventory_count}
                                            cartUuid={cart?.uuid}
                                            stripePriceId={charger.stripe_price_id}
                                            type="physical_product"
                                            category="single_charger"
                                            sellableId={charger.uuid}
                                            disabled={!cart?.uuid}
                                        />
                                    )}
                                </Stack>
                            </FormControl>
                        ))}
                        <hr
                            style={{
                                width: '100%',
                                margin: '16px 0',
                                border: 'none',
                                borderTop: '1px solid #e0e0e0',
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: '600',
                                textAlign: 'center',
                                marginTop: 2,
                            }}
                        >
                            Triple Chargers
                        </Typography>
                        {tripleChargersForPurchase.length === 0 && (
                            <Typography
                                sx={{
                                    fontSize: '0.65rem',
                                    textAlign: 'center',
                                    marginTop: 2,
                                    color: 'red',
                                }}
                            >
                                No triple chargers available
                            </Typography>
                        )}
                        {tripleChargersForPurchase.map((charger) => (
                            <FormControl
                                key={charger.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <HoverImage src={TripleCharger} alt="TripleCharger" />
                                    <Typography
                                        sx={{
                                            width: 'calc(80% - 24px)',
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {charger.name}
                                        <span style={{ fontWeight: '500' }}>
                                            {' $'}
                                            {(charger.price / 100).toFixed(2)}{' '}
                                        </span>
                                        <span
                                            style={{
                                                color:
                                                    charger.inventory_count > 0 ? 'green' : 'red',
                                                fontStyle: 'italic',
                                                fontSize: '0.65rem',
                                            }}
                                        >
                                            {charger.inventory_count > 0
                                                ? ` ${charger.inventory_count} available`
                                                : ' available soon'}
                                        </span>
                                    </Typography>
                                    {charger.inventory_count > 0 && (
                                        <CartInput
                                            initialValue={0}
                                            name={charger.name}
                                            maxValue={charger.inventory_count}
                                            cartUuid={cart?.uuid}
                                            stripePriceId={charger.stripe_price_id}
                                            type="physical_product"
                                            category="triple_charger"
                                            sellableId={charger.uuid}
                                            disabled={!cart?.uuid}
                                        />
                                    )}
                                </Stack>
                            </FormControl>
                        ))}
                        <hr
                            style={{
                                width: '100%',
                                margin: '16px 0',
                                border: 'none',
                                borderTop: '1px solid #e0e0e0',
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: '600',
                                textAlign: 'center',
                                marginTop: 2,
                            }}
                        >
                            Fiyrlink Gateways
                        </Typography>
                        {fiyrlinkGatewaysForPurchase.length === 0 && (
                            <Typography
                                sx={{
                                    fontSize: '0.65rem',
                                    textAlign: 'center',
                                    marginTop: 2,
                                    color: 'red',
                                }}
                            >
                                No Fiyrlink gateways available
                            </Typography>
                        )}
                        {fiyrlinkGatewaysForPurchase.map((fiyrlink) => (
                            <FormControl
                                key={fiyrlink.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <HoverImage src={Fiyrlink} alt="Fiyrlink" />
                                    <Typography
                                        sx={{
                                            width: 'calc(80% - 24px)',
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {fiyrlink.name}
                                        <span style={{ fontWeight: '500' }}>
                                            {' $'}
                                            {(fiyrlink.price / 100).toFixed(2)}/month{' '}
                                        </span>
                                        <span
                                            style={{
                                                color:
                                                    fiyrlink.inventory_count > 0 ? 'green' : 'red',
                                                fontStyle: 'italic',
                                                fontSize: '0.65rem',
                                            }}
                                        >
                                            {fiyrlink.inventory_count > 0
                                                ? ` ${fiyrlink.inventory_count} available`
                                                : ' available soon'}
                                        </span>
                                    </Typography>
                                    {fiyrlink.inventory_count > 0 && (
                                        <CartInput
                                            initialValue={
                                                selectedFiyrlinkGatewayCountsForPods[
                                                    fiyrlink.stripe_price_id
                                                ] || 0
                                            }
                                            name={fiyrlink.name}
                                            maxValue={fiyrlink.inventory_count}
                                            cartUuid={cart?.uuid}
                                            stripePriceId={fiyrlink.stripe_price_id}
                                            type="physical_product"
                                            category="fiyrlink"
                                            sellableId={fiyrlink.uuid}
                                            disabled={!cart?.uuid}
                                        />
                                    )}
                                </Stack>
                            </FormControl>
                        ))}
                        <hr
                            style={{
                                width: '100%',
                                margin: '16px 0',
                                border: 'none',
                                borderTop: '1px solid #e0e0e0',
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: '600',
                                textAlign: 'center',
                                marginTop: 2,
                            }}
                        >
                            {sellerOrganization?.name} Marketplace Items
                        </Typography>
                        {!localMarketplaceItems.length && (
                            <Typography
                                sx={{
                                    fontSize: '0.65rem',
                                    textAlign: 'center',
                                    marginTop: 2,
                                    color: 'red',
                                }}
                            >
                                No marketplace items available
                            </Typography>
                        )}
                        {localMarketplaceItems.map((marketplaceItem) => (
                            <FormControl
                                key={marketplaceItem.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <HoverImageWithText
                                        src={marketplaceItem.imageUrl}
                                        alt=""
                                        title={marketplaceItem.title}
                                        description={marketplaceItem.description}
                                    />
                                    <Typography
                                        sx={{
                                            width: 'calc(80% - 24px)',
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {marketplaceItem.title || 'Marketplace Item'}
                                        <span style={{ fontWeight: '500' }}>
                                            {' $'}
                                            {(marketplaceItem.price / 100).toFixed(2)}
                                        </span>
                                    </Typography>
                                    <CartInput
                                        initialValue={0}
                                        name={marketplaceItem.title}
                                        maxValue={null}
                                        cartUuid={cart?.uuid}
                                        stripePriceId={marketplaceItem.stripe_price_id}
                                        type="marketplace_item"
                                        category="marketplace_item"
                                        sellableId={marketplaceItem.uuid}
                                        disabled={
                                            !cart?.uuid ||
                                            cart?.items.some(
                                                (item) => item.sellable_id === marketplaceItem.uuid,
                                            )
                                        }
                                    />
                                </Stack>
                            </FormControl>
                        ))}
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};
