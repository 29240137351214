import { Organization } from '../../../utils';
import { Commission, CommissionFilters, Payout, Rank, TreeNodes } from '../networking.types';
import { SimplePaginateResponse } from '../../../app.types';

export interface NetworkingState {
    organization: {
        loading: boolean;
        data?: Organization; // Define a more specific type for your organization data
        error?: string;
    };
    binaryTree: {
        data: TreeNodes | undefined;
        loaded: boolean;
        loading: boolean;
        error: string | undefined;
    };
    enrollerTree: {
        data: TreeNodes | undefined;
        loaded: boolean;
        loading: boolean;
        error: string | undefined;
    };
    ranks: {
        data: Rank[];
        loaded: boolean;
        loading: boolean;
        error: string;
    };
    commissions: {
        data: Commission[];
        pagination: {
            has_next: boolean;
            has_prev: boolean;
            current_page: number;
        };
        loaded: boolean;
        loading: boolean;
        filters: CommissionFilters;
        errorMessage: string;
    };
    payouts: {
        data: Payout[];
        pagination: {
            has_next: boolean;
            has_prev: boolean;
            current_page: number;
        };
        loaded: boolean;
        loading: boolean;
        filters: any;
        errorMessage: string;
    };
    downlinePartnerPayouts: {
        data: Payout[];
        pagination: {
            has_next: boolean;
            has_prev: boolean;
            current_page: number;
        };
        loaded: boolean;
        loading: boolean;
        filters: any;
        errorMessage: string;
    };
    payout: {
        payout: Payout | null;
        loaded: boolean;
        loading: boolean;
        errorMessage: string;
    };
    commissionsPerPayout: {
        data: {
            [key: string]: {
                commissions: Commission[];
                loading: boolean;
                loaded: boolean;
            };
        };
        loading: boolean;
        errorMessage: string;
    };
    selectedPayoutId: string;
}

export enum NetworkingActionTypes {
    LOAD_ORGANIZATION_REQUEST = 'LOAD_ORGANIZATION_REQUEST',
    LOAD_ORGANIZATION_SUCCESS = 'LOAD_ORGANIZATION_SUCCESS',
    LOAD_ORGANIZATION_ERROR = 'LOAD_ORGANIZATION_ERROR',
    LOAD_BINARY_TREE_REQUEST = 'LOAD_BINARY_TREE_REQUEST',
    LOAD_BINARY_TREE_SUCCESS = 'LOAD_BINARY_TREE_SUCCESS',
    LOAD_BINARY_TREE_ERROR = 'LOAD_BINARY_TREE_ERROR',
    LOAD_RANKS_REQUEST = 'LOAD_RANKS_REQUEST',
    LOAD_RANKS_SUCCESS = 'LOAD_RANKS_SUCCESS',
    LOAD_RANKS_ERROR = 'LOAD_RANKS_ERROR',
    LOAD_COMMISSIONS_REQUEST = 'LOAD_COMMISSIONS_REQUEST',
    LOAD_COMMISSIONS_SUCCESS = 'LOAD_COMMISSIONS_SUCCESS',
    LOAD_COMMISSIONS_ERROR = 'LOAD_COMMISSIONS_ERROR',
    COMMISSIONS_FILTERS_CHANGED = 'COMMISSIONS_FILTERS_CHANGED',
    COMMISSIONS_ERROR_MESSAGE_CLEARED = 'COMMISSIONS_ERROR_MESSAGE_CLEARED',
    LOAD_ENROLLER_TREE_REQUEST = 'LOAD_ENROLLER_TREE_REQUEST',
    LOAD_ENROLLER_TREE_SUCCESS = 'LOAD_ENROLLER_TREE_SUCCESS',
    LOAD_ENROLLER_TREE_ERROR = 'LOAD_ENROLLER_TREE_ERROR',
    LOAD_PAYOUTS_REQUEST = 'LOAD_PAYOUTS_REQUEST',
    LOAD_PAYOUTS_SUCCESS = 'LOAD_PAYOUTS_SUCCESS',
    LOAD_PAYOUTS_ERROR = 'LOAD_PAYOUTS_ERROR',
    LOAD_DOWNLINE_PARTNER_PAYOUTS_REQUEST = 'LOAD_DOWNLINE_PARTNER_PAYOUTS_REQUEST',
    LOAD_DOWNLINE_PARTNER_PAYOUTS_SUCCESS = 'LOAD_DOWNLINE_PARTNER_PAYOUTS_SUCCESS',
    LOAD_DOWNLINE_PARTNER_PAYOUTS_ERROR = 'LOAD_DOWNLINE_PARTNER_PAYOUTS_ERROR',
    LOAD_PAYOUT_REQUEST = 'LOAD_PAYOUT_REQUEST',
    LOAD_PAYOUT_SUCCESS = 'LOAD_PAYOUT_SUCCESS',
    LOAD_PAYOUT_ERROR = 'LOAD_PAYOUT_ERROR',
    PAYOUTS_FILTERS_CHANGED = 'PAYOUTS_FILTERS_CHANGED',
    DOWNLINE_PARTNER_PAYOUTS_FILTERS_CHANGED = 'DOWNLINE_PARTNER_PAYOUTS_FILTERS_CHANGED',
    PAYOUTS_ERROR_MESSAGE_CLEARED = 'PAYOUTS_ERROR_MESSAGE_CLEARED',
    DOWNLINE_PARTNER_PAYOUTS_ERROR_MESSAGE_CLEARED = 'DOWNLINE_PARTNER_PAYOUTS_ERROR_MESSAGE_CLEARED',
    LOAD_COMMISSIONS_FOR_PAYOUT_REQUEST = 'LOAD_COMMISSIONS_FOR_PAYOUT_REQUEST',
    LOAD_COMMISSIONS_FOR_PAYOUT_SUCCESS = 'LOAD_COMMISSIONS_FOR_PAYOUT_SUCCESS',
    LOAD_COMMISSIONS_FOR_PAYOUT_ERROR = 'LOAD_COMMISSIONS_FOR_PAYOUT_ERROR',
    PAYOUT_SELECTED = 'PAYOUT_SELECTED',
    UPDATE_COMMISSIONS_FILTERS = 'UPDATE_COMMISSIONS_FILTERS',
}

type NetworkingAction =
    | { type: NetworkingActionTypes.LOAD_ORGANIZATION_REQUEST }
    | { type: NetworkingActionTypes.LOAD_ORGANIZATION_SUCCESS; payload: Organization }
    | { type: NetworkingActionTypes.LOAD_ORGANIZATION_ERROR; payload: string }
    | { type: NetworkingActionTypes.LOAD_BINARY_TREE_REQUEST }
    | { type: NetworkingActionTypes.LOAD_BINARY_TREE_SUCCESS; payload: TreeNodes }
    | { type: NetworkingActionTypes.LOAD_BINARY_TREE_ERROR; payload: string }
    | { type: NetworkingActionTypes.LOAD_ENROLLER_TREE_REQUEST }
    | { type: NetworkingActionTypes.LOAD_ENROLLER_TREE_SUCCESS; payload: TreeNodes }
    | { type: NetworkingActionTypes.LOAD_ENROLLER_TREE_ERROR; payload: string }
    | { type: NetworkingActionTypes.LOAD_RANKS_REQUEST }
    | { type: NetworkingActionTypes.LOAD_RANKS_SUCCESS; payload: Rank[] }
    | { type: NetworkingActionTypes.LOAD_RANKS_ERROR; payload: string }
    | { type: NetworkingActionTypes.LOAD_PAYOUTS_REQUEST }
    | {
          type: NetworkingActionTypes.LOAD_PAYOUTS_SUCCESS;
          payload: SimplePaginateResponse<Payout>;
      }
    | { type: NetworkingActionTypes.LOAD_PAYOUTS_ERROR; payload: string }
    | { type: NetworkingActionTypes.LOAD_DOWNLINE_PARTNER_PAYOUTS_REQUEST }
    | {
          type: NetworkingActionTypes.LOAD_DOWNLINE_PARTNER_PAYOUTS_SUCCESS;
          payload: SimplePaginateResponse<Payout>;
      }
    | { type: NetworkingActionTypes.LOAD_DOWNLINE_PARTNER_PAYOUTS_ERROR; payload: string }
    | { type: NetworkingActionTypes.PAYOUTS_FILTERS_CHANGED; payload: any }
    | { type: NetworkingActionTypes.DOWNLINE_PARTNER_PAYOUTS_FILTERS_CHANGED; payload: any }
    | { type: NetworkingActionTypes.PAYOUTS_ERROR_MESSAGE_CLEARED }
    | { type: NetworkingActionTypes.DOWNLINE_PARTNER_PAYOUTS_ERROR_MESSAGE_CLEARED }
    | { type: NetworkingActionTypes.LOAD_COMMISSIONS_REQUEST }
    | {
          type: NetworkingActionTypes.LOAD_COMMISSIONS_SUCCESS;
          payload: SimplePaginateResponse<Commission>;
      }
    | { type: NetworkingActionTypes.LOAD_COMMISSIONS_ERROR; payload: string }
    | {
          type: NetworkingActionTypes.COMMISSIONS_FILTERS_CHANGED;
          payload: Partial<CommissionFilters>;
      }
    | { type: NetworkingActionTypes.COMMISSIONS_ERROR_MESSAGE_CLEARED }
    | { type: NetworkingActionTypes.LOAD_COMMISSIONS_FOR_PAYOUT_REQUEST; payoutId: string }
    | {
          type: NetworkingActionTypes.LOAD_COMMISSIONS_FOR_PAYOUT_SUCCESS;
          payload: { commissions: Commission[]; payoutId: string };
      }
    | { type: NetworkingActionTypes.LOAD_COMMISSIONS_FOR_PAYOUT_ERROR; payoutId: string }
    | { type: NetworkingActionTypes.PAYOUT_SELECTED; payoutId: string }
    | { type: NetworkingActionTypes.LOAD_PAYOUT_REQUEST; payoutId: string }
    | { type: NetworkingActionTypes.LOAD_PAYOUT_SUCCESS; payload: Payout }
    | { type: NetworkingActionTypes.LOAD_PAYOUT_ERROR; payload: string }
    | {
          type: NetworkingActionTypes.UPDATE_COMMISSIONS_FILTERS;
          payload: Partial<CommissionFilters>;
      };

export const networkingReducer = (
    state: NetworkingState,
    action: NetworkingAction,
): NetworkingState => {
    switch (action.type) {
        case NetworkingActionTypes.PAYOUT_SELECTED:
            return {
                ...state,
                selectedPayoutId: action.payoutId,
            };
        case NetworkingActionTypes.LOAD_ORGANIZATION_REQUEST:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    loading: true,
                    error: undefined,
                },
            };
        case NetworkingActionTypes.LOAD_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    loading: false,
                    data: action.payload,
                },
            };
        case NetworkingActionTypes.LOAD_ORGANIZATION_ERROR:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    loading: false,
                    error: action.payload,
                },
            };
        case NetworkingActionTypes.LOAD_BINARY_TREE_REQUEST:
            return {
                ...state,
                binaryTree: {
                    ...state.binaryTree,
                    loading: true,
                    error: undefined,
                },
            };
        case NetworkingActionTypes.LOAD_BINARY_TREE_SUCCESS:
            return {
                ...state,
                binaryTree: {
                    ...state.binaryTree,
                    loading: false,
                    loaded: true,
                    data: action.payload,
                },
            };
        case NetworkingActionTypes.LOAD_BINARY_TREE_ERROR:
            return {
                ...state,
                binaryTree: {
                    ...state.binaryTree,
                    data: undefined,
                    loading: false,
                    error: action.payload,
                },
            };
        case NetworkingActionTypes.LOAD_ENROLLER_TREE_REQUEST:
            return {
                ...state,
                enrollerTree: {
                    ...state.enrollerTree,
                    loading: true,
                    error: undefined,
                },
            };
        case NetworkingActionTypes.LOAD_ENROLLER_TREE_SUCCESS:
            return {
                ...state,
                enrollerTree: {
                    ...state.enrollerTree,
                    loading: false,
                    loaded: true,
                    data: action.payload,
                },
            };
        case NetworkingActionTypes.LOAD_ENROLLER_TREE_ERROR:
            return {
                ...state,
                enrollerTree: {
                    ...state.enrollerTree,
                    data: undefined,
                    loading: false,
                    error: action.payload,
                },
            };
        case NetworkingActionTypes.LOAD_RANKS_REQUEST:
            return {
                ...state,
                ranks: {
                    ...state.ranks,
                    loading: true,
                },
            };
        case NetworkingActionTypes.LOAD_RANKS_SUCCESS:
            return {
                ...state,
                ranks: {
                    ...state.ranks,
                    loading: false,
                    loaded: true,
                    data: action.payload,
                },
            };
        case NetworkingActionTypes.LOAD_RANKS_ERROR:
            return {
                ...state,
                ranks: {
                    ...state.ranks,
                    loading: false,
                    error: action.payload,
                },
            };
        case NetworkingActionTypes.LOAD_COMMISSIONS_REQUEST:
            return {
                ...state,
                commissions: {
                    ...state.commissions,
                    loading: true,
                },
            };
        case NetworkingActionTypes.LOAD_COMMISSIONS_SUCCESS:
            return {
                ...state,
                commissions: {
                    ...state.commissions,
                    loading: false,
                    loaded: true,
                    data: action.payload.data,
                    pagination: {
                        has_next: action.payload.links.next !== null,
                        has_prev: action.payload.links.prev !== null,
                        current_page: action.payload.meta.current_page,
                    },
                },
            };
        case NetworkingActionTypes.LOAD_COMMISSIONS_ERROR:
            return {
                ...state,
                commissions: {
                    ...state.commissions,
                    loading: false,
                    errorMessage: action.payload,
                },
            };
        case NetworkingActionTypes.COMMISSIONS_FILTERS_CHANGED:
            return {
                ...state,
                commissions: {
                    ...state.commissions,
                    filters: {
                        ...state.commissions.filters,
                        ...action.payload,
                    },
                },
            };
        case NetworkingActionTypes.COMMISSIONS_ERROR_MESSAGE_CLEARED:
            return {
                ...state,
                commissions: {
                    ...state.commissions,
                    errorMessage: '',
                },
            };
        case NetworkingActionTypes.LOAD_PAYOUTS_REQUEST:
            return {
                ...state,
                payouts: {
                    ...state.payouts,
                    loading: true,
                },
            };
        case NetworkingActionTypes.PAYOUTS_FILTERS_CHANGED:
            return {
                ...state,
                payouts: {
                    ...state.payouts,
                    filters: {
                        ...state.payouts.filters,
                        ...action.payload,
                    },
                },
            };
        case NetworkingActionTypes.LOAD_PAYOUTS_SUCCESS:
            return {
                ...state,
                payouts: {
                    ...state.payouts,
                    loading: false,
                    loaded: true,
                    data: action.payload.data,
                    pagination: {
                        has_next: action.payload.links.next !== null,
                        has_prev: action.payload.links.prev !== null,
                        current_page: action.payload.meta.current_page,
                    },
                },
            };
        case NetworkingActionTypes.LOAD_PAYOUTS_ERROR:
            return {
                ...state,
                payouts: {
                    ...state.payouts,
                    loading: false,
                    errorMessage: action.payload,
                },
            };
        case NetworkingActionTypes.LOAD_DOWNLINE_PARTNER_PAYOUTS_REQUEST:
            return {
                ...state,
                downlinePartnerPayouts: {
                    ...state.downlinePartnerPayouts,
                    loading: true,
                },
            };
        case NetworkingActionTypes.DOWNLINE_PARTNER_PAYOUTS_FILTERS_CHANGED:
            return {
                ...state,
                downlinePartnerPayouts: {
                    ...state.downlinePartnerPayouts,
                    filters: {
                        ...state.downlinePartnerPayouts.filters,
                        ...action.payload,
                    },
                },
            };
        case NetworkingActionTypes.LOAD_DOWNLINE_PARTNER_PAYOUTS_SUCCESS:
            return {
                ...state,
                downlinePartnerPayouts: {
                    ...state.downlinePartnerPayouts,
                    loading: false,
                    loaded: true,
                    data: action.payload.data,
                    pagination: {
                        has_next: action.payload.links.next !== null,
                        has_prev: action.payload.links.prev !== null,
                        current_page: action.payload.meta.current_page,
                    },
                },
            };
        case NetworkingActionTypes.LOAD_DOWNLINE_PARTNER_PAYOUTS_ERROR:
            return {
                ...state,
                downlinePartnerPayouts: {
                    ...state.downlinePartnerPayouts,
                    loading: false,
                    errorMessage: action.payload,
                },
            };
        case NetworkingActionTypes.LOAD_PAYOUT_REQUEST:
            return {
                ...state,
                payout: {
                    ...state.payout,
                    loading: true,
                },
            };
        case NetworkingActionTypes.LOAD_PAYOUT_SUCCESS:
            return {
                ...state,
                payout: {
                    ...state.payout,
                    loading: false,
                    loaded: true,
                    payout: action.payload,
                },
            };
        case NetworkingActionTypes.LOAD_PAYOUT_ERROR:
            return {
                ...state,
                payout: {
                    ...state.payout,
                    loading: false,
                    errorMessage: action.payload,
                },
            };
        case NetworkingActionTypes.LOAD_COMMISSIONS_FOR_PAYOUT_REQUEST:
            return {
                ...state,
                commissionsPerPayout: {
                    ...state.commissionsPerPayout,
                    data: {
                        ...state.commissionsPerPayout.data,
                        [action.payoutId]: {
                            ...state.commissionsPerPayout.data[action.payoutId],
                            loading: true,
                        },
                    },
                    loading: true,
                },
            };
        case NetworkingActionTypes.LOAD_COMMISSIONS_FOR_PAYOUT_SUCCESS:
            return {
                ...state,
                commissionsPerPayout: {
                    ...state.commissionsPerPayout,
                    data: {
                        ...state.commissionsPerPayout.data,
                        [action.payload.payoutId]: {
                            commissions: action.payload.commissions,
                            loading: false,
                            loaded: true,
                        },
                    },
                    loading: false,
                },
            };
        case NetworkingActionTypes.LOAD_COMMISSIONS_FOR_PAYOUT_ERROR:
            return {
                ...state,
                commissionsPerPayout: {
                    ...state.commissionsPerPayout,
                    errorMessage: action.payoutId,
                    loading: false,
                },
            };
        case NetworkingActionTypes.UPDATE_COMMISSIONS_FILTERS:
            return {
                ...state,
                commissions: {
                    ...state.commissions,
                    filters: {
                        ...state.commissions.filters,
                        ...action.payload,
                    },
                },
            };
        default:
            return state;
    }
};
