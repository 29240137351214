import { TooltipProps } from 'recharts';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { formatTimestampSeconds } from '../../modules/community/community.func';
import React from 'react';

type DistanceUnit = 'yd' | 'ft' | 'm';

const CustomTooltipRawGpsData = (
    props: TooltipProps<any, any> & {
        formatTimestampFunction?: (timestamp: string) => string;
        showAllSeries?: boolean;
        hideDurationPrefix?: boolean;
        distanceUnit?: DistanceUnit;
    },
) => {
    const showAllSeries = props.showAllSeries ? props.showAllSeries : false;
    const hideDurationPrefix = props.hideDurationPrefix ? props.hideDurationPrefix : false;
    const distanceUnit = props.distanceUnit ? props.distanceUnit : 'yd';

    const formatTimestampFunction = props.formatTimestampFunction
        ? props.formatTimestampFunction
        : formatTimestampSeconds;

    const convertDistance = (distanceInYards: number): number => {
        switch (distanceUnit) {
            case 'ft':
                return distanceInYards * 3; // 1 yard = 3 feet
            case 'm':
                return distanceInYards * 0.9144; // 1 yard = 0.9144 meters
            case 'yd':
            default:
                return distanceInYards;
        }
    };

    const formatDistance = (distanceInYards: number): string => {
        const convertedValue = convertDistance(distanceInYards);

        // Use appropriate decimal precision based on unit
        switch (distanceUnit) {
            case 'ft':
                return convertedValue.toFixed(1);
            case 'm':
                return convertedValue.toFixed(2);
            case 'yd':
            default:
                return convertedValue.toFixed(2);
        }
    };

    if (props.active && props.payload && props.payload.length) {
        // order the props.payload by the Cumulative Distance (yd) field
        const rows = props.payload.sort((a, b) => {
            return b.payload['Cumulative Distance (yd)'] - a.payload['Cumulative Distance (yd)'];
        });

        return (
            <Paper sx={{ opacity: 0.7 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 4,
                    }}
                >
                    {!hideDurationPrefix && (
                        <Typography variant={'h6'}>
                            {rows[0].payload['Duration (s)']?.toFixed(1)}s
                        </Typography>
                    )}
                    {rows.length === 1 && (
                        <Typography variant={'h6'}>
                            {formatTimestampFunction(rows[0].payload['Timestamp'])}
                        </Typography>
                    )}
                </Box>
                <TableContainer>
                    <Table size={'small'}>
                        <TableHead>
                            <TableRow>
                                {showAllSeries && <TableCell>Name</TableCell>}
                                <TableCell>V (mph)</TableCell>
                                <TableCell>D ({distanceUnit})</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {!showAllSeries && (
                                    <TableRow>
                                        <TableCell>
                                            {rows[0].payload['Velocity (mph)']?.toFixed(2)}
                                        </TableCell>
                                        <TableCell>
                                            {formatDistance(
                                                rows[0].payload['Cumulative Distance (yd)'],
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {showAllSeries &&
                                    rows.map((row) => (
                                        <TableRow key={Math.random().toString()}>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <span>{row.name ? row.name : 'N/A'}</span>
                                                    <Box
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                            backgroundColor: row.color,
                                                            marginLeft: 10,
                                                        }}
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                {row.payload['Velocity (mph)']?.toFixed(2)}
                                            </TableCell>
                                            <TableCell>
                                                {formatDistance(
                                                    row.payload['Cumulative Distance (yd)'],
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {row.payload['Heart Rate (bpm)']?.toFixed(0)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }

    return null;
};

export default CustomTooltipRawGpsData;
