import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography,
} from '@mui/material';
import { NextPrevPagination } from 'components';
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Group, Member, Meta, PaginationLink } from 'utils';
import { getMembers, viewGroup } from '../api/groups.api';
import MembersAutocomplete from '../components/MembersAutocomplete';
import SpeedManagementIndividualCard from '../components/SpeedManagementIndividualCard';
import { getExportUrl } from '../api/performance.api';
import { DateTime } from 'luxon';

// Define sorting options
const sortOptions = [
    { value: '-most_recent_sprint', label: 'Most Recent Sprint (newest first)' },
    { value: 'most_recent_sprint', label: 'Most Recent Sprint (oldest first)' },
    { value: '-max_velocity', label: 'Max Velocity (highest first)' },
    { value: 'max_velocity', label: 'Max Velocity (lowest first)' },
    { value: 'first_name', label: 'First Name (A-Z)' },
    { value: '-first_name', label: 'First Name (Z-A)' },
];

const SpeedManagement: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [group, setGroup] = useState<Group>();
    const [members, setMembers] = useState<Member[]>();
    const [membersPaginationLinks, setMembersPaginationLinks] = useState<PaginationLink>();
    const [membersPaginationMeta, setMembersPaginationMeta] = useState<Meta>();
    const [error, setError] = useState<string>('');
    const { id } = useParams<{ id: string }>();
    const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
    const [sortBy, setSortBy] = useState<string>('-most_recent_sprint');

    const loadMembers = useCallback(
        (uuid: string, page?: number) => {
            setLoading(true);
            const memberUuids = selectedMembers.map((member) => member.uuid);
            getMembers(uuid, {
                include: 'individual.managingUser,memberType',
                limit: 8,
                page,
                'filter[uuid]': memberUuids.length > 0 ? memberUuids.join(',') : undefined,
                sort: sortBy,
            })
                .then((response) => {
                    setMembers(response.data.data);
                    setMembersPaginationLinks(response.data.links);
                    setMembersPaginationMeta(response.data.meta);
                })
                .catch(() => setError('Failed to load members'))
                .finally(() => setLoading(false));
        },
        [selectedMembers, sortBy],
    );

    useEffect(() => {
        let isMounted = true;
        viewGroup(id)
            .then((groupResponse) => {
                if (isMounted) {
                    setGroup(groupResponse.data);
                }
            })
            .catch(() => {
                if (isMounted) {
                    setError('Failed to load group');
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [id]);

    useEffect(() => {
        if (group) {
            loadMembers(group.uuid, 1);
        }
    }, [group, loadMembers]);

    const onNextClicked = () => {
        if (!group) return;
        const nextPage = membersPaginationMeta ? membersPaginationMeta.current_page + 1 : 1;
        loadMembers(group.uuid, nextPage);
    };

    const onPrevClicked = () => {
        if (!group) return;
        const prevPage = membersPaginationMeta ? membersPaginationMeta.current_page - 1 : 1;
        loadMembers(group.uuid, prevPage);
    };

    const handleSortChange = (event: SelectChangeEvent) => {
        setSortBy(event.target.value);
    };

    if (error) {
        return (
            <Container>
                <Typography color="error">{error}</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 4 }}>
            <Box sx={{ position: 'relative', width: '100%' }}>
                <Typography variant="h3" textAlign={'center'}>
                    Speed Management
                </Typography>
                <Button
                    variant="contained"
                    sx={{ position: 'absolute', right: 0, top: 0 }}
                    onClick={async () => {
                        try {
                            const { data } = await getExportUrl({
                                'filter[group_uuid]': group?.uuid,
                            });
                            const response = await fetch(data.url);
                            const blob = await response.blob();
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = `${group?.name}-${DateTime.now().toFormat(
                                'yyyy-MM-dd-HH-mm-ss',
                            )}-speed-management-export.csv`;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            document.body.removeChild(a);
                        } catch (err) {
                            console.error('Failed to export:', err);
                        }
                    }}
                >
                    Export
                </Button>
            </Box>
            {group && (
                <Stack direction="row" spacing={2} alignItems="center" justifyContent={'center'}>
                    <img
                        src={group.image_urls?.avatar || '/default-avatar.png'}
                        alt={`${group.name} avatar`}
                        style={{ maxWidth: '40px', maxHeight: '40px', objectFit: 'cover' }}
                    />
                    <Typography variant="h2" sx={{ mb: 4 }}>
                        {group.name}
                    </Typography>
                </Stack>
            )}

            {group && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mb: 4,
                        gap: 2,
                        flexWrap: 'wrap',
                        marginTop: 6,
                        marginBottom: 6,
                    }}
                >
                    <MembersAutocomplete
                        group={group?.uuid || ''}
                        onChange={(v) => {
                            setSelectedMembers(v as Member[]);
                        }}
                        value={selectedMembers}
                        multiple={true}
                        size="small"
                    />

                    {/* Inline Sort Selector */}
                    <FormControl sx={{ minWidth: 200, marginTop: '2px' }}>
                        <InputLabel id="sort-selector-label">Sort By</InputLabel>
                        <Select
                            labelId="sort-selector-label"
                            value={sortBy}
                            label="Sort By"
                            onChange={handleSortChange}
                            size="small"
                        >
                            {sortOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            )}

            {loading ? (
                <Box display="flex" justifyContent="center" my={4}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid container spacing={3}>
                        {members?.map((member) => (
                            <Grid item xs={12} md={4} xl={3} key={member.individual.uuid}>
                                <SpeedManagementIndividualCard individual={member.individual} />
                            </Grid>
                        ))}
                    </Grid>

                    {members && members.length > 0 && (
                        <Box sx={{ mt: 4 }}>
                            <NextPrevPagination
                                onNext={onNextClicked}
                                onPrev={onPrevClicked}
                                disablePrev={Boolean(!membersPaginationLinks?.prev)}
                                disableNext={Boolean(!membersPaginationLinks?.next)}
                            />
                        </Box>
                    )}
                </>
            )}
        </Container>
    );
};

export default SpeedManagement;
