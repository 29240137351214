import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

interface HoverImageProps {
    src: string;
    alt: string;
    title: string;
    description: string;
    size?: number;
}

const HoverImageWithText: React.FC<HoverImageProps> = ({
    src,
    alt,
    title,
    description,
    size = 24,
}) => {
    const [isHovering, setIsHovering] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    // Update popup position when hovering changes
    useEffect(() => {
        if (isHovering) {
            // Center the popup in the viewport regardless of trigger position
            setPosition({
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
            });
        }
    }, [isHovering]);

    return (
        <Box sx={{ position: 'relative' }}>
            <Box
                component="img"
                src={src}
                alt={alt}
                sx={{
                    height: size,
                    width: size,
                    cursor: 'pointer',
                    border: '1px solid #e0e0e0',
                    fontSize: '0.8rem',
                    borderRadius: '2px',
                }}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            />

            {isHovering && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        pointerEvents: 'none',
                        zIndex: 1500,
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: `${position.y}px`,
                            left: `${position.x}px`,
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            color: 'text.primary',
                            border: '1px solid #dadde9',
                            borderRadius: 1,
                            p: 1,
                            boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
                            maxWidth: '50%',
                            maxHeight: '50%',
                            zIndex: 1500,
                            padding: '1rem',
                        }}
                    >
                        <Box
                            sx={{
                                mb: 1,
                                textAlign: 'center',
                                typography: 'subtitle2',
                                fontWeight: 'bold',
                            }}
                        >
                            {title}
                        </Box>
                        <Box
                            component="img"
                            src={src}
                            alt={alt}
                            sx={{
                                height: 100,
                                width: '100%',
                                objectFit: 'contain',
                                mb: 1,
                            }}
                        />
                        <Box sx={{ typography: 'body2', fontSize: '0.8rem' }}>{description}</Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default HoverImageWithText;
