import { ArrowLeft, ArrowRight, OpenInNew, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Form, Formik } from 'formik';
import Lottie from 'lottie-react'; // Correct import statement
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import {
    Area,
    AreaChart,
    Bar,
    Brush,
    CartesianGrid,
    ComposedChart,
    ReferenceArea,
    ReferenceLine,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { getAllRawDataForPoints } from '../../../app.functions';
import { Can, TypographyTitle } from '../../../components';
import CustomTooltipRawGpsData from '../../../components/Charting/CustomTooltipRawGpsData';
import saveButton from '../../../lottie-animations/Save.json';
import {
    createCrop,
    deleteCrop,
    getAllCrops,
    getRawUploadDataFromUrl,
    updateCrop,
} from '../api/performance.api';
import { formatNumber, formatTimestampSeconds, formatTimestampTenths } from '../community.func';
import { Crop, PerformanceDataUpload, RawDataPointEnhanced } from '../community.types';
import CropDataTable from './CropDataTable';

interface RawDataViewerProps {
    upload: PerformanceDataUpload;
    distanceUnit: 'yd' | 'ft' | 'm';
}

const sortCrops = (crops: Crop[]): Crop[] => {
    return crops.sort((a, b) => {
        return a.start_timestamp - b.start_timestamp;
    });
};

const StrikedEditIcon = styled(EditIcon)(({ theme }) => ({
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        height: '2px',
        backgroundColor: theme.palette.text.primary,
    },
}));

const RawDataViewer = ({ upload, distanceUnit }: RawDataViewerProps) => {
    const [rawData, setRawData] = useState<RawDataPointEnhanced[]>([]);
    const [selectedCrops, setSelectedCrops] = useState<{
        [key: string]: {
            crop: Crop;
            data: RawDataPointEnhanced[];
            startIndex: number;
            endIndex: number;
            isEditing: boolean;
        };
    }>({});
    const [sampledData, setSampledData] = useState<RawDataPointEnhanced[]>([]);
    const [sampledDataIndexed, setSampledDataIndexed] = useState<RawDataPointEnhanced[]>([]);
    const [saveCropOpen, setSaveCropOpen] = useState(false);
    const [crops, setCrops] = useState<Crop[]>([]);
    const [cropLabel, setCropLabel] = useState<string>('');
    const [startIndex, setStartIndex] = useState<number>(0);
    const [endIndex, setEndIndex] = useState<number>(0);
    const [chartKey, setChartKey] = useState<string>('initial-key');
    const [rawDataLoading, setRawDataLoading] = useState<boolean>(false);
    const [refAreaLeft, setRefAreaLeft] = useState<number>();
    const [refAreaRight, setRefAreaRight] = useState<number>();
    const [message, setMessage] = useState<string>('');

    const handleDialogSubmit = useCallback(() => {
        createCrop(upload.individual.uuid, upload.uuid, {
            start_timestamp: sampledDataIndexed[startIndex]['Timestamp'],
            end_timestamp: sampledDataIndexed[endIndex]['Timestamp'],
            label: cropLabel,
        })
            .then((response) => {
                setCrops((crops) => sortCrops([...crops, response.data]));
                setMessage('Crop Saved Successfully');
            })
            .catch(() => {
                setMessage('Error Saving Crop');
            })
            .finally(() => {
                setCropLabel('');
                setSaveCropOpen(false);
            });
    }, [upload, sampledDataIndexed, startIndex, endIndex, cropLabel]);

    const hasAtLeastOneHeartRateValue = useCallback(() => {
        return (
            rawData.findIndex(
                (v) => v['Heart Rate (bpm)'] !== 0 && v['Heart Rate (bpm)'] !== null,
            ) > -1
        );
    }, [rawData]);

    const getDataForCrop = useCallback(
        (crop: Crop) => {
            const cropData = rawData.slice(
                rawData.findIndex((p) => p['Timestamp'] === crop.start_timestamp),
                rawData.findIndex((p) => p['Timestamp'] === crop.end_timestamp) + 1,
            );

            return getAllRawDataForPoints(cropData);
        },
        [rawData],
    );

    const saveCrop = useCallback(
        (k: string) => {
            const crop = selectedCrops[k];
            updateCrop(upload.individual.uuid, upload.uuid, crop.crop.uuid, {
                label: crop.crop.label,
                start_timestamp: crop.data[crop.startIndex]['Timestamp'],
                end_timestamp: crop.data[crop.endIndex]['Timestamp'],
            })
                .then((response) => {
                    setCrops((crops) => {
                        const newCrops = [...crops];
                        const cropIndex = newCrops.findIndex((c) => c.uuid === response.data.uuid);
                        newCrops[cropIndex] = response.data;
                        return sortCrops(newCrops);
                    });
                    setSelectedCrops((crops) => {
                        const newCrops = { ...crops };
                        const data = getDataForCrop(response.data);
                        newCrops[k].crop = response.data;
                        newCrops[k].data = data;
                        newCrops[k].startIndex = 0;
                        newCrops[k].endIndex = data.length - 1;
                        return newCrops;
                    });
                    setMessage('Crop Saved Successfully');
                })
                .catch(() => {
                    setMessage('Error Saving Crop');
                })
                .finally(() => {});
        },
        [selectedCrops, upload, getDataForCrop],
    );
    const brushChangeHandler = (newIndex: any): void => {
        setStartIndex(newIndex.startIndex);
        setEndIndex(newIndex.endIndex);
    };

    useEffect(() => {
        setSampledDataIndexed(sampledData.filter(Boolean));
        setEndIndex(sampledData.length - 1);
    }, [sampledData]);

    useEffect(() => {
        if (upload && upload.raw_data_url) {
            getAllCrops(upload.individual.uuid, upload.uuid).then((cropsResponse) => {
                setCrops(sortCrops(cropsResponse.data));
            });
            setRawDataLoading(true);
            getRawUploadDataFromUrl(upload.raw_data_url)
                .then((response) => {
                    let sampledData: RawDataPointEnhanced[] = [];
                    let enhancedData = getAllRawDataForPoints(response.data);
                    setRawData(enhancedData);
                    for (let i = 0; i < enhancedData.length; i++) {
                        if (
                            i % 10 == 0 ||
                            +enhancedData[i]['Velocity (m/s)'].toFixed(3) ===
                                upload.summary_max_velocity
                        ) {
                            sampledData.push({
                                ...enhancedData[i],
                            });
                        }
                    }
                    setSampledData(sampledData);
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    setRawDataLoading(false);
                });
        }
    }, [upload]);

    const zoom = useCallback(() => {
        const startIx = sampledDataIndexed.findIndex((v) => v['Timestamp'] === refAreaLeft);
        const endIx = sampledDataIndexed.findIndex((v) => v['Timestamp'] === refAreaRight);

        if (startIx > -1 && endIx > -1) {
            setStartIndex(startIx);
            setEndIndex(endIx);
            setRefAreaLeft(0);
            setRefAreaRight(0);
            setChartKey(Math.random().toString());
        }
    }, [refAreaLeft, refAreaRight, sampledDataIndexed]);

    return (
        <>
            {sampledData && (
                <Box sx={{ p: 4 }}>
                    <Typography
                        sx={{ color: grey[700], my: 2 }}
                        variant={'h4'}
                        textAlign={'center'}
                    >
                        Complete Session Data
                    </Typography>
                    <Paper sx={{ p: 8 }}>
                        <Box sx={{ height: 500 }}>
                            {rawDataLoading && (
                                <Typography variant={'h6'} textAlign={'center'}>
                                    Loading Complete Session Data...
                                </Typography>
                            )}
                            {!rawDataLoading && (
                                <ResponsiveContainer width="100%" height="100%">
                                    <AreaChart
                                        key={chartKey}
                                        height={500}
                                        data={sampledDataIndexed}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                        onMouseDown={(e) => {
                                            if (e) {
                                                setRefAreaLeft(+(e.activeLabel ?? ''));
                                            }
                                        }}
                                        onMouseMove={(e) => {
                                            if (e && refAreaLeft) {
                                                setRefAreaRight(+(e.activeLabel ?? ''));
                                            }
                                        }}
                                        onMouseUp={(e) => {
                                            if (e) {
                                                zoom();
                                                setChartKey(Math.random().toString());
                                            }
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            domain={['dataMin', 'dataMax']}
                                            type="number"
                                            dataKey="Timestamp"
                                            angle={-60}
                                            tickFormatter={formatTimestampSeconds}
                                            textAnchor="end"
                                            height={100}
                                            style={{
                                                fontSize: '0.75rem',
                                            }}
                                            onClick={(e) => {
                                                console.log(e);
                                            }}
                                        />
                                        <YAxis
                                            name={'Velocity (mph)'}
                                            domain={[0, 'dataMax']}
                                            tickFormatter={formatNumber}
                                        />
                                        <Tooltip
                                            content={(props) => (
                                                <CustomTooltipRawGpsData
                                                    {...props}
                                                    hideDurationPrefix={true}
                                                    distanceUnit={distanceUnit}
                                                />
                                            )}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="Velocity (mph)"
                                            stroke="#8884d8"
                                            fill="#8884d8"
                                        />
                                        <Brush
                                            tickFormatter={formatTimestampSeconds}
                                            dataKey="Timestamp"
                                            height={60}
                                            stroke="#8884d8"
                                            startIndex={startIndex}
                                            endIndex={endIndex}
                                            onChange={brushChangeHandler}
                                        />
                                        {refAreaLeft && refAreaRight ? (
                                            <ReferenceArea
                                                x1={refAreaLeft}
                                                x2={refAreaRight}
                                                strokeOpacity={0.3}
                                            />
                                        ) : null}
                                        {crops.map((crop) => (
                                            <React.Fragment key={crop.uuid}>
                                                <ReferenceLine
                                                    x={crop.start_timestamp}
                                                    stroke="red"
                                                />
                                                <ReferenceLine
                                                    x={crop.end_timestamp}
                                                    stroke="red"
                                                />
                                            </React.Fragment>
                                        ))}
                                    </AreaChart>
                                </ResponsiveContainer>
                            )}
                        </Box>
                        {sampledDataIndexed.length > 0 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}
                                >
                                    <IconButton
                                        disabled={startIndex === 0}
                                        onClick={() => {
                                            setStartIndex((startIndex) => startIndex - 1);
                                            setChartKey(Math.random().toString());
                                        }}
                                    >
                                        <ArrowLeft />
                                    </IconButton>
                                    <IconButton
                                        disabled={endIndex === startIndex}
                                        onClick={() => {
                                            setStartIndex((startIndex) => startIndex + 1);
                                            setChartKey(Math.random().toString());
                                        }}
                                    >
                                        <ArrowRight />
                                    </IconButton>
                                    Move Sliders Left And Right Or Use The Arrows To Create
                                    <IconButton
                                        disabled={endIndex === startIndex}
                                        onClick={() => {
                                            setEndIndex((endIndex) => endIndex - 1);
                                            setChartKey(Math.random().toString());
                                        }}
                                    >
                                        <ArrowLeft />
                                    </IconButton>
                                    <IconButton
                                        disabled={endIndex === sampledDataIndexed.length - 1}
                                        onClick={() => {
                                            setEndIndex((endIndex) => endIndex + 1);
                                            setChartKey(Math.random().toString());
                                        }}
                                    >
                                        <ArrowRight />
                                    </IconButton>
                                </Typography>
                                <Box>
                                    <Button
                                        onClick={() => {
                                            setStartIndex(0);
                                            setEndIndex(sampledDataIndexed.length - 1);
                                            setChartKey(Math.random().toString());
                                        }}
                                    >
                                        Reset Zoom
                                    </Button>
                                    <Can I={'individual:manage-logs'} this={upload.individual}>
                                        <Button onClick={() => setSaveCropOpen(true)}>
                                            Save Crop <OpenInNew />
                                        </Button>
                                    </Can>
                                </Box>
                            </Box>
                        )}
                    </Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TableContainer sx={{ p: 8, my: 4 }} component={Paper}>
                                <TypographyTitle>Crops</TypographyTitle>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Label</TableCell>
                                            <TableCell align="right">Time</TableCell>
                                            <TableCell align="right">Duration</TableCell>
                                            <TableCell align="right">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {crops.map((crop) => (
                                            <TableRow
                                                key={crop.uuid}
                                                sx={{
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <Button
                                                        onClick={() => {
                                                            const cropData = getDataForCrop(crop);
                                                            setSelectedCrops({
                                                                ...selectedCrops,
                                                                [crop.uuid]: {
                                                                    crop: crop,
                                                                    data: cropData,
                                                                    startIndex: 0,
                                                                    endIndex: cropData.length - 1,
                                                                    isEditing: false,
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        {crop.label}
                                                    </Button>
                                                </TableCell>

                                                <TableCell align="right">
                                                    {DateTime.fromMillis(
                                                        crop.start_timestamp,
                                                    ).toFormat('h:mm:ss.S a')}{' '}
                                                    to{' '}
                                                    {DateTime.fromMillis(
                                                        crop.end_timestamp,
                                                    ).toFormat('h:mm:ss.S a')}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {(
                                                        (crop.end_timestamp -
                                                            crop.start_timestamp) /
                                                        1000
                                                    ).toFixed(1)}
                                                    s
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Can
                                                        I={'individual:manage-logs'}
                                                        this={upload.individual}
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                setCrops((c) =>
                                                                    c.filter(
                                                                        (c) => c.uuid !== crop.uuid,
                                                                    ),
                                                                );
                                                                deleteCrop(
                                                                    upload.individual.uuid,
                                                                    upload.uuid,
                                                                    crop.uuid,
                                                                )
                                                                    .then(() => {})
                                                                    .catch(() => {});
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </Can>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {crops.length === 0 && (
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell colSpan={3} component="th" scope="row">
                                                    No Crops Have Been Created Yet. Move the slider
                                                    and create some crops.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Stack spacing={4}>
                                {Object.keys(selectedCrops).length === 0 && (
                                    <Paper
                                        sx={{
                                            my: 4,
                                            height: 200,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <TypographyTitle>
                                            Choose One (or more) Crops To View And Compare
                                        </TypographyTitle>
                                    </Paper>
                                )}
                                {Object.keys(selectedCrops).map((k: string) => (
                                    <Paper key={k} sx={{ p: 8, my: 4 }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {!selectedCrops[k].isEditing && (
                                                <Typography variant="h6">
                                                    {selectedCrops[k].crop.label}{' '}
                                                    <Typography variant="caption">
                                                        {DateTime.fromMillis(
                                                            selectedCrops[k].crop.start_timestamp,
                                                        ).toFormat('h:mm:ss.S a')}
                                                    </Typography>
                                                </Typography>
                                            )}
                                            {selectedCrops[k].isEditing && (
                                                <Formik
                                                    initialValues={{
                                                        label: selectedCrops[k].crop.label,
                                                    }}
                                                    onSubmit={(values) => {
                                                        setSelectedCrops((oldCrops) => {
                                                            const newCrops = { ...oldCrops };
                                                            newCrops[k].crop.label = values.label;
                                                            newCrops[k].isEditing = false;
                                                            return newCrops;
                                                        });
                                                        saveCrop(k);
                                                    }}
                                                >
                                                    {({ values, setFieldValue }) => (
                                                        <Form>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: 1,
                                                                }}
                                                            >
                                                                <TextField
                                                                    size={'small'}
                                                                    margin="dense"
                                                                    id="label"
                                                                    type="text"
                                                                    variant="outlined"
                                                                    value={values.label}
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            'label',
                                                                            e.target.value,
                                                                        );
                                                                    }}
                                                                />
                                                                <Button
                                                                    type={'submit'}
                                                                    variant={'contained'}
                                                                    sx={{ marginLeft: 4 }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </Box>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            )}

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                }}
                                            >
                                                {' '}
                                                {/* This Box wraps the two buttons */}
                                                <Button
                                                    onClick={() => {
                                                        setSelectedCrops((crops) => {
                                                            const newCrops = { ...crops };
                                                            delete newCrops[k];
                                                            return newCrops;
                                                        });
                                                    }}
                                                >
                                                    <VisibilityOff />
                                                </Button>
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectedCrops((crops) => {
                                                            const newCrops = { ...crops };
                                                            newCrops[k].isEditing =
                                                                !newCrops[k].isEditing;
                                                            return newCrops;
                                                        });
                                                    }}
                                                >
                                                    {!selectedCrops[k].isEditing ? (
                                                        <>
                                                            <Typography variant={'caption'}>
                                                                Edit
                                                            </Typography>
                                                            <StrikedEditIcon />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Typography variant={'caption'}>
                                                                Close
                                                            </Typography>
                                                            <EditIcon />
                                                        </>
                                                    )}
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <CropDataTable data={selectedCrops[k].data} />
                                        </Box>

                                        <Box
                                            style={{
                                                width: '100%',
                                                height: 450,
                                                backgroundColor: '#D9DADF',
                                                borderRadius: 20,
                                                border: '0px solid #A7ABB7',
                                                background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                                padding: 20,
                                            }}
                                        >
                                            <ResponsiveContainer width="100%" height="100%">
                                                <ComposedChart
                                                    height={350}
                                                    margin={{
                                                        top: 10,
                                                        right: 30,
                                                        left: 0,
                                                        bottom: 0,
                                                    }}
                                                    data={selectedCrops[k].data}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <YAxis
                                                        yAxisId="left"
                                                        label={{
                                                            value: 'Velocity (mph)',
                                                            angle: -90,
                                                            position: 'insideBottomLeft',
                                                            offset: 20,
                                                        }}
                                                        domain={[0, 'dataMax']}
                                                        tickFormatter={formatNumber}
                                                        style={{
                                                            fontSize: '0.75rem',
                                                        }}
                                                    />
                                                    <YAxis
                                                        yAxisId="right"
                                                        label={{
                                                            value: 'Cumulative Distance (yd)',
                                                            angle: 90,
                                                            position: 'insideBottomRight',
                                                            offset: 20,
                                                            align: 'center',
                                                            style: {
                                                                whiteSpace: 'nowrap', // Prevents text from wrapping
                                                            },
                                                        }}
                                                        orientation="right"
                                                        domain={[0, 'dataMax']}
                                                        tickFormatter={formatNumber}
                                                        style={{
                                                            fontSize: '0.75rem',
                                                        }}
                                                    />
                                                    {hasAtLeastOneHeartRateValue() && (
                                                        <YAxis
                                                            yAxisId="heartRate"
                                                            label={{
                                                                value: 'Heart Rate (bpm)',
                                                                angle: 90,
                                                                position: 'insideBottomRight',
                                                                offset: 20,
                                                                align: 'center',
                                                                style: {
                                                                    whiteSpace: 'nowrap', // Prevents text from wrapping
                                                                },
                                                            }}
                                                            orientation="right"
                                                            domain={['dataMin - 10', 'dataMax']}
                                                            style={{
                                                                fontSize: '0.75rem',
                                                            }}
                                                        />
                                                    )}
                                                    <XAxis
                                                        type={'number'}
                                                        dataKey="Duration (s)"
                                                        scale={'sequential'}
                                                        label={{
                                                            value: 'Duration (s)',
                                                            position: 'insideBottom',
                                                            offset: 20,
                                                        }}
                                                        angle={-90}
                                                        textAnchor="end"
                                                        height={100}
                                                        style={{
                                                            fontSize: '0.75rem',
                                                        }}
                                                    />
                                                    <Tooltip
                                                        content={(props) => (
                                                            <CustomTooltipRawGpsData
                                                                {...props}
                                                                formatTimestampFunction={
                                                                    formatTimestampTenths
                                                                }
                                                            />
                                                        )}
                                                    />
                                                    <Scatter
                                                        yAxisId={'left'}
                                                        dataKey="Velocity (mph)"
                                                        fill="#25C3F1"
                                                    />
                                                    {hasAtLeastOneHeartRateValue() && (
                                                        <Scatter
                                                            yAxisId={'heartRate'}
                                                            dataKey="Heart Rate (bpm)"
                                                            fill={'#FF0000'}
                                                        />
                                                    )}
                                                    <Bar
                                                        yAxisId={'right'}
                                                        dataKey={'Cumulative Distance (yd)'}
                                                        fill="#7794C5"
                                                        barSize={0.2}
                                                        type={'monotone'}
                                                    />
                                                    {selectedCrops[k].isEditing && (
                                                        <Brush
                                                            startIndex={selectedCrops[k].startIndex}
                                                            endIndex={selectedCrops[k].endIndex}
                                                            tickFormatter={formatTimestampTenths}
                                                            dataKey="Timestamp"
                                                            height={40}
                                                            stroke="#8884d8"
                                                            onChange={(newIndex: any): void => {
                                                                setSelectedCrops((crops) => {
                                                                    const newCrops = { ...crops };
                                                                    newCrops[k].startIndex =
                                                                        newIndex.startIndex;
                                                                    newCrops[k].endIndex =
                                                                        newIndex.endIndex;
                                                                    return newCrops;
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </ComposedChart>
                                            </ResponsiveContainer>
                                        </Box>
                                        {selectedCrops[k].isEditing && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Move Sliders Left And Right And Press Save Below
                                                </Typography>
                                                <Lottie
                                                    animationData={saveButton} // Correct property name
                                                    loop={true}
                                                    autoplay={true}
                                                    style={{
                                                        width: '200px', // Adjust the width and height as needed
                                                        height: '100px',
                                                    }}
                                                    onClick={() => {
                                                        saveCrop(k);
                                                    }}
                                                ></Lottie>
                                            </Box>
                                        )}
                                    </Paper>
                                ))}
                            </Stack>
                        </Grid>
                    </Grid>
                    <Dialog
                        open={saveCropOpen}
                        onClose={() => setSaveCropOpen(false)}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleDialogSubmit();
                                setSaveCropOpen(false);
                            }
                        }}
                    >
                        <DialogTitle>Save Crop</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                To save this crop enter a custom label.
                            </DialogContentText>
                            <TextField
                                margin="dense"
                                id="name"
                                label="Custom Label"
                                type="email"
                                fullWidth
                                variant="standard"
                                value={cropLabel}
                                onChange={(e) => {
                                    setCropLabel(e.target.value);
                                }}
                            />
                            {endIndex - startIndex > 500 && (
                                <Typography
                                    sx={{
                                        color: 'red',
                                    }}
                                >
                                    ** Crop must be smaller
                                </Typography>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button type={'button'} onClick={() => setSaveCropOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                onClick={() => handleDialogSubmit()}
                                disabled={endIndex - startIndex > 500 || !cropLabel}
                            >
                                Save Crop
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={!!message}
                autoHideDuration={1500}
                onClose={() => setMessage('')}
                message={message}
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setMessage('')}
                        >
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    );
};
export default RawDataViewer;
