import React, { useCallback, useRef, useState } from 'react';
import { Box, Button, ButtonBase, Modal, Theme, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useToggle } from 'utils';
import { useTheme } from '@mui/system';

import { ButtonWithLoader } from 'components/Buttons';
import { SxProps } from '@mui/system';

export interface UploadBannerProps {
    loading?: boolean;
    uploadImage: File | undefined;
    setUploadImage: React.Dispatch<React.SetStateAction<File | undefined>>;
    defaultImgSrc?: string | null;
    button?: boolean;
    uploadContainerClassname?: string;
    uploadedImageClassname?: string;
    imageName?: 'banner' | 'image';
    onClose?: (file?: File) => void; // either this function set cropped file or it set complete file as banner
    uploadContainerSx?: SxProps<Theme>;
    uploadedImageSx?: SxProps<Theme>;
    deleteBanner?: () => void;
    onMessage?: (message: string) => void;
}

const UploadBanner = ({
    loading,
    uploadImage,
    setUploadImage,
    defaultImgSrc = null,
    button = true,
    uploadContainerClassname,
    uploadedImageClassname,
    imageName = 'banner',
    deleteBanner = () => {},
    onMessage = () => {},
}: UploadBannerProps) => {
    const theme = useTheme();
    const [, toggle] = useToggle();

    const [isBannerDeleted, setIsBannerDeleted] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const setOriginalImage = useCallback(
        (file: File) => {
            const url = URL.createObjectURL(file);
            const img = new Image();
            img.onload = () => {
                setUploadImage(file);
                toggle();
                setIsBannerDeleted(false);
                // if (img.width < 728 && img.height === 90) {
                //     setUploadImage(file);
                //     toggle();
                // } else {
                //     // Handle the error case when the image size is not 728x90
                //     onError && onError('The banner size must be 728x90 pixels.');
                // }
                URL.revokeObjectURL(url);
            };
            img.src = url;
        },
        [onMessage, setUploadImage, toggle], // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                }}
            >
                {(uploadImage || defaultImgSrc) && !isBannerDeleted && (
                    <Button
                        sx={{
                            position: 'absolute',
                            bottom: '0',
                            right: '-16px',
                            cursor: 'pointer',
                            padding: '1px',
                            color: 'white',
                            height: '32px',
                            width: '32px',
                            minWidth: '32px',
                            background: 'rgba(0,0,0,0)',
                            borderRadius: '2px',
                        }}
                        onClick={() => {
                            setIsDeleteModalOpen(true);
                        }}
                    >
                        🗑️
                    </Button>
                )}
                <ButtonBase
                    sx={{
                        width: 480,
                        height: 90,
                        display: 'flex',
                        cursor: 'pointer',
                        overflow: 'hidden',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        m: 6,
                        border:
                            (!uploadImage && !defaultImgSrc) || isBannerDeleted
                                ? '2px dashed rgba(87, 95, 107, 0.5)'
                                : 'none',
                        background:
                            'linear-gradient(0deg, rgba(0, 62, 157, 0.08), rgba(0, 62, 157, 0.08)), #FFFFFF',
                        ...(uploadContainerClassname === undefined &&
                            {
                                // your styles when uploadContainerClassname isn't provided
                            }),
                    }}
                    onClick={() => fileInputRef.current && fileInputRef.current?.click()}
                >
                    {uploadImage && !isBannerDeleted && (
                        <img
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                ...(uploadedImageClassname === undefined &&
                                    {
                                        // your styles here, applied when uploadedImageClassname is not given
                                    }),
                            }}
                            src={URL.createObjectURL(uploadImage)}
                            alt={imageName}
                        />
                    )}
                    {!uploadImage && defaultImgSrc && !isBannerDeleted && (
                        <img
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                ...(uploadedImageClassname === undefined &&
                                    {
                                        // your styles here, applied when uploadedImageClassname is not given
                                    }),
                            }}
                            src={defaultImgSrc}
                            alt={imageName}
                        />
                    )}
                    {((!uploadImage && !defaultImgSrc) || isBannerDeleted) && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <AddOutlinedIcon
                                sx={{ fontSize: 24, fill: theme.palette.primary.light }}
                            />
                            <Box>Add Banner</Box>
                        </Box>
                    )}
                </ButtonBase>
                <div>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const files = e.target.files;
                            if (files && files.length > 0) setOriginalImage(files[0]);
                        }}
                        onClick={(event) => {
                            if (event.currentTarget.value) event.currentTarget.value = '';
                        }}
                        hidden={true}
                    />
                </div>

                {button && (uploadImage || defaultImgSrc) && (
                    <ButtonWithLoader
                        loading={loading}
                        disabled={loading}
                        color="primary"
                        sx={{ minWidth: 192, minHeight: 56 }}
                        onClick={() => fileInputRef.current?.click()}
                        variant={'outlined'}
                    >
                        {`Replace ${imageName}`}
                    </ButtonWithLoader>
                )}
            </Box>
            <Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
                <Box
                    sx={{
                        padding: 8,
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        margin: 'auto',
                        background: 'white',
                        color: 'black',
                        height: '200px',
                        width: '300px',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '4px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    <Typography>Are you sure you want to delete this banner?</Typography>
                    <Box
                        sx={{
                            marginTop: 'auto',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{ marginRight: 2 }}
                            onClick={async () => {
                                deleteBanner();
                                setIsBannerDeleted(true);
                                setIsDeleteModalOpen(false);
                                setUploadImage(undefined);
                                onMessage('Banner deleted successfully!');
                            }}
                        >
                            Delete
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setIsDeleteModalOpen(false)}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export { UploadBanner };
