import LandingContactForm from 'components/Layouts/LandingContactForm';
import LandingPageAbout from 'components/Layouts/LandingPageAbout';
import LandingPageFeatures from 'components/Layouts/LandingPageFeatures';
import AuthLayout from 'Layouts/AuthLayout';
import BlankLayout from 'Layouts/BlankLayout';
import NavigationLayout from 'Layouts/NavigationLayout';

import {
    AcceptInvite,
    EditOrganization,
    EditSession,
    ForgotPassword,
    ForgotUsername,
    IndividualLink,
    LandingPage,
    Login,
    LogSession,
    NotFound,
    Profile,
    Redirecting,
    Register,
    RestorePassword,
    Unauthorized,
    ViewLog,
    ViewSession,
} from 'pages';
import { RouteProps } from 'react-router-dom';
import { Navigation } from 'utils';
import AppUserAgreement from '../components/Layouts/AppUserAgreement';
import CaPrivacyPolicy from '../components/Layouts/CaPrivacyPolicy';
import PrivacyPolicy from '../components/Layouts/PrivacyPolicy';
import { athleteRoutes } from '../modules/athletes/athletes.routes';
import { authRoutes } from '../modules/auth/scenes/auth.routes';
import { communityRoutes } from '../modules/community/community.routes';
import exploreRoutes from '../modules/explore/explore.routes';
import { homeRoutes } from '../modules/home/home.routes';
import { learnRoutes } from '../modules/learn/learn.routes';
import Learn1TUL from '../modules/Learn1TUL/scenes/Learn1TULLanding';
import { marketplaceRoutes } from '../modules/marketplace/marketplace.routes';
import { mediaRoutes } from '../modules/media/media.routes';
import networkingRoutes from '../modules/networking/networking.routes';
import { planRoutes } from '../modules/plan/scenes/plan.routes';
import { socialRoutes } from '../modules/social/social.routes';
import SessionsList from '../modules/train/scenes/SessionsList';
import { trainRoutes } from '../modules/train/scenes/train-routes';
import { websiteRoutes } from '../modules/website/website.routes';
import OrganizationInvitationAccepted from '../pages/Organization/OrganizationInvitationAccepted';
import AdminBasePage from '../modules/admin/AdminBasePage';
import { adminRoutes } from '../modules/admin/admin.routes';
import shopRoutes from '../modules/shop/shop.routes';

export type RouteConfigProps = RouteProps & {
    component: any;
    layout?: any;
    private?: boolean;
    is_auth_route?: boolean;
    primaryNavigation?: Navigation;
    secondaryNavigation?: Navigation;
    pageTitle?: string;
};

const routeconfig: RouteConfigProps[] = [
    ...authRoutes,
    ...trainRoutes,
    ...planRoutes,
    ...learnRoutes,
    ...marketplaceRoutes,
    ...homeRoutes,
    ...socialRoutes,
    ...communityRoutes,
    ...mediaRoutes,
    ...websiteRoutes,
    ...exploreRoutes,
    ...athleteRoutes,
    ...networkingRoutes,
    ...adminRoutes,
    ...shopRoutes,
    {
        component: IndividualLink,
        exact: true,
        layout: AuthLayout,
        path: '/i/:id',
        private: false,
    },
    {
        path: '/Learn1TUL',
        exact: true,
        layout: NavigationLayout,
        component: Learn1TUL,
        private: true,
    },
    {
        path: '/Features',
        exact: true,
        layout: BlankLayout,
        component: LandingPageFeatures,
        private: false,
    },
    {
        path: '/About',
        exact: true,
        layout: BlankLayout,
        component: LandingPageAbout,
        private: false,
    },
    {
        path: '/Contactus',
        exact: true,
        layout: BlankLayout,
        component: LandingContactForm,
        private: false,
    },
    {
        path: '/PrivacyPolicy',
        exact: true,
        layout: BlankLayout,
        component: PrivacyPolicy,
        private: false,
    },
    {
        path: '/CaPrivacyPolicy',
        exact: true,
        layout: BlankLayout,
        component: CaPrivacyPolicy,
        private: false,
    },
    {
        path: '/AppUserAgreement',
        exact: true,
        layout: BlankLayout,
        component: AppUserAgreement,
        private: false,
    },

    {
        path: '/accept-invite/:token',
        exact: true,
        layout: AuthLayout,
        component: AcceptInvite,
        private: false,
    },
    {
        exact: true,
        layout: AuthLayout,
        path: '/login',
        component: Login,
        private: false,
        is_auth_route: true,
    },
    {
        exact: true,
        layout: AuthLayout,
        path: '/forgot-password',
        component: ForgotPassword,
        private: false,
        is_auth_route: true,
    },
    {
        exact: true,
        layout: AuthLayout,
        path: '/forgot-username',
        component: ForgotUsername,
        private: false,
        is_auth_route: true,
    },
    {
        exact: true,
        layout: AuthLayout,
        path: '/api/authentication/password/redirect-to-reset',
        component: RestorePassword,
        private: false,
        is_auth_route: true,
    },
    {
        exact: true,
        layout: AuthLayout,
        path: '/register',
        component: Register,
        private: false,
        is_auth_route: true,
    },
    {
        exact: true,
        layout: BlankLayout,
        path: '/links/:linkId',
        component: LandingPage,
        private: false,
    },

    //Redirecting Page if user is not logged in
    {
        path: '/redirecting',
        exact: true,
        layout: AuthLayout,
        component: Redirecting,
        private: false,
    },
    //404 Page Not Found
    {
        path: '/unauthorized',
        exact: true,
        layout: NavigationLayout,
        private: true,
        component: Unauthorized,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/profile',
        component: Profile,
        private: true,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/train/sessions/:id',
        component: EditSession,
        private: true,
        primaryNavigation: Navigation.TRAIN,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/train/sessions/:id/view',
        component: ViewSession,
        private: true,
        primaryNavigation: Navigation.TRAIN,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/train/activities/:id',
        component: LogSession,
        private: true,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/organizations/:id',
        component: EditOrganization,
        private: true,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/organizations/:id/invitation-accepted',
        component: OrganizationInvitationAccepted,
        private: true,
    },
    {
        exact: false,
        layout: NavigationLayout,
        path: '/train/sessions/:id',
        component: EditSession,
        private: true,
        primaryNavigation: Navigation.TRAIN,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/train/sessions',
        component: SessionsList,
        private: true,
        primaryNavigation: Navigation.TRAIN,
        secondaryNavigation: Navigation.TRAIN_SESSIONS,
    },

    {
        exact: true,
        layout: NavigationLayout,
        path: '/activities/:id',
        component: ViewLog,
        private: true,
    },
    {
        path: '/404',
        exact: false,
        layout: BlankLayout,
        private: false,
        component: NotFound,
    },
    {
        exact: false,
        layout: NavigationLayout,
        path: '/admin',
        component: AdminBasePage,
        private: true,
    },
];

export default routeconfig;
