import Request from 'api/Request';
import { AxiosResponse } from 'axios';
import { Invoice } from './getInvoicesForOrganization';

interface PaginatedApiResponse {
    data: Array<Invoice>;
    meta: {
        current_page: string;
        per_page: string;
        has_more: boolean;
    };
}

type ReturnTypeInvoices = Promise<AxiosResponse<PaginatedApiResponse>>;

/**
 * Fetch the current athlete user's invoices
 */
const getAthleteInvoices = (
    perPage: number,
    page: number,
    status: 'all' | 'paid' | 'open' | 'past_due' | 'void',
    startDate?: string | null,
    endDate?: string | null,
): ReturnTypeInvoices => {
    return Request.get(`/athlete-users/billing/get-invoices`, {
        params: {
            per_page: perPage,
            page,
            status,
            start_date: startDate,
            end_date: endDate,
        },
    });
};

export default getAthleteInvoices;
