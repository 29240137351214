import { Box, CircularProgress, IconButton, Snackbar, TextField } from '@mui/material';
import { DeleteOutline, ImportExport, ShoppingCartOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { ShoppingCartItem } from '../../../utils';
import { UseShoppingCart } from '../../../contexts/ShoppingCartContext';
import { addItemToCart, changeItemQuantity } from '../shop.api';

interface CartInputProps {
    initialValue?: number;
    name: string;
    maxValue?: number | null;
    cartUuid?: string;
    stripePriceId: string;
    type: string;
    category: string;
    sellableId?: string | null;
    disabled?: boolean;
    onAdded?: () => void;
    onRemoved?: () => void;
}

export const CartInput: React.FC<CartInputProps> = ({
    initialValue = 0,
    maxValue,
    cartUuid,
    name,
    stripePriceId,
    type,
    category,
    sellableId = null,
    disabled = false,
    onAdded = null,
    onRemoved = null,
}) => {
    const [quantity, setQuantity] = useState(initialValue);
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [isInCart, setIsInCart] = useState(false);
    const [cartItemUuid, setCartItemUuid] = useState<string | null>(null);
    const [adjustedQuantity, setAdjustedQuantity] = useState(quantity);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const { refetchCart, cart } = UseShoppingCart();

    const isNonPhysicalItem = maxValue === null;

    const findCartItem = (
        items: ShoppingCartItem[],
        stripePriceId: string,
        sellableId: string | null,
    ): ShoppingCartItem | undefined =>
        items.find(
            (item) => item.stripe_price_id === stripePriceId && item.sellable_id === sellableId,
        );

    useEffect(() => {
        if (cart?.items) {
            const cartItem = findCartItem(cart.items, stripePriceId, sellableId);

            if (cartItem) {
                setIsInCart(true);
                setCartItemUuid(cartItem.uuid);
                setQuantity(cartItem.quantity);
                setAdjustedQuantity(cartItem.quantity);
            } else {
                setIsInCart(false);
                setCartItemUuid(null);
                setQuantity(initialValue);
                setAdjustedQuantity(initialValue);
            }
        }
    }, [cart, stripePriceId, sellableId, initialValue]);

    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNonPhysicalItem) return;

        const value = parseInt(event.target.value, 10) || 0;
        if (value >= 0 && (!maxValue || value <= maxValue)) {
            if (isInCart) {
                setAdjustedQuantity(value);
            } else {
                setQuantity(value);
            }
        }
    };

    const handleAddToCart = async () => {
        const quantityToAdd = isNonPhysicalItem ? 1 : quantity;
        if (quantityToAdd > 0) {
            setLoading(true);
            try {
                await addItemToCart(
                    cartUuid!,
                    name,
                    stripePriceId,
                    quantityToAdd,
                    type,
                    category,
                    sellableId,
                );
                await refetchCart();
                onAdded?.();
            } catch (error) {
                setSnackbarMessage('Error adding item to cart');
                console.error('Error adding item to cart:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleUpdateQuantity = async () => {
        if (!cartItemUuid) return;

        setUpdateLoading(true);
        try {
            await changeItemQuantity(cartUuid!, cartItemUuid, adjustedQuantity);
            await refetchCart();
        } catch (error) {
            setSnackbarMessage('Error changing item quantity');
        } finally {
            setUpdateLoading(false);
        }
    };

    const handleRemoveFromCart = async () => {
        if (!cartItemUuid) return;

        setDeleteLoading(true);
        try {
            await changeItemQuantity(cartUuid!, cartItemUuid, 0);
            await refetchCart();
            onRemoved?.();
        } catch (error) {
            setSnackbarMessage('Error removing item from cart');
            console.error('Error removing item from cart:', error);
        } finally {
            setDeleteLoading(false);
        }
    };

    return (
        <>
            <Snackbar
                open={!!snackbarMessage}
                message={snackbarMessage}
                autoHideDuration={6000}
                onClose={() => setSnackbarMessage('')}
            />
            {isInCart ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {!isNonPhysicalItem && (
                        <>
                            <TextField
                                type="number"
                                value={adjustedQuantity}
                                onChange={handleQuantityChange}
                                disabled={disabled || loading}
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: maxValue,
                                    },
                                    style: {
                                        fontSize: '0.8rem',
                                    },
                                }}
                                sx={{
                                    width: '42px',
                                    '& input': {
                                        padding: '4px',
                                        textAlign: 'center',
                                    },
                                }}
                                size="small"
                            />
                            <IconButton
                                onClick={handleUpdateQuantity}
                                disabled={
                                    disabled || updateLoading || adjustedQuantity === quantity
                                }
                                color="primary"
                                size="small"
                                title="Update quantity"
                            >
                                {updateLoading ? <CircularProgress size={24} /> : <ImportExport />}
                            </IconButton>
                        </>
                    )}
                    <IconButton
                        onClick={handleRemoveFromCart}
                        disabled={isNonPhysicalItem ? deleteLoading : disabled || deleteLoading}
                        color="error"
                        size="small"
                    >
                        {deleteLoading ? <CircularProgress size={24} /> : <DeleteOutline />}
                    </IconButton>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {!isNonPhysicalItem && (
                        <TextField
                            type="number"
                            value={quantity}
                            onChange={handleQuantityChange}
                            disabled={disabled || loading}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    max: maxValue,
                                },
                                style: {
                                    fontSize: '0.8rem',
                                },
                            }}
                            sx={{
                                width: '42px',
                                '& input': {
                                    padding: '4px',
                                    textAlign: 'center',
                                },
                            }}
                            size="small"
                        />
                    )}
                    <IconButton
                        onClick={handleAddToCart}
                        disabled={disabled || loading || (!isNonPhysicalItem && quantity === 0)}
                        color="primary"
                        size="small"
                    >
                        {loading ? <CircularProgress size={24} /> : <ShoppingCartOutlined />}
                    </IconButton>
                    {!isNonPhysicalItem && <span style={{ width: '33px' }}></span>}
                </Box>
            )}
        </>
    );
};
