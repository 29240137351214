import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getShoppingCart, ShoppingCart } from '../modules/shop/shop.api';

interface ShoppingCartContextType {
    cart: ShoppingCart | null;
    refetchCart: () => Promise<void>;
    isInitialLoading?: boolean;
}

interface ShoppingCartProviderProps {
    children: ReactNode;
}

const ShoppingCartContext = createContext<ShoppingCartContextType>({
    cart: null,
    refetchCart: async () => {},
});

export const ShoppingCartProvider: React.FC<ShoppingCartProviderProps> = ({ children }) => {
    const [shoppingCartData, setShoppingCartData] = useState<
        Omit<ShoppingCartContextType, 'refetchCart'>
    >({
        cart: null,
    });
    const [isInitialLoading, setIsInitialLoading] = useState(true);

    const fetchShoppingCart = async () => {
        try {
            if (window.location.href.includes('/unauthorized')) {
                return;
            }

            const response = await getShoppingCart();
            console.debug('Received active shopping cart', response.data);
            setShoppingCartData({
                cart: response.data,
            });
            console.debug('Updated shopping cart state:', response.data);
        } catch (error) {
            console.log('Error fetching shopping cart:', error);
            setShoppingCartData({
                cart: null,
            });
        } finally {
            setIsInitialLoading(false);
        }
    };

    // Memoize the context value to prevent unnecessary re-renders
    const contextValue = React.useMemo(
        () => ({
            ...shoppingCartData,
            refetchCart: fetchShoppingCart,
            isInitialLoading,
        }),
        [isInitialLoading, shoppingCartData],
    );

    useEffect(() => {
        fetchShoppingCart();
    }, []); // Intentionally empty as this should only run once on mount

    useEffect(() => {
        console.log('Shopping cart data changed:', shoppingCartData);
    }, [shoppingCartData]);

    return (
        <ShoppingCartContext.Provider value={contextValue}>{children}</ShoppingCartContext.Provider>
    );
};

export const UseShoppingCart = () => {
    return useContext(ShoppingCartContext);
};
