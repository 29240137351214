import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { PhysicalProductForPurchase } from 'utils';

interface PhysicalItemPurchaseDialogProps {
    open: boolean;
    onClose: () => void;
    items: PhysicalProductForPurchase[];
    onPurchase: (selectedItems: Record<string, number>) => void;
    title: string;
    imageSource: string;
    shippingFee: number;
    subscription?: boolean;
}

const PhysicalItemPurchaseDialog: React.FC<PhysicalItemPurchaseDialogProps> = ({
    open,
    onClose,
    items,
    onPurchase,
    title,
    imageSource,
    shippingFee,
    subscription = false,
}) => {
    const [selectedItemCounts, setSelectedItemCounts] = useState<Record<string, number>>({});

    const handleItemCountChange = (priceId: string, count: number) => {
        setSelectedItemCounts((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[priceId];
            } else {
                newCounts[priceId] = count;
            }
            return newCounts;
        });
    };

    const calculateTotalCost = () => {
        let totalCost = 0;
        items.forEach((item) => {
            const count = selectedItemCounts[item.stripe_price_id] || 0;
            const costForThisItem = (count * item.price) / 100;
            totalCost += costForThisItem;
        });
        return totalCost.toFixed(2);
    };

    const isPurchaseButtonDisabled = () => {
        return !Object.values(selectedItemCounts).some((count) => count > 0);
    };

    const handlePurchase = () => {
        onPurchase(selectedItemCounts);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                {items.map((item) => (
                    <FormControl key={item.uuid} fullWidth margin="normal">
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Box
                                component="img"
                                sx={{
                                    height: 24,
                                    width: 24,
                                    marginRight: 2,
                                }}
                                alt={item.name}
                                src={imageSource}
                            />
                            <Typography sx={{ width: 'calc(80% - 24px)', fontSize: '0.8rem' }}>
                                {item.name}
                                {subscription ? (
                                    <span style={{ fontWeight: '500' }}>
                                        {' $'}
                                        {(item.price / 100).toFixed(2)}/month
                                    </span>
                                ) : (
                                    <span style={{ fontWeight: '500' }}>
                                        {' $'}
                                        {(item.price / 100).toFixed(2)}
                                    </span>
                                )}
                                <span
                                    style={{
                                        color: item.inventory_count > 0 ? 'green' : 'red',
                                        fontStyle: 'italic',
                                        fontSize: '0.65rem',
                                    }}
                                >
                                    {item.inventory_count > 0
                                        ? ` ${item.inventory_count} available`
                                        : ' available soon'}
                                </span>
                            </Typography>
                            {item.inventory_count > 0 && (
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{
                                        width: '120px',
                                        minWidth: '120px',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        sx={{
                                            width: '32px',
                                            height: '32px',
                                            minWidth: '32px',
                                            padding: 0,
                                        }}
                                        onClick={() =>
                                            handleItemCountChange(
                                                item.stripe_price_id,
                                                Math.max(
                                                    (selectedItemCounts[item.stripe_price_id] ||
                                                        0) - 1,
                                                    0,
                                                ),
                                            )
                                        }
                                        disabled={
                                            (selectedItemCounts[item.stripe_price_id] || 0) === 0
                                        }
                                    >
                                        −
                                    </IconButton>
                                    <TextField
                                        value={selectedItemCounts[item.stripe_price_id] || 0}
                                        inputProps={{
                                            readOnly: true,
                                            style: {
                                                textAlign: 'center',
                                                padding: '4px',
                                                width: '40px',
                                                minWidth: '40px',
                                                fontSize: '0.8rem',
                                            },
                                        }}
                                        sx={{
                                            width: '40px',
                                            minWidth: '40px',
                                            '& .MuiOutlinedInput-root': {
                                                padding: 0,
                                            },
                                        }}
                                        size="small"
                                    />
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            handleItemCountChange(
                                                item.stripe_price_id,
                                                Math.min(
                                                    (selectedItemCounts[item.stripe_price_id] ||
                                                        0) + 1,
                                                    Math.min(item.inventory_count),
                                                ),
                                            )
                                        }
                                        disabled={
                                            (selectedItemCounts[item.stripe_price_id] || 0) >=
                                            Math.min(item.inventory_count)
                                        }
                                        sx={{
                                            width: '32px',
                                            height: '32px',
                                            minWidth: '32px',
                                            padding: 0,
                                        }}
                                    >
                                        +
                                    </IconButton>
                                </Stack>
                            )}
                        </Stack>
                    </FormControl>
                ))}
            </DialogContent>
            {!isPurchaseButtonDisabled() && (
                <div style={{ padding: '10px 16px 4px 0', textAlign: 'right' }}>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            fontSize: '0.65rem',
                            color: '#424242',
                            fontWeight: '450',
                        }}
                    >
                        Subtotal: ${calculateTotalCost()}
                    </Typography>
                    {!subscription && (
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                fontSize: '0.6rem',
                                color: '#424242',
                            }}
                        >
                            Tax: (will be calculated)
                        </Typography>
                    )}
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            fontSize: '0.6rem',
                            color: '#424242',
                        }}
                    >
                        Shipping: ${shippingFee.toFixed(2)}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            fontSize: '0.65rem',
                            color: '#424242',
                            fontWeight: '600',
                        }}
                    >
                        Total: ${(parseFloat(calculateTotalCost()) + shippingFee).toFixed(2)}
                    </Typography>
                </div>
            )}
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    onClick={handlePurchase}
                    variant="contained"
                    disabled={isPurchaseButtonDisabled()}
                >
                    Purchase
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PhysicalItemPurchaseDialog;
