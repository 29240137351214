import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Stack,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    SelectChangeEvent,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
} from '@mui/material';
import { DataGrid, GridColDef, GridLoadingOverlay, GridPaginationModel } from '@mui/x-data-grid';
import getOrganizationsForAthleteUser from '../../../api/Organization/getOrganizationsForAthleteUser';
import { Invoice } from '../../../api/Auth/getInvoicesForOrganization';
import getAthleteInvoices from '../../../api/Auth/getAthleteInvoices';
import { OrganizationPublic } from '../../../utils';
import { CreditCard, SpeakerPhone } from '@mui/icons-material';
//import RequestPageIcon from '@mui/icons-material/RequestPage';
import athleteUpdatePaymentMethod from '../../../api/Auth/athleteUpdatePaymentMethod';
//import athleteConsolidateOpenInvoices from '../../../api/Auth/athleteConsolidateOpenInvoices';
import FiyrPodReturnForm from '../../Learn1TUL/scenes/FiyrpodReturnForm';
import { getSellerOrganization } from '../../shop/shop.api';

function CustomLoadingOverlay() {
    return (
        <GridLoadingOverlay
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
            }}
        >
            <CircularProgress />
        </GridLoadingOverlay>
    );
}

const AthleteHome = (): ReactElement => {
    const [snackMessage, setSnackMessage] = useState('');
    const [organizations, setOrganizations] = useState<OrganizationPublic[]>([]);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [invoicesLoading, setInvoicesLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [formStatus, setFormStatus] = useState<'all' | 'paid' | 'open' | 'past_due' | 'void'>(
        'open',
    );
    const [formStartDate, setFormStartDate] = useState<string | null>(null);
    const [formEndDate, setFormEndDate] = useState<string | null>(null);
    const [searchParams, setSearchParams] = useState({
        status: 'open' as 'all' | 'paid' | 'open' | 'past_due' | 'void',
        startDate: null as string | null,
        endDate: null as string | null,
    });
    const [isReturningHardware, setIsReturningHardware] = useState(false);
    const [userType, setUserType] = useState<'user' | 'organization' | null>(null);

    useEffect(() => {
        fetchOrganizations();
        fetchSellerOrganization();
    }, []);

    const fetchSellerOrganization = async () => {
        const orgResponse = await getSellerOrganization();
        setUserType(orgResponse.data.userType);
    };

    const fetchOrganizations = async () => {
        try {
            const response = await getOrganizationsForAthleteUser();
            setOrganizations(response.data);
        } catch (error) {
            setSnackMessage('Failed to fetch organizations');
        }
    };

    const isPastDue = (invoice: Invoice): boolean => {
        return invoice.status === 'open' && invoice.due_date < Date.now() / 1000;
    };

    const getComputedStatus = (invoice: Invoice): string => {
        if (isPastDue(invoice)) {
            return 'past_due';
        }
        return invoice.status;
    };

    const decodeStripeUrl = (url: string): string => {
        return url.replace(/\\/g, '');
    };

    const handleStatusChange = (event: SelectChangeEvent) => {
        setFormStatus(event.target.value as 'all' | 'paid' | 'open' | 'past_due' | 'void');
    };

    const handleSearch = (event: React.FormEvent) => {
        event.preventDefault();
        setSearchParams({
            status: formStatus,
            startDate: formStartDate,
            endDate: formEndDate,
        });
        setPaginationModel((prev) => ({ ...prev, page: 0 }));
    };

    const handleOrganizationClick = (slug: string) => {
        window.open(`/shop/${slug}`, '_blank');
    };

    const handleChangeBillingMethod = () => {
        athleteUpdatePaymentMethod(window.location.href)
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                setSnackMessage(`An error occurred. ${e.response?.data?.message}`);
            });
    };

    // const handleConsolidateOpenInvoices = () => {
    //     athleteConsolidateOpenInvoices(window.location.href)
    //         .then((response) => {
    //             window.location.href = response.data.url;
    //         })
    //         .catch((e: any) => {
    //             setSnackMessage(`An error occurred. ${e.response?.data?.message}`);
    //         });
    // };

    const convertSnakeToTitleCase = (str: string | undefined): string => {
        if (!str) return '';
        return str
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const organizationColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Organizations',
            flex: 1,
            minWidth: 300,
            renderCell: (params: any) => {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', height: '100%' }}>
                            {params.row.image_urls && params.row.image_urls.avatar && (
                                <Box
                                    component="img"
                                    src={params.row.image_urls.avatar}
                                    alt=""
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: '50%',
                                        marginRight: 2,
                                        objectFit: 'cover',
                                    }}
                                />
                            )}
                            <Typography variant="body1">{params.row.name}</Typography>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleOrganizationClick(params.row.slug)}
                        >
                            Shop as {userType === 'organization' ? 'Coach' : 'Athlete'}
                        </Button>
                    </Box>
                );
            },
        },
    ];

    const invoiceColumns: GridColDef[] = [
        {
            field: 'number',
            headerName: 'Invoice Number',
            flex: 1,
            minWidth: 130,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 120,
            renderCell: (params: any) => {
                const computedStatus = getComputedStatus(params.row);
                return (
                    <span
                        style={{
                            backgroundColor:
                                computedStatus === 'paid'
                                    ? '#4caf50'
                                    : computedStatus === 'past_due'
                                    ? '#f44336'
                                    : '#ffeb3b',
                            color:
                                computedStatus === 'paid'
                                    ? 'white'
                                    : computedStatus === 'past_due'
                                    ? 'white'
                                    : 'black',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            textTransform: 'capitalize',
                        }}
                    >
                        {computedStatus}
                    </span>
                );
            },
        },
        {
            field: 'amount_due',
            headerName: 'Amount Due',
            flex: 1,
            minWidth: 120,
            renderCell: (params: any) => {
                let value = params.row.amount_due;
                if (params.row.metadata?.consolidated_payment === 'true') {
                    value = params.row.total;
                }
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(value / 100);
            },
        },
        {
            field: 'amount_paid',
            headerName: 'Amount Paid',
            flex: 1,
            minWidth: 120,
            renderCell: (params: any) => {
                let value = params.row.amount_paid;
                if (params.row.metadata?.consolidated_payment === 'true') {
                    value = params.row.total;
                }
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(value / 100);
            },
        },
        {
            field: 'created',
            headerName: 'Created Date',
            flex: 1,
            minWidth: 160,
            valueFormatter: (value: any) => {
                return new Date(value * 1000).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                });
            },
        },
        {
            field: 'due_date',
            headerName: 'Due Date',
            flex: 1,
            minWidth: 160,
            valueFormatter: (params: any) => {
                return params?.value
                    ? new Date(params.value * 1000).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                      })
                    : 'N/A';
            },
        },
        {
            field: 'subscription_name',
            headerName: 'Type',
            flex: 1,
            minWidth: 160,
            renderCell: (params) => {
                if (params.row.metadata.consolidated_payment) {
                    return 'Consolidated Payment';
                }
                return (
                    convertSnakeToTitleCase(params.row.subscription_details.metadata?.name) ?? ''
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            minWidth: 120,
            renderCell: (params: any) => {
                if (!params.row.hosted_invoice_url) {
                    return null;
                }
                return (
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', height: '100%' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            href={decodeStripeUrl(params.row.hosted_invoice_url)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {params.row.status === 'open' ? 'Pay Invoice' : 'View Invoice'}
                        </Button>
                    </Box>
                );
            },
        },
    ];

    const fetchInvoices = useCallback(async () => {
        setInvoicesLoading(true);
        try {
            const response = await getAthleteInvoices(
                paginationModel.pageSize,
                paginationModel.page + 1,
                searchParams.status,
                searchParams.startDate,
                searchParams.endDate,
            );

            setInvoices(response.data.data);
            setHasMore(response.data.meta.has_more);
        } catch (error) {
            console.error('Error fetching invoices:', error);
            setSnackMessage('Failed to fetch invoices');
        } finally {
            setInvoicesLoading(false);
        }
    }, [paginationModel, searchParams]);

    useEffect(() => {
        fetchInvoices();
    }, [fetchInvoices]);

    return (
        <Container maxWidth={false} sx={{ width: '98%', mx: 'auto', py: 4 }}>
            <Dialog open={!!snackMessage} onClose={() => setSnackMessage('')}>
                <DialogTitle>Notice</DialogTitle>
                <DialogContent>{snackMessage}</DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setSnackMessage('')}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={isReturningHardware} onClose={() => setSnackMessage('')}>
                <DialogContent>
                    <FiyrPodReturnForm
                        onSubmitCallback={function () {
                            setIsReturningHardware(false);
                            setSnackMessage('Pod return requested successfully!');
                        }}
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsReturningHardware(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                    <Typography variant="h5">My Athlete Account</Typography>
                </Grid>
                <Grid item xs={12} lg={12} style={{ marginBottom: 24 }}>
                    <Button
                        variant="outlined"
                        size="small"
                        style={{ marginRight: 4 }}
                        onClick={handleChangeBillingMethod}
                    >
                        <CreditCard fontSize="small" style={{ marginRight: 2 }}></CreditCard>Manage
                    </Button>
                    {/* This may be used in the future if we can figure out how to accurately issue commissions from the previously unpaid invoices */}
                    {/*<Button*/}
                    {/*    variant="outlined"*/}
                    {/*    size="small"*/}
                    {/*    style={{ marginRight: 4 }}*/}
                    {/*    onClick={handleConsolidateOpenInvoices}*/}
                    {/*>*/}
                    {/*    <RequestPageIcon*/}
                    {/*        fontSize="small"*/}
                    {/*        style={{ marginRight: 2 }}*/}
                    {/*    ></RequestPageIcon>{' '}*/}
                    {/*    Pay Open Invoices*/}
                    {/*</Button>*/}
                    <Button
                        variant="outlined"
                        size="small"
                        style={{ marginRight: 4 }}
                        onClick={() => {
                            setIsReturningHardware(true);
                        }}
                    >
                        <SpeakerPhone fontSize="small" style={{ marginRight: 2 }}></SpeakerPhone>{' '}
                        Return Hardware
                    </Button>
                </Grid>
            </Grid>

            {/* Side-by-side layout for the data grids */}
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <Typography variant="h6" sx={{ mb: 3 }}>
                        My Invoices
                    </Typography>
                    <form onSubmit={handleSearch}>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                mb: 3,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel id="invoice-status-label">Invoice Status</InputLabel>
                                <Select
                                    labelId="invoice-status-label"
                                    id="invoice-status-select"
                                    value={formStatus}
                                    label="Invoice Status"
                                    onChange={handleStatusChange}
                                    size="small"
                                >
                                    <MenuItem value="all">All Invoices</MenuItem>
                                    <MenuItem value="paid">Paid</MenuItem>
                                    <MenuItem value="open">Open</MenuItem>
                                    <MenuItem value="past_due">Past Due</MenuItem>
                                    <MenuItem value="void">Void</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                label="Created Start Date"
                                type="date"
                                value={formStartDate}
                                onChange={(e) => {
                                    setFormStartDate(e.target.value || null);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 160 }}
                            />

                            <TextField
                                label="Created End Date"
                                type="date"
                                value={formEndDate}
                                onChange={(e) => {
                                    setFormEndDate(e.target.value || null);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 160 }}
                            />

                            <Button type="submit" variant="contained" color="primary">
                                Search
                            </Button>
                        </Stack>
                    </form>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={invoices}
                            columns={invoiceColumns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={(newModel) => {
                                if (!hasMore && newModel.page > paginationModel.page) {
                                    return;
                                }
                                setPaginationModel(newModel);
                            }}
                            pagination
                            paginationMode="server"
                            rowCount={-1}
                            loading={invoicesLoading}
                            pageSizeOptions={[5, 10, 25, 50]}
                            disableRowSelectionOnClick
                            getRowId={(row) => row.id}
                            slots={{
                                loadingOverlay: CustomLoadingOverlay,
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} lg={4}>
                    <Typography variant="h6" sx={{ mb: 3, marginTop: 23 }}>
                        My Organizations
                    </Typography>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={organizations}
                            columns={organizationColumns}
                            getRowId={(row) => row.uuid}
                            disableRowSelectionOnClick
                            pageSizeOptions={[5, 10, 25]}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 5, page: 0 },
                                },
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AthleteHome;
