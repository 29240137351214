import Request from 'api/Request';
import { AxiosResponse } from 'axios';

interface Invoice {
    id: string;
    number: string;
    status: string;
    amount_due: number;
    amount_paid: number;
    billing_reason: string;
    total: number;
    amount_remaining: number;
    created: number;
    due_date: number;
    customer_email: string;
    customer_name: string;
    metadata: {
        consolidated_payment?: 'true' | 'false';
    };
    period_start: number;
    period_end: number;
    hosted_invoice_url?: string;
    invoice_pdf?: string;
    subscription_details: {
        metadata?: {
            name?: string;
        };
    };
}

interface PaginatedApiResponse {
    data: Array<Invoice>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

type ReturnTypeCollection = Promise<AxiosResponse<PaginatedApiResponse>>;

const getInvoicesForOrganization = (
    organization: string,
    perPage: number,
    page: number,
    status: 'all' | 'paid' | 'open' | 'past_due',
    startDate?: string | null,
    endDate?: string | null,
): ReturnTypeCollection => {
    return Request.get(`/organizations/${organization}/invoices`, {
        params: {
            per_page: perPage,
            page,
            status,
            start_date: startDate,
            end_date: endDate,
        },
    });
};

interface OrgWithPastDueInvoice {
    uuid: string;
    name: string;
}

interface OrgsWithPastDueInvoices {
    organizations: OrgWithPastDueInvoice[];
}

type ReturnTypePastDueInvoices = Promise<AxiosResponse<OrgsWithPastDueInvoices>>;

const getPastDueInvoicesForOrganizations = (): ReturnTypePastDueInvoices => {
    return Request.get(`/organizations/past-due-invoices`);
};

export { getInvoicesForOrganization, getPastDueInvoicesForOrganizations };
export type { Invoice, OrgWithPastDueInvoice };
