import { Box, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ButtonWithLoader, CustomButton } from 'components/Buttons';
import { Form, Formik, useFormikContext } from 'formik';
import React, { ReactNode, useEffect, useRef } from 'react';

interface StepperProps {
    steps: Array<{
        label: string;
        onNext?: Function;
        onSkip?: Function;
        disabled?: boolean;
        optional: boolean;
        loading?: boolean;
        validationSchema?: any;
        content: React.ReactNode;
    }>;
    stepperClassName?: string;
    initialValues?: any;
    showOptional?: boolean;
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    skipped: Set<number>;
    setSkipped: React.Dispatch<React.SetStateAction<Set<number>>>;
    stepperTitle?: string;
    isFinishButtonDisabled?: () => boolean;
    selectionType?: 'existing' | 'new';
    selectedExistingOrganization?: any;
}

const FormikObserver: React.FC = () => {
    const { validateForm } = useFormikContext();

    useEffect(() => {
        validateForm();
    }, [validateForm]);

    return null;
};

const CustomStepper = ({
    steps,
    skipped,
    activeStep,
    setSkipped,
    showOptional,
    setActiveStep,
    initialValues,
    stepperTitle,
    isFinishButtonDisabled,
    selectionType,
    selectedExistingOrganization,
}: StepperProps) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const isProcessingRef = useRef(false);

    const isStepOptional = (step: number) => !!steps[step] && steps[step].optional;
    const isNextDisabled = (step: number) => !!steps[step] && steps[step].disabled;
    const isStepSkipped = (step: number) => skipped.has(step);

    const onNextOrSkip = async (values: any) => {
        if (isProcessingRef.current) return;
        isProcessingRef.current = true;

        try {
            let response = true;
            const currentStep = steps[activeStep];

            if (
                currentStep?.onNext &&
                !(isStepOptional(activeStep) && isNextDisabled(activeStep))
            ) {
                response = await currentStep.onNext(values);
            } else if (currentStep?.onSkip) {
                response = await currentStep.onSkip(values);
            }

            if (response) {
                if (currentStep.optional) {
                    handleSkip();
                } else {
                    const nextStep = getNextStep(activeStep, 1);
                    setActiveStep(nextStep);
                }
            }
        } finally {
            isProcessingRef.current = false;
        }
    };

    const handleBack = () => {
        if (activeStep === 3) {
            // This is a hack - the 'Personal' step is index 3 but going backwards takes you to login form without this.
            setActiveStep(1);
        } else {
            const previousStep = getNextStep(activeStep, -1);
            setActiveStep(previousStep);
        }
    };

    const handleSkip = async () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const getNextStep = (currentStep: number, direction: 1 | -1) => {
        let nextStep = currentStep + direction;
        while (
            nextStep >= 0 &&
            nextStep < steps.length &&
            (skipped.has(nextStep) || steps[nextStep].disabled)
        ) {
            nextStep += direction;
        }
        return nextStep >= 0 && nextStep < steps.length ? nextStep : currentStep;
    };

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    height: '60px',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    padding: '20px',
                }}
            >
                <Stepper
                    activeStep={activeStep}
                    sx={{
                        padding: 0,
                        width: {
                            xs: '100%',
                            md: 'auto',
                        },
                    }}
                >
                    {steps.map(({ label }, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: ReactNode } = {};
                        if (isStepOptional(index) && showOptional) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{!matches && label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {stepperTitle && (
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: 'center',
                            marginTop: theme.spacing(4),
                            marginBottom: theme.spacing(4),
                        }}
                    >
                        {stepperTitle}
                    </Typography>
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    flex: 1,
                    paddingTop: '60px',
                    paddingBottom: '100px',
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={steps[activeStep].validationSchema}
                    onSubmit={onNextOrSkip}
                    validateOnMount={true}
                >
                    {({ isValid }) => (
                        <Form
                            style={{
                                flex: 1,
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <FormikObserver />
                            {steps[activeStep].content}
                            <Box
                                sx={{
                                    display: 'flex',
                                    marginTop: theme.spacing(10),
                                    padding: theme.spacing(12, 24),
                                    justifyContent: 'space-between',
                                    borderTop: '1px solid #EBEBEB',
                                    position: 'fixed',
                                    bottom: 0,
                                    width: '100%',
                                    backgroundColor: 'white',
                                    zIndex: 1000,
                                    [theme.breakpoints.down('md')]: {
                                        padding: theme.spacing(8),
                                    },
                                }}
                            >
                                <CustomButton
                                    size="large"
                                    variant="outlined"
                                    onClick={handleBack}
                                    disabled={activeStep === 0 || steps[activeStep].loading}
                                    sx={{
                                        minWidth: 160,
                                        marginRight: theme.spacing(1),
                                        [theme.breakpoints.down('md')]: {
                                            minWidth: 120,
                                        },
                                    }}
                                >
                                    Back
                                </CustomButton>
                                {isStepOptional(activeStep) && isNextDisabled(activeStep) ? (
                                    <ButtonWithLoader
                                        color="primary"
                                        variant="outlined"
                                        type="submit"
                                        sx={{
                                            minWidth: 160,
                                            marginRight: theme.spacing(1),
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: 120,
                                            },
                                        }}
                                        loading={steps[activeStep].loading}
                                        disabled={steps[activeStep].loading}
                                    >
                                        Skip
                                    </ButtonWithLoader>
                                ) : (
                                    <ButtonWithLoader
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            minWidth: 160,
                                            marginRight: theme.spacing(1),
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: 120,
                                            },
                                        }}
                                        disabled={
                                            !isValid ||
                                            steps[activeStep].loading ||
                                            // Only check for organization selection when in "existing" selection mode
                                            (activeStep === 4 &&
                                                selectionType === 'existing' &&
                                                !selectedExistingOrganization) ||
                                            (activeStep === steps.length - 1 &&
                                                isFinishButtonDisabled &&
                                                isFinishButtonDisabled())
                                        }
                                        loading={steps[activeStep].loading}
                                    >
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Continue'}
                                    </ButtonWithLoader>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default CustomStepper;
