import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Button, Popover } from '@mui/material';
import React from 'react';
import ChooseOrganizationPlanRadioGroup from '../Inputs/ChooseOrganizationPlanRadioGroup';
//import bronzeMedal from '../../images/bronze-medal.png';
//import silverMedal from '../../images/silver-medal.png';
import goldMedal from '../../images/gold-medal (1).png';
import createCheckoutSession from '../../api/Auth/createCheckoutSession';

interface ChooseOrganizationPlanPopoverProps {
    organization: string;
    buttonText: string;
}

export default function ChooseOrganizationPlanPopover({
    organization,
    buttonText,
}: ChooseOrganizationPlanPopoverProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selectedPlan, setSelectedPlan] = React.useState<string>('');
    const [checkingOut, setCheckingOut] = React.useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePurchase = () => {
        setCheckingOut(true);
        createCheckoutSession(window.location.href, organization, selectedPlan)
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch(() => {
                setCheckingOut(false);
            });
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button onClick={handleClick} aria-describedby={id} variant="contained">
                <CreditCardIcon sx={{ mr: 4 }} />
                {buttonText}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{ '& .MuiPopover-paper': { padding: 8 } }}
            >
                <ChooseOrganizationPlanRadioGroup
                    initialValue={''}
                    onSelected={(value) => {
                        setSelectedPlan(value);
                    }}
                    options={[
                        // {
                        //     image: bronzeMedal,
                        //     label: 'Bronze',
                        //     value: process.env
                        //         .REACT_APP_STRIPE_ORGANIZATION_LEVEL_1_PRICE as string,
                        //     description: 'GPS csv upload, 1080 API, Train, Community, Plan',
                        //     price: '$25',
                        // },
                        // {
                        //     image: silverMedal,
                        //     label: 'Silver',
                        //     value: process.env
                        //         .REACT_APP_STRIPE_ORGANIZATION_LEVEL_2_PRICE as string,
                        //     description: (
                        //         <span>
                        //             🚀 GPS API, 1080 API, Train, Community, Plan,{' '}
                        //             <strong>Marketplace-Local, 🔥FiyrApp</strong>
                        //         </span>
                        //     ),
                        //     price: '$50',
                        // },
                        {
                            image: goldMedal,
                            label: 'Gold',
                            value: process.env
                                .REACT_APP_STRIPE_ORGANIZATION_LEVEL_3_PRICE as string,
                            description: (
                                <span>
                                    🚀 GPS API, 1080 API, Train, Community, Plan,{' '}
                                    <strong>
                                        Marketplace-Global, 🤖Unlimited Video AI, Learn, Explore,
                                        🔥FiyrApp
                                    </strong>
                                </span>
                            ),
                            price: '$100',
                        },
                    ]}
                />
                <Button
                    onClick={handlePurchase}
                    aria-describedby={id}
                    disabled={!selectedPlan || checkingOut}
                    variant="contained"
                >
                    Purchase
                </Button>
            </Popover>
        </>
    );
}
