import { OpenInNew } from '@mui/icons-material';
import { Box, Button, Container, Snackbar, Stack, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { GridColDef } from '@mui/x-data-grid';
import {
    DataGridPremium,
    GridRenderCellParams,
    GridSortItem,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { DateTime } from 'luxon';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createStripeLoginLink } from '../../../api/organizations.api';
import { formatCurrency, formatDateStringToLocale } from '../../../app.functions';
import CustomDateRangePicker from '../../../components/FormControl/CustomDateRangePicker';
import CustomPagination from '../../../components/Functional/CustomPagination';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import PartnershipEarningsAnalytics from '../components/PartnershipEarningsAnalytics';
import { NetworkingActionTypes } from '../state/networking.state';
import {
    useDispatchContext,
    useNetworkingOrganization,
    useNetworkingPayouts,
} from '../state/NetworkingContext';

const columns: GridColDef[] = [
    {
        field: 'payout_date',
        headerName: 'Payout Date',
        width: 250,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<any, string>) => {
            return formatDateStringToLocale(params.value);
        },
    },
    {
        field: 'amount',
        headerName: 'Amount',
        width: 200,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<any, string>) => {
            return formatCurrency({
                amount: ((params.value ?? 0) as number) / 100,
                currency: 'USD',
            });
        },
    },
];
const PartnershipPayoutsScene = () => {
    const payouts = useNetworkingPayouts();
    const organization = useNetworkingOrganization();
    const dispatch = useDispatchContext();
    const { id } = useParams<{ id: string }>();
    const { push } = useHistory();

    const handleSortModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            dispatch({
                type: NetworkingActionTypes.PAYOUTS_FILTERS_CHANGED,
                payload: {
                    page: 1,
                    sort: sortModel.map((item: GridSortItem) => {
                        if (item.sort === 'asc') {
                            return `${item.field}`;
                        }
                        return `-${item.field}`;
                    }),
                },
            });
        },
        [dispatch],
    );

    return (
        <Container
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
            }}
            maxWidth={'xl'}
        >
            <Stack
                direction={'column'}
                spacing={4}
                sx={{
                    py: 4,
                }}
            >
                <PartnershipEarningsAnalytics />
                <h2>Partnership Payouts List</h2>
                <Stack direction={'row'} spacing={2}>
                    <CustomDateRangePicker
                        onChange={(e: DateRange<DateTime>) => {
                            if (e[0] && e[1]) {
                                dispatch({
                                    type: NetworkingActionTypes.PAYOUTS_FILTERS_CHANGED,
                                    payload: {
                                        page: 1,
                                        'filter[paid_after]': e[0].toFormat('yyyy-MM-dd'),
                                        'filter[paid_before]': e[1].toFormat('yyyy-MM-dd'),
                                    },
                                });
                            }
                        }}
                        value={[
                            payouts.filters['filter[paid_after]']
                                ? DateTime.fromFormat(
                                      payouts.filters['filter[paid_after]'],
                                      'yyyy-MM-dd',
                                  )
                                : null,
                            payouts.filters['filter[paid_before]']
                                ? DateTime.fromFormat(
                                      payouts.filters['filter[paid_before]'],
                                      'yyyy-MM-dd',
                                  )
                                : null,
                        ]}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<OpenInNew />}
                        onClick={async () => {
                            try {
                                const response = await createStripeLoginLink(
                                    organization.data?.uuid ?? '',
                                );
                                window.location.href = response.data.url;
                            } catch (e) {
                                console.error(e);
                            }
                        }}
                    >
                        View Bank And Transfer Details
                    </Button>
                </Stack>
            </Stack>
            <Box
                sx={{
                    flex: 1,
                    width: '100%',
                }}
            >
                <DataGridPremium
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                            backgroundColor: blue[100],
                        },
                    }}
                    loading={payouts.loading}
                    rows={payouts.data}
                    columns={columns}
                    sortingMode={'server'}
                    onSortModelChange={handleSortModelChange}
                    slots={{
                        loadingOverlay: CustomLoadingOverlay,
                        footer: CustomPagination,
                        noRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                <Typography variant="h6">No Payouts Yet</Typography>
                            </Stack>
                        ),
                        noResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                No Results When Filter Applied
                            </Stack>
                        ),
                    }}
                    onRowClick={(row) => {
                        push(`/networking/${id}/payouts/${row.id}`);
                    }}
                    getRowId={(row) => row.uuid}
                    slotProps={{
                        footer: {
                            hasNext: payouts.pagination.has_next,
                            hasPrevious: payouts.pagination.has_prev,
                            onNext: () => {
                                dispatch({
                                    type: NetworkingActionTypes.PAYOUTS_FILTERS_CHANGED,
                                    payload: {
                                        page: payouts.pagination.current_page + 1,
                                    },
                                });
                            },
                            onPrevious: () => {
                                dispatch({
                                    type: NetworkingActionTypes.PAYOUTS_FILTERS_CHANGED,
                                    payload: {
                                        page: payouts.pagination.current_page - 1,
                                    },
                                });
                            },
                            page: payouts.pagination.current_page,
                        },
                    }}
                />
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={Boolean(payouts.errorMessage)}
                message={payouts.errorMessage}
                onClose={() => {
                    dispatch({
                        type: NetworkingActionTypes.PAYOUTS_ERROR_MESSAGE_CLEARED,
                    });
                }}
                autoHideDuration={3000}
            />
        </Container>
    );
};
export default PartnershipPayoutsScene;
