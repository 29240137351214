import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    debounce,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField as MUITextField,
    InputAdornment,
    IconButton,
    Divider,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataGridPremium, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { GridColDef } from '@mui/x-data-grid-premium';
import CommissionDialog from '../components/CommissionDialog';
import { Organization, UserData } from 'utils';
import { adminGetAllOrganizations } from '../../../admin.organizations.api';
import { Commission } from '../../../../networking/networking.types';
import {
    adminCreateNewCommission,
    getCommissionBonusTypesForAdmin,
    getCommissionsForAdmins,
    getCommissionTypesForAdmin,
} from '../commissions.api';
import { GetCommissionsParams, CreateCommissionParams } from '../commissions.api';
import { adminGetAllUsers } from '../../../admin.users.api';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { CommissionBonusType } from '../commissions.types';

const ResponsiveFormItem = styled(Box)(({ theme }) => ({
    width: 'calc(15% - 16px)',
    maxWidth: '300px',
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 16px)',
        maxWidth: 'none',
    },
}));

const ResponsiveForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    margin: theme.spacing(-1),
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
}));

const CommissionList = () => {
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('md'));
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [otherOrganizations, setOtherOrganizations] = useState<Organization[]>([]);
    const [resellerOrganizations, setResellerOrganizations] = useState<Organization[]>([]);
    const [athleteUsers, setAthleteUsers] = useState<UserData[]>([]);
    const [commissions, setCommissions] = useState<Commission[]>([]);
    const [selectedCommission, setSelectedCommission] = useState<Commission | null>(null);
    const [searchParams, setSearchParams] = useState<GetCommissionsParams>({
        type: '',
        earned_date: '',
        is_paid: undefined,
        payout_uuid: '',
        athlete_user_uuid: '',
        'partner.organization': '',
        'otherPartner.organization': '',
        'sellerPartner.organization': '',
        sort: '-earned_date',
        page: 1,
        per_page: 10,
    });

    const [totalCommissions, setTotalCommissions] = useState(0);
    const [commissionTypes, setCommissionTypes] = useState<string[]>([]);
    const [commissionBonusTypes, setCommissionBonusTypes] = useState<CommissionBonusType[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedCommissionType, setSelectedCommissionType] = useState<string>('all');
    const [selectedPayoutStatus, setSelectedPayoutStatus] = useState<string>('all');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [newCommissionAmount, setNewCommissionAmount] = useState<string>('');
    const [newCommission, setNewCommission] = useState<CreateCommissionParams>({
        organization_uuid: '',
        other_organization_uuid: null,
        athlete_user_uuid: null,
        type: '',
        bonus_type_uuid: null,
        amount: 0,
        earned_date: null,
        notes: '',
    });
    const [createOrganizations, setCreateOrganizations] = useState<Organization[]>([]);
    const [createOtherOrganizations, setCreateOtherOrganizations] = useState<Organization[]>([]);
    const [createAthleteUsers, setCreateAthleteUsers] = useState<UserData[]>([]);
    const [createOrganization, setCreateOrganization] = useState<Organization | null>(null);
    const [createOtherOrganization, setCreateOtherOrganization] = useState<Organization | null>(
        null,
    );
    const [createAthleteUser, setCreateAthleteUser] = useState<UserData | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const handleOpenCreateDialog = () => {
        setIsCreateDialogOpen(true);
    };

    const handleCloseCreateDialog = () => {
        setIsCreateDialogOpen(false);
        clearFormData();
    };

    const clearFormData = () => {
        setNewCommissionAmount('');
        setCreateOrganization(null);
        setCreateOtherOrganization(null);
        setCreateAthleteUser(null);
        setNewCommission({
            organization_uuid: '',
            other_organization_uuid: null,
            athlete_user_uuid: null,
            type: '',
            bonus_type_uuid: null,
            amount: 0,
            earned_date: null,
            notes: '',
        });
        setIsFormSubmitted(false);
    };

    const validateCreateCommissionInput = (): string | null => {
        if (!newCommission.organization_uuid) {
            return 'Organization is required.';
        }
        if (!newCommission.type) {
            return 'Commission type is required.';
        }
        if (newCommissionAmount === '') {
            return 'Commission amount is required.';
        }
        if (!newCommission.earned_date) {
            return 'Earned date is required.';
        }
        if (!newCommission.other_organization_uuid && !newCommission.athlete_user_uuid) {
            return 'Either Other Organization or Athlete User must be selected.';
        }
        return null;
    };

    const handleCreateCommission = () => {
        setIsFormSubmitted(true);
        const validationError = validateCreateCommissionInput();

        if (validationError) {
            setSnackbarMessage(validationError);
            setSnackbarOpen(true);
            return;
        }

        adminCreateNewCommission(newCommission)
            .then((response) => {
                console.log('Creating commission', newCommission, response);
                handleCloseCreateDialog();
                setSnackbarMessage('Commission created successfully');
                setSnackbarOpen(true);
                clearFormData();
                getCommissionsForAdmins()
                    .then((response) => {
                        setCommissions(response.data.data);
                        setTotalCommissions(response.data.meta.total);
                    })
                    .catch((error) => {
                        console.error('Failed to fetch commissions', error);
                    });
            })
            .catch((error) => {
                setSnackbarMessage(`Failed to create commission: ${error.response.data.message}.`);
                setSnackbarOpen(true);
                console.error('Failed to create commission', error);
            });
    };

    const fetchCreateOrganizations = debounce((searchTerm: string, perPage?: number) => {
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setCreateOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    const fetchCreateOtherOrganizations = debounce((searchTerm: string, perPage?: number) => {
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setCreateOtherOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    const fetchCreateAthleteUsers = debounce((searchTerm: string, perPage?: number) => {
        adminGetAllUsers(searchTerm, perPage)
            .then((response) => {
                setCreateAthleteUsers(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch athlete users', error);
            });
    }, 300);

    useEffect(() => {
        getCommissionsForAdmins()
            .then((response) => {
                setCommissions(response.data.data);
                setTotalCommissions(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch commissions', error);
            });

        adminGetAllOrganizations('').then((response) => {
            setOrganizations(response.data.data);
        });

        adminGetAllOrganizations('').then((response) => {
            setOtherOrganizations(response.data.data);
        });

        adminGetAllUsers('').then((response) => {
            setAthleteUsers(response.data.data);
        });

        getCommissionTypesForAdmin().then((response) => {
            setCommissionTypes(response.data);
        });

        getCommissionBonusTypesForAdmin().then((response) => {
            setCommissionBonusTypes(response.data);
        });
    }, []);

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
        setSearchParams({
            ...searchParams,
            page: newModel.page + 1,
            per_page: newModel.pageSize,
        });
        getCommissionsForAdmins({
            ...searchParams,
            page: newModel.page + 1,
            per_page: newModel.pageSize,
        })
            .then((response) => {
                setCommissions(response.data.data);
                setTotalCommissions(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch commissions', error);
            });
    };

    const fetchOrganizations = debounce((searchTerm: string, perPage?: number) => {
        // Replace this with your actual API call
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    const fetchOtherOrganizations = debounce((searchTerm: string, perPage?: number) => {
        // Replace this with your actual API call
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setOtherOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    const fetchResellerOrganizations = debounce((searchTerm: string, perPage?: number) => {
        // Replace this with your actual API call
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setResellerOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch reseller organizations', error);
            });
    }, 300);

    const fetchAthleteUsers = debounce((searchTerm: string, perPage?: number) => {
        // Replace this with your actual API call
        adminGetAllUsers(searchTerm, perPage)
            .then((response) => {
                setAthleteUsers(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    const formatCentsToDollars = (cents: number): string => {
        const dollars = cents / 100;
        return `$${dollars.toFixed(2)}`;
    };

    const columns: GridColDef<Commission, any, any>[] = [
        {
            field: 'partner.organization',
            headerName: 'Organization',
            width: 250,
            sortable: false,
            type: 'string',
            renderCell: (params) => {
                return params.row.partner?.organization.name;
            },
        },
        {
            field: 'otherPartner.organization',
            headerName: 'Other Organization',
            width: 250,
            sortable: false,
            type: 'string',
            renderCell: (params) => {
                return params.row.other_partner?.organization.name;
            },
        },
        {
            field: 'sellerPartner',
            headerName: 'Reseller',
            minWidth: 250,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (_: any, row: any) => {
                return row.seller_partner?.organization?.name ?? '-';
            },
        },
        {
            field: 'athlete_user',
            headerName: 'Athlete User',
            width: 250,
            sortable: false,
            type: 'string',
            renderCell: (params) => {
                return params.row.athlete_user?.name;
            },
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 300,
            sortable: false,
            type: 'string',
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 150,
            sortable: true,
            type: 'number',
            valueFormatter: (value) => formatCentsToDollars(value),
        },
        {
            field: 'earned_date',
            headerName: 'Earned Date',
            sortable: true,
            width: 200,
            valueFormatter: (value: any) => {
                if (value) {
                    return new Date(value).toISOString().split('T')[0];
                }
                return '';
            },
        },
        {
            field: 'is_paid',
            headerName: 'Status',
            sortable: false,
            width: 120,
            renderCell: (params) =>
                params.row.payout?.transaction_id ? (
                    <Button
                        variant="outlined"
                        size="small"
                        href={`https://dashboard.stripe.com/transfers/${params.row.payout.transaction_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        PAID
                    </Button>
                ) : (
                    <span style={{ color: 'red' }}>UNPAID</span>
                ),
        },
        {
            field: 'actions',
            headerName: '',
            sortable: false,
            width: 250,
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'left',
                    }}
                >
                    <Button
                        variant="outlined"
                        size="small"
                        href={`https://dashboard.stripe.com/${
                            params.row.sale_id?.includes('pi_') ? 'payments' : 'invoices'
                        }/${params.row.sale_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        SALE
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleOpenDialog(params.row)}
                    >
                        {params.row.is_paid ? 'VIEW' : 'EDIT'}
                    </Button>
                </Box>
            ),
        },
    ];

    const handleCommissionSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await getCommissionSearchResults(searchParams);
        } catch (error) {
            console.error(error);
        }
    };

    const getCommissionSearchResults = async (params: any) => {
        const response = await getCommissionsForAdmins(params);
        if (Array.isArray(response.data.data)) {
            const updatedCommissions = response.data.data.map((commission: Commission) => {
                return {
                    ...commission,
                };
            });
            setCommissions(updatedCommissions);
            setTotalCommissions(response.data.meta.total);
        } else {
            console.error('Unexpected data format: commission data is not an array.');
        }
    };

    const handleCommissionSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        /**
         * Sort will be undefined, or it will be ascending, ie "createdAt", or in this case
         * it will be descending, ie "-createdAt". If descending, we prepend a hyphen.
         */
        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        // Update your API request with the new sort model and fetch the data again
        const params = {
            ...searchParams,
            sort: sortField as GetCommissionsParams['sort'],
        };

        await getCommissionSearchResults(params);
    };

    const handleOpenDialog = (commission: Commission) => {
        setSelectedCommission(commission);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleSaveOrder = async () => {
        handleCloseDialog();
        await getCommissionSearchResults(searchParams);
    };

    const handleNewCommissionAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Allow negative numbers, numbers, and a single decimal point
        if (/^-?\d*\.?\d*$/.test(value)) {
            setNewCommissionAmount(value);
            const amountInCents = Math.round(parseFloat(value) * 100);
            setNewCommission({ ...newCommission, amount: amountInCents });
        }
    };

    return (
        <>
            <Box style={{ marginLeft: '2rem' }}>
                <h2>Commissions</h2>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenCreateDialog}
                    style={{ marginBottom: '1rem' }}
                >
                    Create New Commission
                </Button>
                <section style={{ marginBottom: '2rem' }}>
                    <h4>Search Commissions</h4>
                    <ResponsiveForm onSubmit={handleCommissionSearch} noValidate>
                        <ResponsiveFormItem>
                            <Autocomplete
                                value={
                                    organizations.find(
                                        (org) => org.uuid === searchParams['partner.organization'],
                                    ) || null
                                }
                                onChange={(event, newValue) => {
                                    setSearchParams({
                                        ...searchParams,
                                        'partner.organization': newValue ? newValue.uuid : '',
                                    });
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason !== 'reset') {
                                        fetchOrganizations(newInputValue);
                                    }
                                }}
                                getOptionKey={(option) => option.uuid}
                                filterOptions={(options) => options}
                                size={'small'}
                                fullWidth
                                options={organizations}
                                getOptionLabel={(option) =>
                                    `${option.name} (${option.subscription_tier?.name}) - ${option.uuid}`
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="Organizations" />
                                )}
                            />
                        </ResponsiveFormItem>
                        <ResponsiveFormItem>
                            <Autocomplete
                                value={
                                    otherOrganizations.find(
                                        (org) =>
                                            org.uuid === searchParams['otherPartner.organization'],
                                    ) || null
                                }
                                onChange={(event, newValue) => {
                                    setSearchParams({
                                        ...searchParams,
                                        'otherPartner.organization': newValue ? newValue.uuid : '',
                                    });
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason !== 'reset') {
                                        fetchOtherOrganizations(newInputValue);
                                    }
                                }}
                                getOptionKey={(option) => option.uuid}
                                filterOptions={(options) => options}
                                size={'small'}
                                fullWidth
                                options={otherOrganizations}
                                getOptionLabel={(option) =>
                                    `${option.name} (${option.subscription_tier?.name}) - ${option.uuid}`
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="Other Organizations" />
                                )}
                                disabled={!!createAthleteUser}
                            />
                        </ResponsiveFormItem>
                        <ResponsiveFormItem>
                            <Autocomplete
                                value={
                                    athleteUsers.find(
                                        (athleteUser) =>
                                            athleteUser.uuid === searchParams.athlete_user_uuid,
                                    ) || null
                                }
                                onChange={(event, newValue) => {
                                    setSearchParams({
                                        ...searchParams,
                                        athlete_user_uuid: newValue ? newValue.uuid : '',
                                    });
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason !== 'reset') {
                                        fetchAthleteUsers(newInputValue);
                                    }
                                }}
                                getOptionKey={(option) => option.uuid}
                                filterOptions={(options) => options}
                                size={'small'}
                                fullWidth
                                options={athleteUsers}
                                getOptionLabel={(option) =>
                                    `${option.first_name} ${option.last_name} (${option.user_name})`
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="Athlete Users" />
                                )}
                                disabled={!!createOtherOrganization}
                            />
                        </ResponsiveFormItem>
                        <ResponsiveFormItem>
                            <Autocomplete
                                value={
                                    resellerOrganizations.find(
                                        (org) =>
                                            org.uuid === searchParams['sellerPartner.organization'],
                                    ) || null
                                }
                                onChange={(event, newValue) => {
                                    setSearchParams({
                                        ...searchParams,
                                        'sellerPartner.organization': newValue ? newValue.uuid : '',
                                    });
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason !== 'reset') {
                                        fetchResellerOrganizations(newInputValue);
                                    }
                                }}
                                getOptionKey={(option) => option.uuid}
                                filterOptions={(options) => options}
                                size={'small'}
                                fullWidth
                                options={resellerOrganizations}
                                getOptionLabel={(option) =>
                                    `${option.name} (${option.subscription_tier?.name}) - ${option.uuid}`
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="Reseller Organizations" />
                                )}
                            />
                        </ResponsiveFormItem>
                        <ResponsiveFormItem>
                            <FormControl fullWidth size="small">
                                <InputLabel id="filter-by-type">Type</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="filter-by-type"
                                    label="Type"
                                    value={selectedCommissionType}
                                    onChange={(event: SelectChangeEvent<string>) => {
                                        const value = event.target.value;
                                        setSelectedCommissionType(value);
                                        setSearchParams({
                                            ...searchParams,
                                            type: value === 'all' ? '' : value,
                                        });
                                    }}
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    {commissionTypes.map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </ResponsiveFormItem>
                        <ResponsiveFormItem>
                            <FormControl fullWidth size="small">
                                <InputLabel id="filter-by-payout-status">Payout Status</InputLabel>
                                <Select
                                    labelId="filter-by-payout-status"
                                    label="Payout Status"
                                    value={selectedPayoutStatus}
                                    onChange={(event: SelectChangeEvent<string>) => {
                                        const value = event.target.value;
                                        setSelectedPayoutStatus(value);
                                        setSearchParams({
                                            ...searchParams,
                                            is_paid: value === 'all' ? undefined : Number(value),
                                        });
                                    }}
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="0">Unpaid</MenuItem>
                                    <MenuItem value="1">Paid</MenuItem>
                                </Select>
                            </FormControl>
                        </ResponsiveFormItem>
                        <ResponsiveFormItem>
                            <Button
                                type="submit"
                                size={'medium'}
                                variant="contained"
                                fullWidth={isNarrow}
                            >
                                Search
                            </Button>
                        </ResponsiveFormItem>
                    </ResponsiveForm>
                </section>
                <Box width="98%" maxHeight="600px" overflow="auto" className="dataGridContainer">
                    <DataGridPremium
                        rows={commissions}
                        columns={columns}
                        checkboxSelection={false}
                        sortingMode="server"
                        onSortModelChange={(model: GridSortModel) => handleCommissionSort(model)}
                        getRowId={(row) => row.uuid}
                        style={{ height: '624px', marginBottom: '50px' }}
                        pagination
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        paginationModel={paginationModel}
                        disableColumnMenu={true}
                        onPaginationModelChange={handlePaginationModelChange}
                        rowCount={totalCommissions}
                        paginationMode="server"
                        sx={{
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                        }}
                    />
                </Box>
                <CommissionDialog
                    open={isDialogOpen}
                    commission={selectedCommission}
                    onClose={handleCloseDialog}
                    onSave={handleSaveOrder}
                />
            </Box>
            <Dialog
                open={isCreateDialogOpen}
                maxWidth="md"
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloseCreateDialog();
                    }
                }}
            >
                <DialogTitle>Create New Commission</DialogTitle>
                <Divider sx={{ my: 2 }} />
                <DialogContent>
                    <Autocomplete
                        value={createOrganization}
                        onChange={(event, newValue) => {
                            setCreateOrganization(newValue);
                            setNewCommission({
                                ...newCommission,
                                organization_uuid: newValue?.uuid || '',
                            });
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                            if (reason !== 'reset') {
                                fetchCreateOrganizations(newInputValue);
                            }
                        }}
                        getOptionKey={(option) => option.uuid}
                        filterOptions={(options) => options}
                        options={createOrganizations}
                        getOptionLabel={(option) =>
                            `${option.name} (${option.subscription_tier?.name}) - ${option.uuid}`
                        }
                        renderInput={(params) => (
                            <MUITextField
                                {...params}
                                label="Organization"
                                fullWidth
                                margin="normal"
                                required
                                error={!newCommission.organization_uuid && isFormSubmitted}
                            />
                        )}
                        size="small"
                    />
                    <Autocomplete
                        value={createOtherOrganization}
                        onChange={(event, newValue) => {
                            setCreateOtherOrganization(newValue);
                            setNewCommission({
                                ...newCommission,
                                other_organization_uuid: newValue?.uuid || null,
                            });
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                            if (reason !== 'reset') {
                                fetchCreateOtherOrganizations(newInputValue);
                            }
                        }}
                        getOptionKey={(option) => option.uuid}
                        filterOptions={(options) => options}
                        options={createOtherOrganizations}
                        getOptionLabel={(option) =>
                            `${option.name} (${option.subscription_tier?.name}) - ${option.uuid}`
                        }
                        renderInput={(params) => (
                            <MUITextField
                                {...params}
                                label="Other Organization"
                                fullWidth
                                margin="normal"
                            />
                        )}
                        size="small"
                        disabled={!!createAthleteUser}
                    />
                    <Autocomplete
                        value={createAthleteUser}
                        onChange={(event, newValue) => {
                            setCreateAthleteUser(newValue);
                            setNewCommission({
                                ...newCommission,
                                athlete_user_uuid: newValue?.uuid || null,
                            });
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                            if (reason !== 'reset') {
                                fetchCreateAthleteUsers(newInputValue);
                            }
                        }}
                        getOptionKey={(option) => option.uuid}
                        filterOptions={(options) => options}
                        options={createAthleteUsers}
                        getOptionLabel={(option) =>
                            `${option.first_name} ${option.last_name} (${option.user_name})`
                        }
                        renderInput={(params) => (
                            <MUITextField
                                {...params}
                                label="Athlete User"
                                fullWidth
                                margin="normal"
                            />
                        )}
                        size="small"
                        disabled={!!createOtherOrganization}
                    />
                    <MUITextField
                        select
                        label="Type"
                        value={newCommission.type}
                        onChange={(e) =>
                            setNewCommission({ ...newCommission, type: e.target.value })
                        }
                        margin="normal"
                        required
                        error={!newCommission.type && isFormSubmitted}
                        size="small"
                        sx={{ width: '49%', marginRight: '10px' }}
                    >
                        {commissionTypes.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </MUITextField>
                    <MUITextField
                        select
                        label="Bonus Type"
                        value={
                            newCommission.bonus_type_uuid === null
                                ? '0'
                                : newCommission.bonus_type_uuid
                        }
                        onChange={(e) => {
                            const value = e.target.value === '0' ? null : e.target.value;
                            setNewCommission({ ...newCommission, bonus_type_uuid: value });
                        }}
                        margin="normal"
                        size="small"
                        sx={{ width: '49%' }}
                    >
                        <MenuItem key={'0'} value={'0'} selected>
                            None
                        </MenuItem>
                        {commissionBonusTypes.map((type) => (
                            <MenuItem key={type.uuid} value={type.uuid}>
                                {`${type.type} - ${type.rate}%`}
                            </MenuItem>
                        ))}
                    </MUITextField>
                    <MUITextField
                        label="Commission Amount"
                        value={newCommissionAmount}
                        onChange={handleNewCommissionAmountChange}
                        size="small"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        type="text"
                        inputProps={{
                            inputMode: 'decimal',
                            pattern: '[0-9]*\\.?[0-9]*',
                        }}
                        margin="normal"
                        required
                        error={newCommissionAmount === '' && isFormSubmitted}
                        sx={{ width: '49%', marginRight: '10px' }}
                    />
                    <MUITextField
                        label="Earned Date"
                        type="date"
                        value={newCommission.earned_date || ''}
                        onChange={(e) => {
                            setNewCommission({
                                ...newCommission,
                                earned_date: e.target.value,
                            });
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        margin="normal"
                        required
                        error={newCommission.earned_date === '' && isFormSubmitted}
                        size="small"
                        sx={{ width: '49%' }}
                    />
                    <MUITextField
                        label="Notes"
                        value={newCommission.notes}
                        onChange={(e) =>
                            setNewCommission({
                                ...newCommission,
                                notes: e.target.value != '' ? e.target.value : null,
                            })
                        }
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                        size="small"
                    />
                </DialogContent>
                <Divider sx={{ my: 2 }} />
                <DialogActions>
                    <Button onClick={handleCloseCreateDialog}>Cancel</Button>
                    <Button onClick={handleCreateCommission} variant="contained" color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setSnackbarOpen(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </>
    );
};

export default CommissionList;
