import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Organization, ShoppingCart } from 'utils';

export interface InitializeShoppingCartParams {
    buyerType: 'user' | 'organization';
    buyerUuid: string;
    sellerUuid: string;
}

export interface sellerOrgResponse {
    userType: 'user' | 'organization';
    seller: Organization | null;
}

type ReturnType = Promise<AxiosResponse<ShoppingCart>>;

const getShoppingCart = (): ReturnType => {
    return Request.get(`/shop/cart`);
};

type SellerOrgReturnType = Promise<AxiosResponse<sellerOrgResponse>>;

const getSellerOrganization = (): SellerOrgReturnType => {
    return Request.get(`/shop/seller-organization`);
};

const initializeShoppingCart = (params: InitializeShoppingCartParams) => {
    return Request.post('/shop/cart', {
        buyer_type: params.buyerType,
        buyer_uuid: params.buyerUuid,
        seller_uuid: params.sellerUuid,
    });
};

const deleteShoppingCarts = () => {
    return Request.delete(`/shop/cart`);
};

const addItemToCart = (
    cartUuid: string,
    name: string,
    stripePriceId: string,
    quantity: number,
    type: string,
    category: string,
    sellableId: string | null,
) => {
    return Request.put(`/shop/cart/${cartUuid}/item`, {
        stripe_price_id: stripePriceId,
        name: name || 'Misc Marketplace Item',
        quantity,
        type,
        item_category: category,
        sellable_id: sellableId,
    });
};

const changeItemQuantity = (cartUuid: string, cartItemUuid: string, quantity: number) => {
    return Request.put(`/shop/cart/${cartUuid}/item/${cartItemUuid}`, {
        quantity,
    });
};

const checkoutOrganization = (cartUuid: string, organizationUuid: string) => {
    return Request.post(`/shop/cart/${cartUuid}/checkout-organization/${organizationUuid}`);
};

const checkoutAthlete = (cartUuid: string) => {
    return Request.post(`/shop/cart/${cartUuid}/checkout-athlete`);
};

const completeShopping = (cartUuid: string) => {
    return Request.post(`/shop/cart/${cartUuid}/complete-shopping`);
};

export {
    initializeShoppingCart,
    getShoppingCart,
    deleteShoppingCarts,
    addItemToCart,
    changeItemQuantity,
    checkoutOrganization,
    checkoutAthlete,
    completeShopping,
    getSellerOrganization,
};
export type { ShoppingCart };
