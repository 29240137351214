import styled from '@emotion/styled';
import { ExitToApp } from '@mui/icons-material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Avatar,
    BadgeProps,
    Box,
    Card,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import { AbilityContext } from 'components/Functional/Can';
import { OrganizationsContext } from 'contexts/OrganizationsContext';
import { orderBy } from 'lodash';
import Lottie from 'lottie-react'; // Correct import statement
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userSelector } from 'redux/reducers/user';
import ROUTES from 'Routes/routes';
import { getInitials } from 'utils';
import learnAnimation from '../../lottie-animations/learn-more-button.json'; // Replace with your animation file

const OrganizationList = ({
    onOrganizationClicked,
}: {
    onOrganizationClicked: ProfileDropdownProps['onOrganizationClicked'];
}) => {
    const { organizations } = useContext(OrganizationsContext);
    const ability = useContext(AbilityContext);

    if (organizations?.filter((o) => ability.can('organization:update', o)).length == 0) {
        return null;
    }

    return (
        <>
            <List component="div">
                {orderBy(organizations, ['name'], ['asc'])
                    // Only showing the organizations on top profile dropdown if the user has permission to update
                    ?.filter((o) => ability.can('organization:update', o))
                    ?.map((organization) => (
                        <ListItem
                            onClick={() => onOrganizationClicked(organization)}
                            button
                            key={organization.uuid}
                        >
                            <ListItemAvatar>
                                <Box sx={{ position: 'relative' }}>
                                    <Avatar
                                        variant="rounded"
                                        src={organization.logo_url || ''}
                                        alt={organization.name}
                                        style={{
                                            width: 40,
                                            height: 40,
                                        }}
                                    >
                                        {getInitials(organization.name)}
                                    </Avatar>

                                    {organization.subscription_tier?.name === 'Gold' && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                right: 0,
                                                width: '40px',
                                                height: '40px',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    right: 16,
                                                    backgroundColor: '#FFD700',
                                                    width: '40px',
                                                    height: '10px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '6px',
                                                    fontWeight: 'bold',
                                                    color: '#000',
                                                    boxShadow: '0px 0px 1px rgba(0,0,0,0.1)',
                                                    borderRadius: '0 0 2px 2px',
                                                }}
                                            >
                                                GOLD
                                            </Box>
                                        </Box>
                                    )}

                                    {organization.subscription_tier?.name === 'Silver' && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                right: 0,
                                                width: '40px',
                                                height: '40px',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    right: 16,
                                                    backgroundColor: '#C0C0C0',
                                                    width: '40px',
                                                    height: '10px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '6px',
                                                    fontWeight: 'bold',
                                                    color: '#000',
                                                    boxShadow: '0px 0px 1px rgba(0,0,0,0.1)',
                                                    borderRadius: '0 0 2px 2px',
                                                }}
                                            >
                                                SILVER
                                            </Box>
                                        </Box>
                                    )}

                                    {organization.subscription_tier?.name === 'Bronze' && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                right: 0,
                                                width: '40px',
                                                height: '40px',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    right: 16,
                                                    backgroundColor: '#CD7F32',
                                                    width: '40px',
                                                    height: '10px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '6px',
                                                    fontWeight: 'bold',
                                                    color: '#000',
                                                    boxShadow: '0px 0px 1px rgba(0,0,0,0.1)',
                                                    borderRadius: '0 0 2px 2px',
                                                }}
                                            >
                                                BRONZE
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'body2',
                                }}
                            >
                                {organization.name}
                            </ListItemText>
                        </ListItem>
                    ))}
            </List>
            <Divider />
        </>
    );
};

interface ProfileDropdownProps {
    onLogOut: (action: string) => void;
    hideProfileOptions: () => void;
    onOrganizationClicked: (organization: any) => void;
}

const ProfileDropdown = ({
    onLogOut,
    hideProfileOptions,
    onOrganizationClicked,
}: ProfileDropdownProps) => {
    const { push } = useHistory();
    const { userData } = useSelector(userSelector);

    const StyledBadge = styled(Badge)<BadgeProps>(() => ({
        '& .MuiBadge-badge': {
            right: 75,
            top: 13,
            padding: '4 8px',
            fontSize: '1.0rem', // Increase the font size of the badge content
            minWidth: '24px', // Set a minimum width for the badge
            height: '24px', // Set the height of the badge
            borderRadius: '12px', // Adjust the borderRadius to maintain the circular shape
        },
    }));
    const RotatingSettingsIcon = styled(SettingsIcon)(({ fontSize }) =>
        fontSize === 'large'
            ? {
                  animation: '$rotation 4s infinite linear',
                  '@keyframes rotation': {
                      from: {
                          transform: 'rotate(0deg)',
                      },
                      to: {
                          transform: 'rotate(359deg)',
                      },
                  },
              }
            : {},
    );

    return (
        <Card sx={{ minWidth: 300 }} raised={false}>
            <List component="nav" dense>
                {userData && (
                    <>
                        <ListItem
                            button
                            onClick={() => {
                                hideProfileOptions();
                                push(ROUTES.Profile.path);
                            }}
                        >
                            <ListItemText
                                secondary={`${userData.user_name} ${userData.email}`}
                                primaryTypographyProps={{ variant: 'h6' }}
                            >
                                {`${userData.first_name} ${userData.last_name}`}
                            </ListItemText>
                            <Box sx={{ '& svg': { fontSize: 'large' } }}>
                                <RotatingSettingsIcon color="primary" />
                            </Box>
                        </ListItem>
                    </>
                )}
                <ListItemButton onClick={() => push('/athletes')}>
                    <DirectionsRunIcon sx={{ mr: 4 }} color={'primary'} />
                    My Athlete Account
                </ListItemButton>
                <ListItemButton onClick={() => push('/media')}>
                    <PermMediaIcon sx={{ mr: 4 }} color={'primary'} />
                    Media Library
                </ListItemButton>
            </List>
            <Divider />
            <OrganizationList onOrganizationClicked={onOrganizationClicked} />
            <List>
                <ListItem button onClick={() => onLogOut('log-out-all-devices')}>
                    <ListItemText>Logout All Devices</ListItemText>
                </ListItem>
                <ListItem button onClick={() => onLogOut('log-out')}>
                    <ListItemText>Logout</ListItemText>
                    <ListItemSecondaryAction>
                        <ExitToApp />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button onClick={() => push('/Learn1TUl')}>
                    <ListItemText>Learn 1TUL</ListItemText>
                    <StyledBadge color="primary">
                        {/* Add the Lottie component here */}
                        <Lottie
                            animationData={learnAnimation} // Correct property name
                            loop={true}
                            autoplay={true}
                            style={{
                                width: '100px', // Adjust the width and height as needed
                                height: '100px',
                            }}
                        />
                    </StyledBadge>
                </ListItem>
            </List>
        </Card>
    );
};

export default ProfileDropdown;
