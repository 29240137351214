import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Stack,
    Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { PhysicalProductForPurchase, Organization } from '../../../utils';
import { getPhysicalProducts } from '../../../api/Auth/getPhysicalProducts';
import { ErrorIcon } from 'stream-chat-react';
import { UseShoppingCart } from '../../../contexts/ShoppingCartContext';
import HoverImage from '../../../components/Images/HoverImage';
import HoverImageWithText from '../../../components/Images/HoverImageWithText';
import { MarketplaceItem } from '../../marketplace/marketplace.types';
import { getLocalMarketplaceItems } from '../../marketplace/marketplace.api';
import { CartInput } from './CartInput';
import Pod from '../../../images/pod.png';
import Vest from '../../../images/vestTransparent.png';
import Charger from '../../../images/charger1.png';
import { AthleteContext } from '../../../contexts/AthleteContext';
import GoldMedal from '../../../images/gold-medal.png';

interface ShopAsAthleteProps {
    sellerOrganization: Organization;
    hasActiveCart: boolean;
}

export const ShopAsAthlete: React.FC<ShopAsAthleteProps> = ({
    sellerOrganization,
    hasActiveCart,
}) => {
    const [vestsForPurchase, setVestsForPurchase] = useState<PhysicalProductForPurchase[]>([]);
    const [podsForPurchase, setPodsForPurchase] = useState<PhysicalProductForPurchase[]>([]);
    const [singleChargersForPurchase, setSingleChargersForPurchase] = useState<
        PhysicalProductForPurchase[]
    >([]);
    const [localMarketplaceItems, setLocalMarketplaceItems] = useState<MarketplaceItem[]>([]);
    const [hasSelectedPodSubscription, setHasSelectedPodSubscription] = useState<boolean>(false);
    const [hasSelected1TulSubscription, setHasSelected1TulSubscription] = useState<boolean>(false);
    const [hasActive1TulSubscription, setHasActive1TulSubscription] = useState<boolean>(false);
    const [hasActiveFiyrPodSubscription, setHasActiveFiyrPodSubscription] =
        useState<boolean>(false);
    const [hasActiveComboSubscription, setHasActiveComboSubscription] = useState<boolean>(false);
    const [error, setError] = useState('');
    const { cart } = UseShoppingCart();
    const { subscriptions } = useContext(AthleteContext);

    useEffect(() => {
        console.debug('ShopAsAthlete - cart', cart);
    }, [cart]);

    useEffect(() => {
        if (
            subscriptions.some(
                (subscription) =>
                    subscription.stripe_status === 'active' &&
                    (subscription.name === 'default' || subscription.name === 'athlete_combo'),
            )
        ) {
            setHasActive1TulSubscription(true);
        }

        if (
            subscriptions.some(
                (subscription) =>
                    subscription.stripe_status === 'active' &&
                    (subscription.name === 'athlete_combo' ||
                        subscription.name === 'athlete_fiyrpod'),
            )
        ) {
            setHasActiveFiyrPodSubscription(true);
        }

        if (
            subscriptions.some(
                (subscription) =>
                    subscription.stripe_status === 'active' &&
                    subscription.name === 'athlete_combo',
            )
        ) {
            setHasActiveComboSubscription(true);
        }
    }, [subscriptions]);

    useEffect(() => {
        getPhysicalProducts('FiyrPod Vest')
            .then((response) => {
                setVestsForPurchase(response.data.data);
            })
            .catch((e: any) => {
                setError(`Error retrieving FiyrPod vests. ${e.response?.data?.message}`);
            });

        getPhysicalProducts('Fiyrpod')
            .then((response) => {
                setPodsForPurchase(response.data.data);
            })
            .catch((e: any) => {
                setError(`Error retrieving FiyrPods. ${e.response?.data?.message}`);
            });

        getPhysicalProducts('Fiyrpod Single Charger')
            .then((response) => {
                setSingleChargersForPurchase(response.data.data);
            })
            .catch((e: any) => {
                setError(`Error retrieving FiyrPod single chargers. ${e.response?.data?.message}`);
            });
    }, [sellerOrganization]);

    // In ShopAsAthlete component
    useEffect(() => {
        // If we have a cart, use the seller_uuid from there
        if (cart && cart.seller_uuid) {
            const actualSellerUuid = cart.seller_uuid;

            const localMarketplaceFilters = {
                'filter[category]': 'all',
                'filter[title]': '',
                'filter[sales_type]': 'business_to_customer',
            };

            getLocalMarketplaceItems(actualSellerUuid, localMarketplaceFilters).then((response) => {
                setLocalMarketplaceItems(response.data);
            });
        } else if (sellerOrganization?.uuid) {
            // Fall back to the prop if cart isn't available
            const localMarketplaceFilters = {
                'filter[category]': 'all',
                'filter[title]': '',
                'filter[sales_type]': 'business_to_customer',
            };

            getLocalMarketplaceItems(sellerOrganization.uuid, localMarketplaceFilters).then(
                (response) => {
                    setLocalMarketplaceItems(response.data);
                },
            );
        }
    }, [cart, sellerOrganization]);

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: '24px',
                marginBottom: '24px',
            }}
        >
            <Dialog
                open={!!error}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                        return;
                    }
                    setError('');
                }}
                disableEscapeKeyDown={true}
                PaperProps={{
                    sx: {
                        minWidth: '320px',
                        maxWidth: '90vw',
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: '#dc2626',
                    }}
                >
                    <ErrorIcon />
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{error}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setError('');
                        }}
                        variant="contained"
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '24px',
                }}
            >
                {hasActiveCart ? (
                    <Box
                        sx={{
                            maxWidth: 512,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            border: '1px solid lightgray',
                            borderRadius: '8px',
                            padding: '12px',
                        }}
                    >
                        {hasActiveComboSubscription && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '0.8rem',
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        marginTop: 2,
                                    }}
                                >
                                    You have an active Fiyrpod Combo subscription
                                </Typography>
                                <hr
                                    style={{
                                        width: '100%',
                                        margin: '16px 0',
                                        border: 'none',
                                        borderTop: '1px solid #e0e0e0',
                                    }}
                                />
                            </>
                        )}
                        {hasActive1TulSubscription && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '0.8rem',
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        marginTop: 2,
                                    }}
                                >
                                    You have an active 1Tul subscription
                                </Typography>
                                <hr
                                    style={{
                                        width: '100%',
                                        margin: '16px 0',
                                        border: 'none',
                                        borderTop: '1px solid #e0e0e0',
                                    }}
                                />
                            </>
                        )}
                        {hasActiveFiyrPodSubscription && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '0.8rem',
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        marginTop: 2,
                                    }}
                                >
                                    You have an active Fiyrpod subscription
                                </Typography>
                                <hr
                                    style={{
                                        width: '100%',
                                        margin: '16px 0',
                                        border: 'none',
                                        borderTop: '1px solid #e0e0e0',
                                    }}
                                />
                            </>
                        )}

                        {!hasActive1TulSubscription && !hasActiveFiyrPodSubscription && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '0.8rem',
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        marginTop: 2,
                                    }}
                                >
                                    1Tul Combo Athlete Subscription
                                </Typography>

                                {podsForPurchase.map((pod) => (
                                    <FormControl
                                        key={pod.uuid}
                                        fullWidth
                                        margin="normal"
                                        style={{ marginTop: 0, marginBottom: '8px' }}
                                    >
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <HoverImage src={Pod} alt="Pod" />
                                            <Typography
                                                sx={{
                                                    width: 'calc(80% - 24px)',
                                                    fontSize: '0.8rem',
                                                }}
                                            >
                                                {`${pod.name} Combo`}{' '}
                                                <span style={{ fontWeight: '500' }}>
                                                    $69.99/month
                                                </span>
                                                <span
                                                    style={{
                                                        color:
                                                            pod.inventory_count > 0
                                                                ? 'green'
                                                                : 'red',
                                                        fontStyle: 'italic',
                                                        fontSize: '0.65rem',
                                                    }}
                                                >
                                                    {pod.inventory_count > 0
                                                        ? ` ${pod.inventory_count} available`
                                                        : ' available soon'}
                                                </span>
                                            </Typography>
                                            {pod.inventory_count > 0 && (
                                                <CartInput
                                                    initialValue={0}
                                                    name={pod.name}
                                                    maxValue={null}
                                                    cartUuid={cart?.uuid}
                                                    stripePriceId={
                                                        process.env
                                                            .REACT_APP_STRIPE_ATHLETE_COMBO_PRICE!
                                                    }
                                                    type="physical_product"
                                                    category="athlete_combo"
                                                    sellableId={pod.uuid}
                                                    disabled={
                                                        !cart?.uuid ||
                                                        hasSelectedPodSubscription ||
                                                        hasSelected1TulSubscription
                                                    }
                                                    onAdded={() =>
                                                        setHasSelectedPodSubscription(true)
                                                    }
                                                    onRemoved={() =>
                                                        setHasSelectedPodSubscription(false)
                                                    }
                                                />
                                            )}
                                        </Stack>
                                    </FormControl>
                                ))}
                                <hr
                                    style={{
                                        width: '100%',
                                        margin: '16px 0',
                                        border: 'none',
                                        borderTop: '1px solid #e0e0e0',
                                    }}
                                />
                            </>
                        )}
                        {!hasActive1TulSubscription && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '0.8rem',
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        marginTop: 2,
                                    }}
                                >
                                    1Tul Subscription
                                </Typography>

                                <FormControl
                                    key={1}
                                    fullWidth
                                    margin="normal"
                                    style={{ marginTop: 0, marginBottom: '8px' }}
                                >
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <HoverImage src={GoldMedal} alt="1Tul" />
                                        <Typography
                                            sx={{
                                                width: 'calc(80% - 24px)',
                                                fontSize: '0.8rem',
                                            }}
                                        >
                                            1Tul Subscription{' '}
                                            <span style={{ fontWeight: '500' }}>$9.99/month</span>
                                        </Typography>
                                        <CartInput
                                            initialValue={0}
                                            name="1Tul Subscription"
                                            maxValue={null}
                                            cartUuid={cart?.uuid}
                                            stripePriceId={
                                                process.env.REACT_APP_STRIPE_ATHLETE_PRICE!
                                            }
                                            type="subscription"
                                            category="subscription"
                                            sellableId={null}
                                            disabled={!cart?.uuid || hasSelectedPodSubscription}
                                            onAdded={() => setHasSelected1TulSubscription(true)}
                                            onRemoved={() => setHasSelected1TulSubscription(false)}
                                        />
                                    </Stack>
                                </FormControl>
                                <hr
                                    style={{
                                        width: '100%',
                                        margin: '16px 0',
                                        border: 'none',
                                        borderTop: '1px solid #e0e0e0',
                                    }}
                                />
                            </>
                        )}
                        {!hasActiveFiyrPodSubscription && hasActive1TulSubscription && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '0.8rem',
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        marginTop: 2,
                                    }}
                                >
                                    Fiyrpods
                                </Typography>
                                {podsForPurchase.map((pod) => (
                                    <FormControl
                                        key={pod.uuid}
                                        fullWidth
                                        margin="normal"
                                        style={{ marginTop: 0, marginBottom: '8px' }}
                                    >
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <HoverImage src={Pod} alt="Pod" />
                                            <Typography
                                                sx={{
                                                    width: 'calc(80% - 24px)',
                                                    fontSize: '0.8rem',
                                                }}
                                            >
                                                {pod.name}
                                                <span style={{ fontWeight: '500' }}>
                                                    {' $'}
                                                    {(pod.price / 100).toFixed(2)}
                                                    /month
                                                </span>
                                                <span
                                                    style={{
                                                        color:
                                                            pod.inventory_count > 0
                                                                ? 'green'
                                                                : 'red',
                                                        fontStyle: 'italic',
                                                        fontSize: '0.65rem',
                                                    }}
                                                >
                                                    {pod.inventory_count > 0
                                                        ? ` ${pod.inventory_count} available`
                                                        : ' available soon'}
                                                </span>
                                            </Typography>
                                            {pod.inventory_count > 0 && (
                                                <CartInput
                                                    initialValue={0}
                                                    name={pod.name}
                                                    maxValue={null}
                                                    cartUuid={cart?.uuid}
                                                    stripePriceId={pod.stripe_price_id}
                                                    type="physical_product"
                                                    category="fiyrpod"
                                                    sellableId={pod.uuid}
                                                    disabled={
                                                        !cart?.uuid ||
                                                        hasSelectedPodSubscription ||
                                                        hasSelected1TulSubscription
                                                    }
                                                    onAdded={() =>
                                                        setHasSelectedPodSubscription(true)
                                                    }
                                                    onRemoved={() =>
                                                        setHasSelectedPodSubscription(false)
                                                    }
                                                />
                                            )}
                                        </Stack>
                                    </FormControl>
                                ))}
                                <hr
                                    style={{
                                        width: '100%',
                                        margin: '16px 0',
                                        border: 'none',
                                        borderTop: '1px solid #e0e0e0',
                                    }}
                                />
                            </>
                        )}
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: '600',
                                textAlign: 'center',
                                marginTop: 2,
                            }}
                        >
                            Vests
                        </Typography>
                        {vestsForPurchase.map((vest) => (
                            <FormControl
                                key={vest.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <HoverImage src={Vest} alt="Vest" />
                                    <Typography
                                        sx={{
                                            width: 'calc(80% - 24px)',
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {vest.name}
                                        <span style={{ fontWeight: '500' }}>
                                            {' $'}
                                            {(vest.price / 100).toFixed(2)}
                                        </span>
                                        <span
                                            style={{
                                                color: vest.inventory_count > 0 ? 'green' : 'red',
                                                fontStyle: 'italic',
                                                fontSize: '0.65rem',
                                            }}
                                        >
                                            {vest.inventory_count > 0
                                                ? ` ${vest.inventory_count} available`
                                                : ' available soon'}
                                        </span>
                                    </Typography>
                                    {vest.inventory_count > 0 && (
                                        <CartInput
                                            initialValue={0}
                                            name={vest.name}
                                            maxValue={vest.inventory_count}
                                            cartUuid={cart?.uuid}
                                            stripePriceId={vest.stripe_price_id}
                                            type="physical_product"
                                            category="vest"
                                            sellableId={vest.uuid}
                                            disabled={!cart?.uuid}
                                        />
                                    )}
                                </Stack>
                            </FormControl>
                        ))}
                        <hr
                            style={{
                                width: '100%',
                                margin: '16px 0',
                                border: 'none',
                                borderTop: '1px solid #e0e0e0',
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: '600',
                                textAlign: 'center',
                                marginTop: 2,
                            }}
                        >
                            Single Chargers
                        </Typography>
                        {singleChargersForPurchase.length === 0 && (
                            <Typography
                                sx={{
                                    fontSize: '0.65rem',
                                    textAlign: 'center',
                                    marginTop: 2,
                                    color: 'red',
                                }}
                            >
                                No single chargers available
                            </Typography>
                        )}
                        {singleChargersForPurchase.map((charger) => (
                            <FormControl
                                key={charger.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <HoverImage src={Charger} alt="Charger" />
                                    <Typography
                                        sx={{
                                            width: 'calc(80% - 24px)',
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {charger.name}
                                        <span style={{ fontWeight: '500' }}>
                                            {' $'}
                                            {(charger.price / 100).toFixed(2)}{' '}
                                        </span>
                                        <span
                                            style={{
                                                color:
                                                    charger.inventory_count > 0 ? 'green' : 'red',
                                                fontStyle: 'italic',
                                                fontSize: '0.65rem',
                                            }}
                                        >
                                            {charger.inventory_count > 0
                                                ? ` ${charger.inventory_count} available`
                                                : ' available soon'}
                                        </span>
                                    </Typography>
                                    {charger.inventory_count > 0 && (
                                        <CartInput
                                            initialValue={0}
                                            name={charger.name}
                                            maxValue={charger.inventory_count}
                                            cartUuid={cart?.uuid}
                                            stripePriceId={charger.stripe_price_id}
                                            type="physical_product"
                                            category="single_charger"
                                            sellableId={charger.uuid}
                                            disabled={!cart?.uuid}
                                        />
                                    )}
                                </Stack>
                            </FormControl>
                        ))}
                        <hr
                            style={{
                                width: '100%',
                                margin: '16px 0',
                                border: 'none',
                                borderTop: '1px solid #e0e0e0',
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: '600',
                                textAlign: 'center',
                                marginTop: 2,
                            }}
                        >
                            {sellerOrganization?.name} Marketplace Items
                        </Typography>
                        {!localMarketplaceItems.length && (
                            <Typography
                                sx={{
                                    fontSize: '0.65rem',
                                    textAlign: 'center',
                                    marginTop: 2,
                                    color: 'red',
                                }}
                            >
                                No marketplace items available
                            </Typography>
                        )}
                        {localMarketplaceItems.map((marketplaceItem) => (
                            <FormControl
                                key={marketplaceItem.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <HoverImageWithText
                                        src={marketplaceItem.imageUrl}
                                        alt=""
                                        title={marketplaceItem.title}
                                        description={marketplaceItem.description}
                                    />
                                    <Typography
                                        sx={{
                                            width: 'calc(80% - 24px)',
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {marketplaceItem.title || 'Marketplace Item'}
                                        <span style={{ fontWeight: '500' }}>
                                            {' $'}
                                            {(marketplaceItem.price / 100).toFixed(2)}
                                        </span>
                                    </Typography>
                                    <CartInput
                                        initialValue={0}
                                        name={marketplaceItem.title}
                                        maxValue={null}
                                        cartUuid={cart?.uuid}
                                        stripePriceId={marketplaceItem.stripe_price_id}
                                        type="marketplace_item"
                                        category="marketplace_item"
                                        sellableId={marketplaceItem.uuid}
                                        disabled={
                                            !cart?.uuid ||
                                            cart?.items.some(
                                                (item: any) =>
                                                    item.sellable_id === marketplaceItem.uuid,
                                            )
                                        }
                                    />
                                </Stack>
                            </FormControl>
                        ))}
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};
