import React, { useContext, useEffect, useReducer } from 'react';
import {
    getCommissions,
    getDownlinePartnerPayouts,
    getEnrollerTree,
    getPayout,
    getPayouts,
    getRanks,
    getTree,
} from '../networking.api';
import { NetworkingActionTypes, networkingReducer, NetworkingState } from './networking.state';
import { viewOrganization } from '../../../api';

const initialState: NetworkingState = {
    organization: {
        data: undefined,
        loading: false,
    },
    binaryTree: {
        data: undefined,
        loaded: false,
        loading: false,
        error: '',
    },
    enrollerTree: {
        data: undefined,
        loaded: false,
        loading: false,
        error: '',
    },
    ranks: {
        data: [],
        loaded: false,
        loading: false,
        error: '',
    },
    commissions: {
        data: [],
        loaded: false,
        loading: false,
        errorMessage: '',
        filters: {
            page: 1,
            sort: [],
            search: '',
            'filter[payout_uuid]': '',
        },
        pagination: {
            has_next: false,
            has_prev: false,
            current_page: 1,
        },
    },
    payouts: {
        data: [],
        loaded: false,
        loading: false,
        errorMessage: '',
        filters: {
            page: 1,
            sort: [],
        },
        pagination: {
            has_next: false,
            has_prev: false,
            current_page: 1,
        },
    },
    downlinePartnerPayouts: {
        data: [],
        loaded: false,
        loading: false,
        errorMessage: '',
        filters: {
            page: 1,
            sort: [],
        },
        pagination: {
            has_next: false,
            has_prev: false,
            current_page: 1,
        },
    },
    payout: {
        payout: null,
        loading: false,
        loaded: false,
        errorMessage: '',
    },
    commissionsPerPayout: {
        data: {},
        errorMessage: '',
        loading: false,
    },
    selectedPayoutId: '',
};

export const DispatchContext = React.createContext<React.Dispatch<any> | undefined>(undefined);
export const StateContext = React.createContext<NetworkingState | undefined>(undefined);

export function NetworkingProvider({ children, id }: { children: React.ReactNode; id: string }) {
    const [state, dispatch] = useReducer(networkingReducer, initialState);
    const stateMemo = React.useMemo(() => state, [state]);
    const dispatchMemo = React.useMemo(() => dispatch, [dispatch]);
    /**
     * Fetch data
     */
    useEffect(() => {
        if (!id) {
            return;
        }
        (async () => {
            try {
                dispatch({
                    type: NetworkingActionTypes.LOAD_ORGANIZATION_REQUEST,
                });
                const organization = await viewOrganization(id);
                dispatch({
                    type: NetworkingActionTypes.LOAD_ORGANIZATION_SUCCESS,
                    payload: organization.data,
                });
            } catch (e) {
                dispatch({
                    type: NetworkingActionTypes.LOAD_ORGANIZATION_ERROR,
                    payload: 'Error while retrieving organization.',
                });
            }
        })();

        (async () => {
            try {
                dispatch({
                    type: NetworkingActionTypes.LOAD_BINARY_TREE_REQUEST,
                });
                const treeFromApi = await getTree({ id });
                dispatch({
                    type: NetworkingActionTypes.LOAD_BINARY_TREE_SUCCESS,
                    payload: treeFromApi.data,
                });
            } catch (error) {
                dispatch({
                    type: NetworkingActionTypes.LOAD_BINARY_TREE_ERROR,
                    payload: 'Error while retrieving binary tree.',
                });
            }
        })();

        (async () => {
            try {
                dispatch({
                    type: NetworkingActionTypes.LOAD_ENROLLER_TREE_REQUEST,
                });
                const treeFromApi = await getEnrollerTree({ id });
                dispatch({
                    type: NetworkingActionTypes.LOAD_ENROLLER_TREE_SUCCESS,
                    payload: treeFromApi.data,
                });
            } catch (error) {
                dispatch({
                    type: NetworkingActionTypes.LOAD_ENROLLER_TREE_ERROR,
                    payload: 'Error while retrieving enroller tree.',
                });
            }
        })();

        (async () => {
            dispatch({
                type: NetworkingActionTypes.LOAD_RANKS_REQUEST,
            });
            try {
                const ranksFromApi = await getRanks({ id, filters: {} });
                dispatch({
                    type: NetworkingActionTypes.LOAD_RANKS_SUCCESS,
                    payload: ranksFromApi.data,
                });
            } catch (error) {
                dispatch({
                    type: NetworkingActionTypes.LOAD_RANKS_ERROR,
                    payload: 'Error while retrieving ranks.',
                });
            }
        })();
    }, [id]);

    useEffect(() => {
        if (!id) {
            return;
        }
        (async () => {
            dispatch({
                type: NetworkingActionTypes.LOAD_COMMISSIONS_REQUEST,
            });
            try {
                const commissionsFromApi = await getCommissions({
                    id,
                    filters: state.commissions.filters,
                });
                dispatch({
                    type: NetworkingActionTypes.LOAD_COMMISSIONS_SUCCESS,
                    payload: commissionsFromApi.data,
                });
            } catch (error) {
                dispatch({
                    type: NetworkingActionTypes.LOAD_COMMISSIONS_ERROR,
                    payload: 'Error while retrieving commissions.',
                });
            }
        })();
    }, [id, state.commissions.filters]);

    useEffect(() => {
        if (!id) {
            return;
        }
        (async () => {
            dispatch({
                type: NetworkingActionTypes.LOAD_PAYOUTS_REQUEST,
            });
            try {
                const payoutsFromApi = await getPayouts({
                    id,
                    filters: state.payouts.filters,
                });
                dispatch({
                    type: NetworkingActionTypes.LOAD_PAYOUTS_SUCCESS,
                    payload: payoutsFromApi.data,
                });
            } catch (error) {
                dispatch({
                    type: NetworkingActionTypes.LOAD_PAYOUTS_ERROR,
                    payload: 'Error while retrieving Payouts.',
                });
            }
        })();
    }, [id, state.payouts.filters]);

    useEffect(() => {
        if (id && state.selectedPayoutId) {
            (async () => {
                try {
                    dispatch({
                        type: NetworkingActionTypes.LOAD_PAYOUT_REQUEST,
                        payoutId: state.selectedPayoutId,
                    });
                    const selectedPayoutData = await getPayout({
                        id,
                        payoutId: state.selectedPayoutId,
                    });
                    dispatch({
                        type: NetworkingActionTypes.LOAD_PAYOUT_SUCCESS,
                        payload: selectedPayoutData.data,
                    });
                } catch (error) {
                    dispatch({
                        type: NetworkingActionTypes.LOAD_PAYOUT_ERROR,
                        payload: 'Error while retrieving Payout.',
                    });
                }
                try {
                    dispatch({
                        type: NetworkingActionTypes.LOAD_COMMISSIONS_FOR_PAYOUT_REQUEST,
                        payoutId: state.selectedPayoutId,
                    });
                    const commissionsPerPayoutFromApi = await getCommissions({
                        id,
                        filters: {
                            'filter[payout_uuid]': state.selectedPayoutId,
                            limit: '1000',
                        },
                    });
                    dispatch({
                        type: NetworkingActionTypes.LOAD_COMMISSIONS_FOR_PAYOUT_SUCCESS,
                        payload: {
                            commissions: commissionsPerPayoutFromApi.data.data,
                            payoutId: state.selectedPayoutId,
                        },
                    });
                } catch (error) {
                    dispatch({
                        type: NetworkingActionTypes.LOAD_COMMISSIONS_FOR_PAYOUT_ERROR,
                        payoutId: state.selectedPayoutId,
                    });
                }
            })();
        }
    }, [id, state.selectedPayoutId]);

    useEffect(() => {
        if (!id) {
            return;
        }
        (async () => {
            dispatch({
                type: NetworkingActionTypes.LOAD_DOWNLINE_PARTNER_PAYOUTS_REQUEST,
            });
            try {
                const downlinePartnerPayoutsFromApi = await getDownlinePartnerPayouts({
                    id,
                    filters: state.downlinePartnerPayouts.filters,
                });
                dispatch({
                    type: NetworkingActionTypes.LOAD_DOWNLINE_PARTNER_PAYOUTS_SUCCESS,
                    payload: downlinePartnerPayoutsFromApi.data,
                });
            } catch (error) {
                dispatch({
                    type: NetworkingActionTypes.LOAD_DOWNLINE_PARTNER_PAYOUTS_ERROR,
                    payload: 'Error while retrieving Downline Partner Payouts.',
                });
            }
        })();
    }, [id, state.downlinePartnerPayouts.filters]);

    return (
        <StateContext.Provider value={stateMemo}>
            <DispatchContext.Provider value={dispatchMemo}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
}

export const useStateContext = () => {
    const state = useContext(StateContext);
    if (state === undefined) {
        throw new Error('useStateContext must be used within a NetworkingProvider');
    }
    return state;
};

export const useDispatchContext = () => {
    const dispatch = useContext(DispatchContext);
    if (dispatch === undefined) {
        throw new Error('useDispatchContext must be used within an NetworkingProvider');
    }
    return dispatch;
};

export const useNetworkingOrganization = () => {
    const { organization } = useStateContext();
    return organization;
};

export const useNetworkingBinaryTree = () => {
    const { binaryTree } = useStateContext();
    return binaryTree;
};

export const useNetworkingEnrollerTree = () => {
    const { enrollerTree } = useStateContext();
    return enrollerTree;
};

export const useNetworkingRanks = () => {
    const { ranks } = useStateContext();
    return ranks;
};

export const useNetworkingCommissions = () => {
    const { commissions } = useStateContext();
    return commissions;
};

export const useNetworkingPayouts = () => {
    const { payouts } = useStateContext();
    return payouts;
};

export const useNetworkingPayout = () => {
    const { payout } = useStateContext();
    return payout;
};

export const useNetworkingSelectedPayoutId = () => {
    const { selectedPayoutId } = useStateContext();
    return selectedPayoutId;
};

export const useNetworkingCommissionsPerPayout = () => {
    const { commissionsPerPayout } = useStateContext();
    return commissionsPerPayout;
};

export const useNetworkingDownlinePartnerPayouts = () => {
    const { downlinePartnerPayouts } = useStateContext();
    return downlinePartnerPayouts;
};
