import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { AthleteSubscription } from '../../app.types';

type ReturnType = Promise<AxiosResponse<Array<AthleteSubscription>>>;

const createPodReturnRequestEmail = (emailBody: string, podSerialNumbers: string[]): ReturnType =>
    Request.post(`/athlete-users/pods/request-return`, {
        pod_serial_numbers: podSerialNumbers,
        email_body: emailBody,
    });

export default createPodReturnRequestEmail;
