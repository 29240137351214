import { Box, Button, Container, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { GridColDef } from '@mui/x-data-grid';
import {
    DataGridPremium,
    GridRenderCellParams,
    GridSortItem,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { formatCurrency, formatDateStringToLocale } from '../../../app.functions';
import CustomDateRangePicker from '../../../components/FormControl/CustomDateRangePicker';
import CustomPagination from '../../../components/Functional/CustomPagination';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import { NetworkingActionTypes } from '../state/networking.state';
import {
    useDispatchContext,
    useNetworkingDownlinePartnerPayouts,
} from '../state/NetworkingContext';

const columns: GridColDef[] = [
    {
        field: 'organization_name',
        headerName: 'Organization',
        width: 250,
        disableColumnMenu: true,
        sortable: true,
    },
    {
        field: 'payout_date',
        headerName: 'Payout Date',
        width: 250,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<any, string>) => {
            return formatDateStringToLocale(params.value);
        },
    },
    {
        field: 'amount',
        headerName: 'Amount',
        width: 150,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<any, string>) => {
            return formatCurrency({
                amount: ((params.value ?? 0) as number) / 100,
                currency: 'USD',
            });
        },
    },
];
const PartnershipDownlinePayoutsScene = () => {
    const payouts = useNetworkingDownlinePartnerPayouts();
    const dispatch = useDispatchContext();
    const [orgNameInput, setOrgNameInput] = useState<string>('');
    const [dateRange, setDateRange] = useState<DateRange<DateTime>>([null, null]);

    const handleOrgNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrgNameInput(e.target.value);
    };

    const handleDateRangeChange = (newDateRange: DateRange<DateTime>) => {
        setDateRange(newDateRange);
    };

    const handleSearch = () => {
        const payload: any = { page: 1 };

        payload['filter[organization_name]'] = orgNameInput || undefined;

        if (dateRange[0] && dateRange[1]) {
            payload['filter[paid_after]'] = dateRange[0].toFormat('yyyy-MM-dd');
            payload['filter[paid_before]'] = dateRange[1].toFormat('yyyy-MM-dd');
        } else {
            payload['filter[paid_after]'] = undefined;
            payload['filter[paid_before]'] = undefined;
        }

        dispatch({
            type: NetworkingActionTypes.DOWNLINE_PARTNER_PAYOUTS_FILTERS_CHANGED,
            payload,
        });
    };

    const clearFilters = () => {
        setDateRange([null, null]);
        setOrgNameInput('');
    };

    const handleSortModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            dispatch({
                type: NetworkingActionTypes.DOWNLINE_PARTNER_PAYOUTS_FILTERS_CHANGED,
                payload: {
                    page: 1,
                    sort: sortModel.map((item: GridSortItem) => {
                        if (item.sort === 'asc') {
                            return `${item.field}`;
                        }
                        return `-${item.field}`;
                    }),
                },
            });
        },
        [dispatch],
    );

    return (
        <Container
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
            }}
            maxWidth={'xl'}
        >
            <Stack
                direction={'column'}
                spacing={4}
                sx={{
                    py: 4,
                }}
            >
                <h2>Partnership Downline Payouts List</h2>
                <Stack direction={'row'} spacing={2} alignItems="center">
                    <TextField
                        label="Organization Name"
                        variant="outlined"
                        value={orgNameInput}
                        onChange={handleOrgNameChange}
                        sx={{ minWidth: 250 }}
                        size="small"
                    />
                    <CustomDateRangePicker onChange={handleDateRangeChange} value={dateRange} />
                    <Button variant="outlined" onClick={clearFilters} size="medium">
                        Clear Filters
                    </Button>
                    <Button variant="contained" onClick={handleSearch} size="medium">
                        Search
                    </Button>
                </Stack>
            </Stack>
            <Box
                sx={{
                    flex: 1,
                    width: '100%',
                }}
            >
                <DataGridPremium
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                            backgroundColor: blue[100],
                        },
                    }}
                    loading={payouts.loading}
                    rows={payouts.data}
                    columns={columns}
                    sortingMode={'server'}
                    onSortModelChange={handleSortModelChange}
                    slots={{
                        loadingOverlay: CustomLoadingOverlay,
                        footer: CustomPagination,
                        noRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                <Typography variant="h6">No Payouts Yet</Typography>
                            </Stack>
                        ),
                        noResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                No Results When Filter Applied
                            </Stack>
                        ),
                    }}
                    getRowId={(row) => row.uuid}
                    slotProps={{
                        footer: {
                            hasNext: payouts.pagination.has_next,
                            hasPrevious: payouts.pagination.has_prev,
                            onNext: () => {
                                dispatch({
                                    type: NetworkingActionTypes.DOWNLINE_PARTNER_PAYOUTS_FILTERS_CHANGED,
                                    payload: {
                                        page: payouts.pagination.current_page + 1,
                                    },
                                });
                            },
                            onPrevious: () => {
                                dispatch({
                                    type: NetworkingActionTypes.DOWNLINE_PARTNER_PAYOUTS_FILTERS_CHANGED,
                                    payload: {
                                        page: payouts.pagination.current_page - 1,
                                    },
                                });
                            },
                            page: payouts.pagination.current_page,
                        },
                    }}
                />
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={Boolean(payouts.errorMessage)}
                message={payouts.errorMessage}
                onClose={() => {
                    dispatch({
                        type: NetworkingActionTypes.DOWNLINE_PARTNER_PAYOUTS_ERROR_MESSAGE_CLEARED,
                    });
                }}
                autoHideDuration={3000}
            />
        </Container>
    );
};
export default PartnershipDownlinePayoutsScene;
