import {
    Box,
    Checkbox,
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import RankHeaderCard from '../components/RankHeaderCard';
import { green, grey, orange, red, yellow } from '@mui/material/colors';
import { useNetworkingBinaryTree, useNetworkingRanks } from '../state/NetworkingContext';
import { formatCurrency } from '../../../app.functions';
import { Rank } from '../networking.types';

const colors = [red[300], yellow[300], orange[300], green[300], grey[300]];

const getTextFromQualificationCriteria = (key: string, value: number, actual: number) => {
    switch (key) {
        case 'organizationSubscriptionLevel':
            return `You must have at least a level ${value} organization subscription. You have a level ${actual} subscription`;
        case 'athleteCount':
            return `Have at least ${value} paid athletes in your organization. You have ${actual} paid athletes`;
        case 'marketplaceSales':
            return `Have at least ${formatCurrency({
                amount: value,
                currency: 'USD',
            })} in annual marketplace sales. You have ${formatCurrency({
                amount: actual / 100,
                currency: 'USD',
            })}`;
        default:
            return '';
    }
};

const meetsQualificationCriteria = (key: string, rank: Rank, actual: number): boolean => {
    if (key === 'marketplaceSales') {
        return (
            actual >=
            rank.qualification_criteria[key as keyof typeof rank.qualification_criteria] * 100
        );
    }
    return actual >= rank.qualification_criteria[key as keyof typeof rank.qualification_criteria];
};
const PartnershipRanksScene = () => {
    const [selectedRank, setSelectedRank] = React.useState<Rank>();
    const ranks = useNetworkingRanks();
    const binaryTree = useNetworkingBinaryTree();
    const levelMapping: { [key: number]: string } = {
        0: 'None',
        1: 'Bronze',
        2: 'Silver',
        3: 'Gold',
    };
    const num = binaryTree?.data?.levels?.organizationSubscriptionLevel ?? 0;

    return (
        <Container
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
            }}
            maxWidth={'xl'}
        >
            <Stack spacing={2}>
                <Grid container sx={{ padding: 4 }}>
                    <Grid xs={12} sm={6}>
                        <Paper sx={{ padding: 4 }}>
                            <TableContainer>
                                <Table size={'small'}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Your Rank</TableCell>
                                            <TableCell>
                                                {ranks.data.find(
                                                    (r) => r.level === binaryTree?.data?.rank,
                                                )?.name ?? 'Not Ranked'}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Your Leadership Rank</TableCell>
                                            <TableCell>
                                                {binaryTree?.data?.leadership_rank}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Your Organization Subscription Level
                                            </TableCell>
                                            <TableCell>{levelMapping[num]}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Your Athlete Count</TableCell>
                                            <TableCell>
                                                {binaryTree?.data?.levels.athleteCount}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Your Total Marketplace Sales</TableCell>
                                            <TableCell>
                                                {formatCurrency({
                                                    amount:
                                                        (binaryTree?.data?.levels
                                                            .marketplaceSalesTotal ?? 0) / 100,
                                                    currency: 'USD',
                                                })}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ 'td, th': { border: 0 } }}>
                                            <TableCell>
                                                Your Marketplace Sales for the past 365 days
                                            </TableCell>
                                            <TableCell>
                                                {formatCurrency({
                                                    amount:
                                                        (binaryTree?.data?.levels
                                                            .marketplaceSales ?? 0) / 100,
                                                    currency: 'USD',
                                                })}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={10} columns={10}>
                    {ranks.data.map((rank, index) => (
                        <Grid key={rank.uuid} xs={10} sm={5} md={2}>
                            <RankHeaderCard
                                selected={selectedRank?.name === rank.name}
                                onClick={() => {
                                    setSelectedRank(rank);
                                }}
                                color={colors[index]}
                                title={rank.name}
                                icon={rank.name.slice(0, 1).toUpperCase()}
                                isChecked={binaryTree?.data?.rank === rank.level}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ padding: 4 }}>
                    {selectedRank && (
                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                padding: 8,
                            }}
                        >
                            {Object.keys(selectedRank.qualification_criteria).map((key: string) => (
                                <ListItem disablePadding key={key}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            disabled={true}
                                            checked={meetsQualificationCriteria(
                                                key,
                                                selectedRank,
                                                binaryTree?.data?.levels[
                                                    key as keyof typeof selectedRank.qualification_criteria
                                                ] ?? 0,
                                            )}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={'text'}
                                        primary={getTextFromQualificationCriteria(
                                            key,
                                            selectedRank.qualification_criteria[
                                                key as keyof typeof selectedRank.qualification_criteria
                                            ],
                                            binaryTree?.data?.levels[
                                                key as keyof typeof selectedRank.qualification_criteria
                                            ] ?? 0,
                                        )}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Stack>
        </Container>
    );
};

export default PartnershipRanksScene;
