import { RouteConfigProps } from '../../Layouts/routeconfig';
import NavigationLayout from '../../Layouts/NavigationLayout';
import { Navigation } from '../../utils';
import Shop from './scenes/Shop';

const shopRoutes: RouteConfigProps[] = [
    {
        exact: true,
        layout: NavigationLayout,
        path: ['/shop', '/shop/:slug'],
        component: Shop,
        primaryNavigation: Navigation.SHOP,
        private: true,
    },
];

export default shopRoutes;
