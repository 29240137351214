import { Avatar, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { AbilityContext } from '../../../components/Functional/Can';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';
import Dashboard from './Dashboard';
import GpsSummaryDashboard from './GpsSummaryDashboard';
import SprintDashboard from './SprintDashboard';
import TemDashboard from './TemDashboard';
import TimeSegmentDashboard from './TimeSegmentDashboard';
import VideoAnalysisDashboard from './VideoAnalysisDashboard';

function DashboardLayout(): React.ReactElement {
    let { push } = useHistory();
    let { pathname } = useLocation();
    const orgProvider = useContext(OrganizationsContext);
    const ability = useContext(AbilityContext);
    const [cookie] = useCookies(['favOrg']);
    const handleChange = (event: React.MouseEvent<HTMLElement>, newPath: string) => {
        push(newPath);
    };

    const adminOrgs = orgProvider.organizations.filter((o) =>
        ability.can('organization:update', o),
    );
    const filterAdminOrgs = adminOrgs.filter((o) => `${o?.name}${o?.uuid}` === cookie.favOrg);

    return (
        <Box
            sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Avatar
                    src={
                        filterAdminOrgs[0]?.image_urls.avatar ??
                        orgProvider.organizations.filter((o) =>
                            ability.can('organization:update', o),
                        )[1]?.image_urls.avatar ??
                        orgProvider.organizations.filter((o) =>
                            ability.can('organization:update', o),
                        )[0]?.image_urls.avatar ??
                        ''
                    }
                    alt={
                        orgProvider.organizations.filter((o) =>
                            ability.can('organization:update', o),
                        )[0]?.name
                    }
                    variant={'rounded'}
                    sx={{
                        width: 66,
                        height: 66,
                        margin: 3,
                        boxShadow:
                            '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                    }}
                />
                <ToggleButtonGroup
                    sx={{
                        m: 4,
                        background: 'linear-gradient(#FFFFFF, #F5F5F5)',
                        boxShadow:
                            '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                    }}
                    color={'primary'}
                    exclusive={true}
                    onChange={handleChange}
                    value={pathname}
                >
                    <ToggleButton value={`/home/training-dashboard`}>Train Data</ToggleButton>
                    <ToggleButton value={`/home/sprint-dashboard`}>Sprint Data</ToggleButton>
                    <ToggleButton value={`/home/analyses-dashboard`}>Video Analysis</ToggleButton>
                    <ToggleButton value={`/home/tem-dashboard`}>1080 Motion</ToggleButton>
                    <ToggleButton value={`/home/time-segment-dashboard`}>
                        Time Segments
                    </ToggleButton>
                    <ToggleButton value={`/home/gps-summary-dashboard`}>GPS Summary</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box sx={{ width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Switch>
                    <Route exact path={`/home/training-dashboard`}>
                        <Dashboard />
                    </Route>
                    <Route exact path={`/home/sprint-dashboard`}>
                        <SprintDashboard />
                    </Route>
                    <Route exact path={`/home/analyses-dashboard`}>
                        <VideoAnalysisDashboard />
                    </Route>
                    <Route exact path={`/home/tem-dashboard`}>
                        <TemDashboard />
                    </Route>
                    <Route exact path={`/home/time-segment-dashboard`}>
                        <TimeSegmentDashboard />
                    </Route>
                    <Route exact path={`/home/gps-summary-dashboard`}>
                        <GpsSummaryDashboard />
                    </Route>
                    <Route path="/">
                        <Redirect to={'/community/groups'} />
                    </Route>
                </Switch>
            </Box>
        </Box>
    );
}

export default DashboardLayout;
